import React, {useState} from 'react'
import RbModal from '../../common/components/RbModal'
import {bind} from '../../../utils/helpers'
import RbTextField from '../../common/inputs/RbTextField'
import RbButton from '../../common/inputs/RbButton'
import PrioritySelect from '../../common/inputs/PrioritySelect'
import RecordSelect from '../../common/inputs/RecordSelect'
import {Stack} from '@mui/material'
import {useSchedule} from '../ScheduleContext'
import {toast} from 'react-toastify'
import apiRequests from '../../../api/apiRequests'
import useApiRequest from '../../../hooks/useApiRequest'

const RequestStudent = ({openLocations, onClose}) => {
  const [requestInfo, setRequestInfo] = useState({priorityLevel: null, reason: ''})
  const [selectedLocation, setSelectedLocation] = useState(null)

  const {viewingUser} = useSchedule()

  const request = useApiRequest({
    request: apiRequests.attendance.requestMany,
    errorText: 'Failed to request student. Please try again.'
  })

  const save = e => {
    e.preventDefault()

    const body = [
      {
        accountId: viewingUser.id,
        locationId: selectedLocation.id,
        priorityId: parseInt(requestInfo.priorityLevel),
        teacherNote: requestInfo.reason
      }
    ]

    request.send(selectedLocation.id, body)
      .then(failedStudents => {
        if (failedStudents.length > 0) {
          toast.error('Failed to request student. Please try again.', {autoClose: false})
        } else {
          toast.success('Student Requested Successfully')
        }

        onClose()
      })
  }

  return (
    <RbModal open title="Request Student" onClose={onClose} width={400}>
      <form onSubmit={save}>
        <Stack spacing={2}>
          <RecordSelect
            records={openLocations}
            label="Location"
            labelKey="name"
            required
            {...bind.recordSelect(selectedLocation, setSelectedLocation)}
          />

          {selectedLocation &&
            <>
              <PrioritySelect
                required
                sx={{mb: 2}}
                {...bind.object.select(requestInfo, 'priorityLevel', setRequestInfo)}
              />

              {requestInfo.priorityLevel &&
                <>
                  <RbTextField
                    multiline
                    label="Reason"
                    sx={{mb: 2}}
                    autoFocus
                    {...bind.object.input(requestInfo, 'reason', setRequestInfo)}
                  />

                  <RbButton type="submit">Submit</RbButton>
                </>
              }
            </>
          }
        </Stack>
      </form>
    </RbModal>
  )
}

export default RequestStudent