import React, {useState} from 'react'
import apiRequests from '../../../../api/apiRequests'
import RbLoading from '../../../common/components/RbLoading'
import RecordTable from '../../../common/components/RecordTable'
import RbText from '../../../common/components/RbText'
import {v4 as uuid} from 'uuid'
import Flex from '../../../common/components/Flex'
import RbInfoTooltip from '../../../common/components/RbInfoTooltip'
import useApiRequest from '../../../../hooks/useApiRequest'
import {Divider} from '@mui/material'

const RequestPriorities = () => {
  const [columnConfigs, setColumnConfigs] = useState([])
  const [requestInfo, setRequestInfo] = useState(null)

  const parse = requestPrioritiesData => {
    if (requestPrioritiesData?.allPriorities?.length === 0) {
      return
    }

    const newColumnConfigs = [{title: 'Teacher', dataKey: 'teacherName', dataType: 'string'}]

    requestPrioritiesData.allPriorities.forEach(priorityLevel => {
      newColumnConfigs.push({title: priorityLevel.description + ' Request Ct', dataKey: 'priority ' + priorityLevel.priority, dataType: 'number', width: 200})
    })

    // Now we need to create a table worth of data and populate it
    const tableData = []
    requestPrioritiesData.teachersAndPriorities.forEach(teacherData => {
      const tableObj = {
        id: uuid(),
        teacherName: (teacherData.teacherFirst ?? '') + ' ' + (teacherData.teacherLast ?? '')
      }

      // Gather their data for each priority
      let totalUsage = 0
      teacherData.priorityCounts.forEach(priorityCt => {
        tableObj['priority ' + priorityCt.priority.priority] = priorityCt.usageCt
        totalUsage += priorityCt.usageCt
      })

      tableObj.totalUsage = totalUsage

      tableData.push(tableObj)
    })

    newColumnConfigs.push({title: 'Total Requests Used', dataKey: 'totalUsage', dataType: 'number'})

    setColumnConfigs(newColumnConfigs)
    setRequestInfo(tableData)
  }

  useApiRequest({
    request: apiRequests.analytics.getRequestsByLevelForYear,
    onResponse: parse,
    errorText: 'There was an error loading the request priorities data.',
    sendImmediately: true
  })

  return (
    <>
      <Flex alignCenter>
        <RbText h4 noBottomMargin sx={{mr: 1}}>Request Priorities Used Per Teacher For The Whole Year</RbText>
        <RbInfoTooltip title="Note: This gives you a nice breakdown of how many requests a teacher is making per year and at what request levels" />
      </Flex>

      <Divider />

      {!requestInfo && <RbLoading />}

      {requestInfo &&
        <RecordTable
          records={requestInfo}
          isSortable
          columnConfigs={columnConfigs}
          cellTextNoWrap
        />
      }
    </>
  )
}

export default RequestPriorities