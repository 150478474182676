import React from 'react'
import {Box} from '@mui/material'
import SidebarLink from './SidebarLink'
import {useCurrentUser} from '../../context/CurrentUserContext'
import Flex from '../common/components/Flex'

const Sidebar = ({isExpanded, size, navbarHeight}) => {
  const {currentUserIsAdminOrAbove} = useCurrentUser()

  const links = [
    {title: 'Schedule', icon: 'date_range', route: '/'},
    {title: 'Attendance', icon: 'list', route: '/attendance'},
    {title: 'Blocked / Approved', icon: 'block', route: '/blocked-approved-list'},
    {title: 'Groups', icon: 'groups', route: '/groups'},
    {title: 'Search User', icon: 'search', route: '/search'},
  ]

  if (currentUserIsAdminOrAbove) {
    links.push({title: 'Admin Dashboard', icon: 'admin_panel_settings', route: '/admin-dashboard'})
    links.push({
      title: 'Site Analytics', icon: 'bar_chart', dropdown: [
        {title: 'Graphs', icon: 'bar_chart', route: '/site-analytic-graphs'},
        {title: 'Tables', icon: 'table_chart', route: '/site-analytic-tables'}
      ]
    })
  }

  const bottomLinks = [
    {title: 'Tutorials', icon: 'help_center', route: '/tutorials'},
    {title: 'Log Out', icon: 'logout', route: '/logout'}
  ]

  return (
    <Flex column justifyBetween sx={{
      flexGrow: 1,
      position: 'fixed',
      top: navbarHeight,
      bottom: 0,
      left: 0,
      width: size,
      transition: 'width 0.2s ease-in-out',
      overflow: 'hidden',
      backgroundColor: 'primary.main',
      color: 'white'
    }}
    >
      {[links, bottomLinks].map(currentLinks => (
        <Box sx={{width: '100%'}} key={currentLinks[0].title}>
          {currentLinks.map(link => <SidebarLink drawerIsOpen={isExpanded} key={link.title} link={link} />)}
        </Box>
      ))}
    </Flex>
  )
}

export default Sidebar