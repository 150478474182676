import React from 'react'
import AddUser from './tabs/AddUser'
import Reports from './tabs/Reports'
import AutomatedTasks from './tabs/automated-tasks/AutomatedTasks'
import SiteSettings from './tabs/SiteSettings'
import SisSettings from './tabs/sis/settings/SisSettings'
import SisIntegration from './tabs/sis/integration/SisIntegration'
import {SisIntegrationProvider} from './tabs/sis/SisIntegrationContext'
import RbTabs from '../common/components/RbTabs'
import PageTitle from '../common/components/PageTitle'

const AdminDashboard = () => {
  // Set the title in the browser's tab
  document.title = 'Admin Dashboard'

  return (
    <>
      <PageTitle>Admin Dashboard</PageTitle>

      <RbTabs
        contentMarginTop={4}
        tabs={[
          {label: 'Reports', render: () => <Reports />},
          {label: 'Site Settings', render: () => <SiteSettings />},
          {label: 'Automated Tasks', render: () => <AutomatedTasks />},
          {label: 'Add User', render: () => <AddUser />},
          {label: 'Sis Settings', justifySelf: 'flex-end', render: () => <SisSettings />},
          {
            label: 'Sis Integration', justifySelf: 'flex-end', render: () => (
              <SisIntegrationProvider>
                <SisIntegration />
              </SisIntegrationProvider>
            )
          },
        ]}
      />
    </>
  )
}

export default AdminDashboard