import React, {useState} from 'react'
import {Box, Divider, FormControlLabel, Switch, Tooltip} from '@mui/material'
import {useEffect} from 'react'
import apiRequests from '../../../api/apiRequests'
import RecordTable from '../../common/components/RecordTable'
import {ScheduleProvider} from '../../schedule/ScheduleContext'
import {useAttendance} from '../AttendanceContext'
import {getStatusTitle} from '../constants'
import StudentSchedule from './StudentSchedule'
import {bind} from '../../../utils/helpers'
import Flex from '../../common/components/Flex'
import moment from 'moment'
import RbDatePicker from '../../common/inputs/RbDatePicker'
import RbText from '../../common/components/RbText'
import RbLoading from '../../common/components/RbLoading'
import useApiRequest from '../../../hooks/useApiRequest'

const HomeroomTab = () => {
  const {selectedDate, weekId, viewingUser, setSelectedDate} = useAttendance()
  const [users, setUsers] = useState()
  const [showTwoWeeks, setShowTwoWeeks] = useState(false)
  const [attendanceDays, setAttendanceDays] = useState([]) // Configurations for individual days that contain attendance data and will be displayed as columns

  const renderInlineSchedule = record => {
    return (
      <ScheduleProvider viewingUserId={record.id} date={selectedDate} overrideNumberOfWeeks={showTwoWeeks ? 2 : 1}>
        <StudentSchedule />
      </ScheduleProvider>
    )
  }

  const renderAttendanceCell = (record, dayInfo) => {
    const status = record[dayInfo.dataKey]
    const takenByName = record[dayInfo.takenByKey]
    const tooltipTitle = takenByName ? `Taken by ${takenByName}` : null

    return (
      <Tooltip title={tooltipTitle}>
        <span>{status}</span>
      </Tooltip>
    )
  }

  const getColumnConfigs = () => {
    const columns = [
      {title: 'First Name', dataKey: 'firstName', isSortable: true, dataType: 'string'},
      {title: 'Last Name', dataKey: 'lastName', isSortable: true, dataType: 'string'},
      {title: 'Grade', dataKey: 'grade', isSortable: true, dataType: 'number'}
    ]

    // Dynamically add columns for each attendance day
    attendanceDays.forEach(info => {
      columns.push({
        title: info.date, dataKey: info.dataKey, isSortable: true, dataType: 'string', renderCell: record => renderAttendanceCell(record, info)
      })
    })

    return [
      ...columns,
      {title: 'Days Signed Up', dataKey: 'daysSignedUpText', isSortable: true},
      {title: 'Schedule', togglesChildRow: true}
    ]
  }

  const attendanceRequest = useApiRequest({
    request: apiRequests.attendance.getAttendanceForHomeroom,
    errorText: 'There was an error loading the attendance.',
  })

  useEffect(() => {
    setUsers(null)

    attendanceRequest.send(viewingUser.id, weekId)
      .then(data => {
        const daysSignedUp = {} // Stores the number of days signed up for each user
        const parsedUsers = []
        const newAttendanceDays = []

        data.days.forEach(day => {
          day.periods.forEach(period => {
            period.accounts.forEach(record => {
              const userId = record.account.id

              // Only count days signed up if the user is signed up for the day
              if (record.locationId) {
                daysSignedUp[userId] = (daysSignedUp[userId] ?? 0) + 1
              }

              let parsedUser = parsedUsers.find(user => user.id === userId)
              const isAlreadyParsed = Boolean(parsedUser) // If the user has already been parsed, we don't need to add it to the list of parsed users again, we just need to update the existing one

              const attendanceStatusKey = `attendanceInfo${day.dayNum}` // The data key for the attendance status for this user's attendance on this day. Stored in the attendanceDays array and used to dynamically add columns to the table
              const attendanceTakenByKey = attendanceStatusKey + 'TakenBy' // Same thing for taken by info

              // Only add the attendance day to the array if it hasn't already been added, preventing duplicate columns
              if (!newAttendanceDays.find(info => info.dataKey === attendanceStatusKey)) {
                const date = moment(selectedDate).weekday(day.dayNum).format('ddd M/D')
                newAttendanceDays.push({dataKey: attendanceStatusKey, takenByKey: attendanceTakenByKey, date: date})
              }

              // Add a few extra properties to the user object that will be used in the table
              parsedUser = parsedUser ?? record.account
              parsedUser[attendanceStatusKey] = getStatusTitle(record.status)
              parsedUser[attendanceTakenByKey] = record.takenByAccount?.fullName
              parsedUser.numberDaysSignedUp = (parsedUser.numberDaysSignedUp ?? 0) + 1
              parsedUser.daysSignedUpText = `${parsedUser.numberDaysSignedUp}/${data.periodsInWeek}`

              if (!isAlreadyParsed) {
                parsedUsers.push(record.account)
              }
            })
          })
        })

        setAttendanceDays(newAttendanceDays)
        setUsers(parsedUsers)
      })
  }, [weekId, viewingUser, selectedDate])

  return (
    <>
      <Flex justifyBetween alignCenter>
        <Box>
          {users && <FormControlLabel control={<Switch {...bind.switch(showTwoWeeks, setShowTwoWeeks)} />} label="Show Second Week for Student Schedules" />}
        </Box>
        <RbDatePicker {...bind.datePicker(selectedDate, setSelectedDate)} incrementByWeek />

      </Flex>

      <Divider sx={{my: 3}} />

      {!users && <RbLoading />}

      {users &&
        <>
          {users.length === 0 && <RbText h2>No Students in Homeroom</RbText>}

          {users.length > 0 &&
            <RecordTable
              columnConfigs={getColumnConfigs()}
              records={users}
              renderChildRow={renderInlineSchedule}
              defaultSortKey="lastName"
              height={800}
            />
          }
        </>
      }
    </>
  )
}

export default HomeroomTab