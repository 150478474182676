import React, {useState} from 'react'
import useUsers from '../../../../hooks/useUsers'
import RecordTable from '../../../common/components/RecordTable'
import RbLoading from '../../../common/components/RbLoading'
import RbText from '../../../common/components/RbText'
import UserSelect from '../../../common/inputs/UserSelect'
import {toast} from 'react-toastify'

const StudentsWithoutHomerooms = () => {
  const [loadingUsers, setLoadingUsers] = useState([])
  const {students, teachers, updateUser} = useUsers({includeStudents: true, includeTeachers: true})

  const withoutHomerooms = students?.filter(student => !student.homeroomAccountId || student.homeroomAccountId === 0)

  const setHomeroom = (student, teacher) => {
    setLoadingUsers(prev => [...prev, student.id])

    updateUser(student.id, {...student, homeroomAccountId: teacher.id})
      .then(() => toast.success(`${student.fullName} is now in ${teacher.fullName}'s homeroom!`))
      .finally(() => setLoadingUsers(prev => prev.filter(id => id !== student.id)))
  }

  const renderHomeroomCell = student => {
    const userIsLoading = loadingUsers.includes(student.id)
    return (
      <>
        {userIsLoading && <RbLoading small />}
        {!userIsLoading &&
          <UserSelect users={teachers} onChange={teacher => setHomeroom(student, teacher)} />
        }
      </>
    )
  }

  return (
    <>
      {!students && <RbLoading />}

      {students &&
        <>
          {withoutHomerooms.length === 0 && <RbText>Nice! All students have homerooms!</RbText>}

          {withoutHomerooms.length > 0 &&
            <RecordTable
              records={withoutHomerooms}
              columnConfigs={[
                {title: 'Name', dataKey: 'fullName'},
                {
                  title: 'Set Homeroom', renderCell: renderHomeroomCell
                }
              ]}
            />
          }
        </>
      }
    </>
  )
}

export default StudentsWithoutHomerooms