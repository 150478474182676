import React, {useState} from 'react'
import RbModal from '../../../../../../common/components/RbModal'
import apiRequests from '../../../../../../../api/apiRequests'
import RbLoading from '../../../../../../common/components/RbLoading'
import useApiRequest from '../../../../../../../hooks/useApiRequest'
import RbText from '../../../../../../common/components/RbText'
import RecordTable from '../../../../../../common/components/RecordTable'
import NewExclusionForm from './NewExclusionForm'
import {EXCLUSION_TYPES} from './constants'
import RbButton from '../../../../../../common/inputs/RbButton'
import {bind} from '../../../../../../../utils/helpers'
import Flex from '../../../../../../common/components/Flex'
import {useConfirm} from '../../../../../../../context/ConfirmationContext'
import {toast} from 'react-toastify'
import RbInfoTooltip from '../../../../../../common/components/RbInfoTooltip'
import {Box} from '@mui/material'

const AutoscheduleExclusions = ({onClose}) => {
  const [exclusions, setExclusions] = useState()
  const [selectedExclusions, setSelectedExclusions] = useState()
  const [isAddingNewExclusion, setIsAddingNewExclusion] = useState(false)

  const confirm = useConfirm()

  const parseExclusions = records => {
    setExclusions(records.map(record => ({
      ...record,
      type: record.studentId ? EXCLUSION_TYPES.STUDENT :
        record.groupId ? EXCLUSION_TYPES.GROUP :
          record.grade ? EXCLUSION_TYPES.GRADE : null,
      displayValue: record.student ? record.student.fullName :
        record.group ? record.group.name :
          record.grade ? record.grade : null,
    })))
  }

  const request = {
    getExclusions: useApiRequest({
      request: apiRequests.autoscheduleExclusions.get,
      errorText: 'There was an issue getting the autoschedule exclusions. Please try again later.',
      onResponse: parseExclusions,
      sendImmediately: true,
    }),
    deleteExclusions: useApiRequest({
      request: apiRequests.autoscheduleExclusions.delete,
      errorText: 'There was an issue deleting the autoschedule exclusions. Please try again later.',
    }),
  }

  const deleteSelectedExclusions = () => {
    confirm({title: 'Delete Selected Exclusions', description: 'Are you sure you want to delete the selected exclusions?'})
      .then(() => {
        const idsToDelete = selectedExclusions.map(exclusion => exclusion.id)

        request.deleteExclusions.send(idsToDelete)
          .then(() => {
            toast.success(`Successfully deleted ${idsToDelete.length} exclusion${idsToDelete.length > 1 ? 's' : ''}`)
            setSelectedExclusions()
            setExclusions(exclusions.filter(exclusion => !idsToDelete.includes(exclusion.id)))
          })
      })
  }

  const modalTitle = (
    <Flex alignCenter gap={1}>
      <span>Autoschedule Exclusions</span> <RbInfoTooltip title={(
        <>
          <p style={{fontSize: '1rem', marginBottom: 0}}>Autoschedule Exclusions</p>
          <p>Prevents autoscheduling for admin-selected students, groups, or grades.</p>
          <Box sx={{pl: 1}}>
            <p>- For example, to avoid autoscheduling seniors for senior release, simply exclude the 12th grade.</p>
            <p>- If you want to exclude many students at once, create a group and add them to said group using a CSV file of their emails. For details on group upload, please visit the groups page.</p>
            <p>- <b>Note:</b> excluded students are still able to sign up for periods manually, they are just excluded from autoscheduling.</p>
          </Box>
        </>
      )}
      />
    </Flex>
  )

  return (
    <RbModal
      open
      onClose={onClose}
      title={isAddingNewExclusion ? 'New Exclusion(s)' : modalTitle}
      minWidth={400}
      maxWidth={600}
    >
      {!exclusions && <RbLoading />}

      {!isAddingNewExclusion && exclusions &&
        <>
          {exclusions.length === 0 && <RbText>No Exclusions Yet</RbText>}

          {exclusions.length > 0 &&
            <>
              <Flex justifyBetween sx={{mb: 1}}>
                <RbButton onClick={() => setIsAddingNewExclusion(true)} icon="add">New Exclusion(s)</RbButton>

                {selectedExclusions && selectedExclusions.length > 0 &&
                  <RbButton
                    onClick={deleteSelectedExclusions}
                    icon="delete"
                    color="error"
                  >
                    Delete {selectedExclusions.length} Exclusion{selectedExclusions.length > 1 ? 's' : ''}
                  </RbButton>
                }
              </Flex>

              <RecordTable
                isSortable
                isSelectable
                records={exclusions}
                columnConfigs={[
                  {title: 'Type', dataKey: 'type', dataType: 'string'},
                  {title: 'Info', dataKey: 'displayValue', dataType: 'string'},
                  {title: 'Reason', dataKey: 'reason', dataType: 'string'},
                ]}
                {...bind.recordTableSelection(selectedExclusions, setSelectedExclusions)}
              />
            </>
          }
        </>
      }

      {isAddingNewExclusion && <NewExclusionForm
        onClose={() => {
          setIsAddingNewExclusion(false)
          request.getExclusions.send()
        }}
        exclusions={exclusions}
      />
      }
    </RbModal>
  )
}

export default AutoscheduleExclusions