
import axios from 'axios'
import {isLocalDev} from '../utils/helpers'

export const reportError = (error, reactInfo, extraInfo) => {
  if (isLocalDev()) {
    return
  }

  const extraInfoString = Object.keys(extraInfo).map(key => `${key}: ${extraInfo[key]}`).join('\n')

  const headers = {
    'Authorization': `Token token=${process.env.REACT_APP_PAGER_DUTY_API_KEY}`,
    'Content-Type': 'application/json',
    'From': 'ryan@maxqapps.com', // The email you use for PagerDuty
    'Accept': 'application/vnd.pagerduty+json;version=2'
  }

  const payload = {
    incident: {
      type: 'incident',
      title: `Front End Error | ${error.name}: ${error.message}`,
      service: {
        id: process.env.REACT_APP_PAGER_DUTY_SERVICE_ID,
        type: 'service_reference'
      },
      body: {
        type: 'incident_body',
        details: `Info:\n${extraInfoString}\n\nStack:${reactInfo.componentStack}`
      }
    }
  }

  axios.post('https://api.pagerduty.com/incidents', payload, {headers: headers})
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('There was an error reporting the error...', err)
    })
}
