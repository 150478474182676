import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {Box, Stack} from '@mui/material'
import RbButton from '../../common/inputs/RbButton'
import Flex from '../../common/components/Flex'
import {bind, eCoal} from '../../../utils/helpers'
import RecordSelect from '../../common/inputs/RecordSelect'
import {EventBusy, Lock} from '@mui/icons-material'
import {useCurrentUser} from '../../../context/CurrentUserContext'
import {useSchedule} from '../ScheduleContext'
import apiRequests from '../../../api/apiRequests'
import DayCardContainer from '../DayCardContainer'
import RbText from '../../common/components/RbText'
import RequestStudent from './RequestStudent'
import useApiRequest from '../../../hooks/useApiRequest'
import SaveButtons from '../../common/inputs/SaveButtons'
import AllLocations from './AllLocations'

const StudentDayCard = ({dayInfo, location, allLocations, onRefreshNeeded}) => {
  const {currentUserIsTeacherOrAbove, currentUserIsAdminOrAbove, currentUserIsStudent} = useCurrentUser()
  const {viewingUser} = useSchedule()
  const [isEditing, setIsEditing] = useState(false)
  const [isRequesting, setIsRequesting] = useState(false)
  const [currentLocation, setCurrentLocation] = useState(location)
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [saveAfterLocationIsSelected, setSaveAfterLocationIsSelected] = useState(false) // This is to trigger save after a location has been "selected" by choosing it in the allLocations modal

  const isRequested = currentLocation.isRequested

  const requestType = isRequested ? currentLocation.requestType : -1
  const REQUEST_TYPES = {
    NOT_REQUESTED: -1,
    TEACHER_REQUEST: 0,
    GROUP_REQUEST: 1,
  }

  const updateScheduleRequest = useApiRequest({
    request: apiRequests.attendance.updateStudentSchedule,
    errorText: 'Failed to update schedule. Please try again.'
  })

  const getColor = () => {

    if (!currentLocation.teacherName) {
      // If they don't have a teacher name, then they are not signed up, grey
      return '#b3b3b3'
    } else if (currentLocation.wasAutoscheduled) {
      return '#D5972F'
    } else if (requestType === REQUEST_TYPES.NOT_REQUESTED) {
      // If requestType = -1, they not requested, green
      return '#75dc37'
    } else if (requestType === REQUEST_TYPES.TEACHER_REQUEST) {
      // If teacher requested, red
      return '#D7063A'
    } else if (requestType === REQUEST_TYPES.GROUP_REQUEST) {
      // If group requested, blue
      return '#2358B0'
    }

    return '#b3b3b3'
  }

  const resetLocation = () => setCurrentLocation(location)

  const cancelEditing = () => {
    resetLocation()
    setIsEditing(false)
  }

  const save = e => {
    e?.preventDefault()

    // If they didn't put anything, break early
    if (!selectedLocation) {
      return
    }

    updateScheduleRequest.send({
      accountId: viewingUser.id,
      locationId: selectedLocation.locationId,
      wasAutoscheduled: false,
      absolutePeriodId: currentLocation.asupId,
    })
      .then(() => {
        toast.success('Changed Schedule Successfully')

        setCurrentLocation(prev => ({
          ...prev,
          locationId: selectedLocation.locationId,
          wasAutoscheduled: false,
        }))

        setIsEditing(false)
        onRefreshNeeded()
      })
  }

  const onLocationSelect = (newLocation, setStateCallback) => {
    setSelectedLocation(newLocation, setStateCallback)

    if (!newLocation) {
      resetLocation()
    } else {
      setCurrentLocation(prev => ({
        ...prev,
        teacherName: newLocation.teacherName,
        room: newLocation.locationRoom,
        description: newLocation.locationDescription,
        summary: newLocation.locationSummary,
      }))
    }
  }

  const getHoverContents = () => {
    let isLocked = false
    let canEdit = false
    let canRequest = false

    if (currentUserIsAdminOrAbove) {
      canEdit = true
      canRequest = true
    } else if (currentUserIsTeacherOrAbove) {
      canEdit = !isRequested
      canRequest = true
    } else if (currentUserIsStudent) {
      canEdit = !isRequested && !currentLocation.scheduleLockEnabled
      isLocked = !canEdit
    }

    return (
      <>
        {isLocked &&
          <Lock sx={{color: 'white'}} />
        }

        {!isLocked &&
          <>
            {canEdit &&
              <RbButton
                icon="edit"
                color="secondary"
                onClick={() => setIsEditing(true)}
              >
                Edit
              </RbButton>
            }

            {canRequest &&
              <RbButton
                iconStart
                icon="person_add"
                color="warning"
                onClick={() => setIsRequesting(true)}
              >
                Request
              </RbButton>
            }
          </>
        }
      </>
    )
  }

  const openLocations = allLocations.filter(loc => !loc.isFull || loc.id === currentLocation.locationId)

  useEffect(() => {
    if (saveAfterLocationIsSelected) {
      save()
      setSaveAfterLocationIsSelected(false)
    }
  }, [selectedLocation])

  // Reset the current location if the location prop changes, signifying that the whole schedule has refreshed
  useEffect(() => setCurrentLocation(location), [location])

  return (
    <DayCardContainer
      dayInfo={dayInfo}
      indicatorColor={getColor()}
      hoverContents={getHoverContents()}
      disableHover={isEditing || isRequesting}
    >
      {currentLocation?.locationId &&
        <Box>
          <RbText h4>{currentLocation.teacherName}</RbText>
          <RbText h5>{eCoal(currentLocation.summary, <i>No Summary</i>)}</RbText>
          <Flex justifyBetween alignCenter>
            <RbText>{eCoal(currentLocation.description, <i>No Description</i>)}</RbText>
            <RbText h5 alignRight noBottomMargin>{eCoal(currentLocation.room, <i>No Location</i>)}</RbText>
          </Flex>
          {currentLocation.teacherNote && <RbText color="error">{currentLocation.teacherNote}</RbText>}
        </Box>
      }

      {!currentLocation?.locationId &&
        <Flex alignCenter justifyCenter sx={{flexGrow: 1}}>
          <EventBusy />
          <RbText h6 noBottomMargin sx={{ml: 1}}>Unscheduled</RbText>
        </Flex>
      }

      {isEditing &&
        <form onSubmit={save}>
          <Stack spacing={2} mt={3}>
            <Flex alignItems="flex-end" gap={1}>
              <RecordSelect
                records={openLocations}
                label="Location"
                labelKey="name"
                required
                {...bind.recordSelect(selectedLocation, onLocationSelect)}
              />

              {allLocations && allLocations.length > 0 &&
                <AllLocations
                  locations={allLocations}
                  currentLocation={currentLocation}
                  dayInfo={dayInfo}
                  onSignUp={newLocation => {
                    setSaveAfterLocationIsSelected(true)
                    onLocationSelect(newLocation)
                  }}
                />
              }
            </Flex>

            <SaveButtons isLoading={updateScheduleRequest.isLoading} onCancel={cancelEditing} />
          </Stack>
        </form>
      }

      {isRequesting &&
        <RequestStudent
          openLocations={openLocations}
          onClose={() => {
            onRefreshNeeded()
            setIsRequesting(false)
          }}
        />
      }

    </DayCardContainer>
  )
}

export default StudentDayCard