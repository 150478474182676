import React from 'react'
import {Box} from '@mui/material'
import StudentRequests from './StudentRequests'
import GroupRequests from './GroupRequests'

const RequestTab = ({locationId}) => {
  return (
    <>
      {[StudentRequests, GroupRequests].map((Component, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box key={index} p={2} mt={2} sx={{borderRadius: '10px', backgroundColor: '#0000000a'}}>
          <Component locationId={locationId} />
        </Box>
      ))}
    </>
  )
}

export default RequestTab