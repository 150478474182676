import moment from 'moment'
import api from './api'

const apiRequests = {
  users: {
    createHomeroomStudents: (userId, body) => api.put(`accounts/PutManyHomerooms?teacherId=${userId}`, body),
    getCurrentUser: () => api.get('accounts/currentUser'),
    getAllDeleted: () => api.get('Accounts/ListDeletedAccounts'),
    getAll: () => api.get('accounts'),
    createUser: body => api.post('accounts', body),
    getUserInfo: userId => api.get(`accounts/${userId}`),
    getHomeroomStudents: userId => api.get(`accounts/homeroomAccounts?id=${userId}`),
    getLevels: () => api.get('accountLevels'),
    login: (email, password) => api.post('accounts/login', {email, password}),
    googleSignIn: body => api.post('accounts/GoogleSignIn', body),
    updateMissingInfo: (userId, body) => api.put(`accounts/MissingInformation/${userId}`, body),
    getStudents: () => api.get('accounts/StudentAccounts'),
    getTeachers: () => api.get('Accounts/TeachersAndAbove'),
    tokenSignIn: body => api.post('accounts/TokenSignIn', body),
    delete: userId => api.delete(`accounts/${userId}`),
    undelete: userId => api.put(`Accounts/UndeleteAccount/${userId}`),
    acceptTerms: userId => api.put(`accounts/AcceptTos/${userId}`),
    update: (userId, body) => api.put(`accounts/${userId}`, body),
  },
  attendance: {
    exportStudents: {
      absent: dayInt => api.get(`attendance/exportAbsentStudents?dayInt=${dayInt}`),
      tardy: dayInt => api.get(`attendance/exportTardyStudents?dayInt=${dayInt}`),
    },
    getStudentSchedule: (userId, weekId) => api.get(`attendance/studentSchedule/${userId}/week/${weekId}`),
    getAttendanceForHomeroom: (userId, weekId) => api.get(`attendance/homeroom/?homeroomTeacherAccountId=${userId}&weekId=${weekId}`),
    getMyPeriod: (userId, weekId) => api.get(`attendance/MyPeriod/teacher/${userId}/week/${weekId}`),
    unscheduleAutoscheduled: body => api.post('Attendance/unscheduleAutoscheduled', body),
    update: body => api.put('Attendance/UpdateAttendance', body),
    updateStudentSchedule: body => api.put('Attendance/ChangeStudentSchedule', body),
    requestMany: (locationId, body) => api.put(`Attendance/RequestMany/${locationId}`, body),
    updateGroupLocation: body => api.put('Attendance/UpdateGroupLocation', body),
    removeStudentsFromLocation: (locationId, body) => api.put(`attendance/locations/${locationId}/students/remove`, body),
    getAttendanceLog: (weekId, userId) => api.get(`AttendanceLog/Week/${weekId}/Account/${userId}`),
    getFullAttendanceHistory: userId => api.get(`Attendance/FullAttendanceHistory/${userId}`),
  },
  analytics: {
    getAttendanceStats: weekId => api.get(`Attendance/Analytic/AttendanceStats/${weekId}`),
    getAttendanceStatusPerDay: weekId => api.get(`Analytics/AttendanceStatusPerDay/${weekId}`),
    getAutoscheduledPerDay: weekId => api.get(`Analytics/AutoscheduledPerDay/${weekId}`),
    getRequestLevelsPerDay: weekId => api.get(`Analytics/RequestLevelsPerDay/${weekId}`),
    getRequestsByLevelForYear: () => api.get('Attendance/AnalyticTable/RequestsByLevelForYear'),
    getSeatsPerDay: weekId => api.get(`Analytics/SeatsPerDay/${weekId}`),
    getSeatCapacity: weekId => api.get(`SignUpLocations/Analytic/SeatCapacity/${weekId}`),
  },
  groups: {
    create: newGroup => api.post('groups', newGroup),
    delete: groupId => api.delete(`groups/${groupId}`),
    editors: {
      create: body => api.post('groupeditors/PostMany', body),
      delete: (groupId, userId) => api.delete(`GroupEditors?groupId=${groupId}&accountId=${userId}`),
      getByGroupId: groupId => api.get(`GroupEditors/group/${groupId}`),
    },
    getAll: (includeNonstandardGroups = true) => api.get(`groups?includeNonstandardGroups=${includeNonstandardGroups}`),
    members: {
      create: body => api.post('groupMembers/PostMany', body),
      createFromEmails: (groupId, emails) => api.post(`GroupMembers/AddByEmails/${groupId}`, {emails: emails}),
      delete: (groupId, userId) => api.delete(`GroupMembers?groupId=${groupId}&accountId=${userId}`),
      getByGroupId: groupId => api.get(`GroupMembers/Group/${groupId}`),
    },
    update: updatedGroup => api.put(`groups/${updatedGroup.id}`, updatedGroup),
  },
  pushNotifications: {
    getEnrolledDevices: userId => api.get(`NotificationSubscriptions?accountId=${userId}`),
    subscribe: body => api.post('NotificationSubscriptions', body),
    unsubscribe: subscriberId => api.delete(`NotificationSubscriptions/${subscriberId}`),
  },
  partitionList: {
    create: body => api.post('PartitionList', body),
    update: (partitionId, body) => api.put(`PartitionList/${partitionId}`, body),
    delete: partitionId => api.delete(`PartitionList/${partitionId}`),
    getForUser: userId => api.get(`partitionlist?accountId=${userId}`),
  },
  signUpPeriodConfig: {
    getAutomatedTasksConfig: () => api.get('SignUpPeriodsConfig/GetAutomatedTasksConfig'),
    updateAutoscheduleConfig: body => api.post('SignUpPeriodsConfig/AutoscheduleToHomeroomTime', body),
    autoscheduleStudents: (body, smartscheduleEnabled) => api.post(`SignUpPeriodsConfig/autoScheduleStudents?smart=${smartscheduleEnabled}`, body),
    updateScheduleLockConfig: body => api.post('SignUpPeriodsConfig/ScheduleLockTime', body),
    sendAttendanceEmail: body => api.post('SignUpPeriodsConfig/sendAttendanceEmail', body),
    updateAttendanceEmailConfig: body => api.post('SignUpPeriodsConfig/AttendanceEmailTime', body),
    updateAttendanceRemindEmailConfig: body => api.post('SignUpPeriodsConfig/AttendanceRemindEmailTime', body),
  },
  schools: {
    current: {
      get: () => api.get('schools/current'),
      update: body => api.put('schools/current', body),
      updateScheduleDuplication: body => api.post('schools/current/schedule-duplication', body),
    },
  },
  signUpDays: {
    get: () => api.get('signupdaysconfig'),
  },
  signUpLocations: {
    get: (userId, weekId) => api.get(`signuplocations/?accountId=${userId}&weekId=${weekId}`),
    getLocationsForWeek: weekId => api.get(`signuplocations/week/${weekId}`),
    getAttendance: locationId => api.get(`signuplocations/AttendanceForLocation/${locationId}`),
    update: (sulId, body) => api.put(`SignUpLocations/${sulId}`, body),
  },
  signUpWeeks: {
    getWeekOfDate: date => api.get(`signupweeks/getWeekOfDate?date=${moment(date).format('YYYY-MM-DD')}`),
  },
  priorities: {
    get: () => api.get('priorities'),
  },
  sis: {
    getConnectionDetails: () => api.get('SisConnectionDetails'),
    getSisTypes: () => api.get('SisConnectionDetails/SisTypes'),
    testConnection: connectionId => api.get(`SisConnectionDetails/${connectionId}/TestConnection`),
    addConnection: body => api.post('SisConnectionDetails', body),
    deleteConnection: id => api.delete(`SisConnectionDetails/${id}`),
    migration: {
      getSchools: connectionId => api.get(`SisMigration/Schools?connDetailsId=${connectionId}`),
      importTeachers: body => api.post('SisMigration/ImportTeachersFromSis', body, {timeout: 99999999}),
      importStudents: body => api.post('SisMigration/ImportStudentsFromSis', body, {timeout: 99999999}),
      getGradeLevels: (connectionId, schoolId) => api.get(`SisMigration/GradeLevels?connDetailsId=${connectionId}&schoolSourcedId=${schoolId}`),
      getPeriods: (connectionId, schoolId) => api.get(`SisMigration/Periods?connDetailsId=${connectionId}&schoolSourcedId=${schoolId}`),
      getClassesForPeriod: (connectionId, schoolId, period) => api.get(`SisMigration/ClassesForPeriod/${period}?connDetailsId=${connectionId}&schoolSourcedId=${schoolId}`),
    }
  },
  autoscheduleExclusions: {
    get: () => api.get('autoschedule-exclusions'),
    create: exclusions => api.post('autoschedule-exclusions', exclusions),
    delete: ids => api.post('autoschedule-exclusions/delete', ids),
  },
  periodTemplates: {
    getMine: () => api.get('period-templates'),
    create: body => api.post('period-templates', body),
    update: body => api.put('period-templates', body),
    delete: id => api.delete(`period-templates/${id}`),
  },
}

export default apiRequests