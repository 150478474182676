import React, {useEffect, useState} from 'react'
import apiRequests from '../../../../api/apiRequests'
import RecordTable from '../../../common/components/RecordTable'
import RbText from '../../../common/components/RbText'
import RbLoading from '../../../common/components/RbLoading'
import Flex from '../../../common/components/Flex'
import RbInfoTooltip from '../../../common/components/RbInfoTooltip'
import useApiRequest from '../../../../hooks/useApiRequest'
import {Divider} from '@mui/material'

const SeatsFilled = ({weekId}) => {
  const [locationInfo, setLocationInfo] = useState(null)
  const [totalSeats, setTotalSeats] = useState(null)
  const [totalStudents, setTotalStudents] = useState(null)

  const parse = data => {
    const locationArray = []
    if (data) {
      data.teachersWeekCaps.forEach(loc => {
        loc.id = loc.teacherId
        locationArray.push(loc)
      })
    }
    setTotalStudents(data.totalStudents)
    setLocationInfo(locationArray)
  }

  useApiRequest({
    request: apiRequests.analytics.getSeatCapacity,
    params: [weekId],
    onResponse: parse,
    errorText: 'There was an error loading the seat capacity data.',
    sendImmediately: true
  })

  useEffect(() => {
    if (!locationInfo) {
      return
    }

    let seats = 0
    locationInfo.forEach(loc => seats += loc.seatCapacity)
    setTotalSeats(seats)
  }, [locationInfo])

  return (
    <>
      <Flex alignCenter>
        <RbText h4 noBottomMargin sx={{mr: 1}}>School Seat Capacity Statistics for Week</RbText>
        <RbInfoTooltip title="Note: This is the amount of seats your school has available for the week vs the total student count" />
      </Flex>

      <Divider />

      <RbText h5>Total Seats: {totalSeats ?? '(Loading)'} | Total Students: {totalStudents ?? '(Loading)'} | Availability That&apos;ll be Filled: {totalSeats && totalStudents && parseInt((totalStudents / totalSeats) * 100)}%</RbText>

      {!locationInfo && <RbLoading />}

      {locationInfo &&
        <RecordTable
          records={locationInfo}
          isSortable
          headerTextNoWrap
          cellTextNoWrap
          defaultSortKey="signedUpStudents"
          defaultSortDirection="desc"
          columnConfigs={[
            {title: 'Teacher', dataKey: 'teacherName', dataType: 'string'},
            {title: 'Signed Up Students', dataKey: 'signedUpStudents', dataType: 'number'},
            {title: 'Student Limit', dataKey: 'seatCapacity', dataType: 'number'},
          ]}
        />
      }
    </>
  )
}

export default SeatsFilled