import {TimePicker} from '@mui/x-date-pickers'
import React from 'react'
import {bind} from '../../../utils/helpers'

const RbTimePicker = ({label, value, onChange, sx}) => {
  return (
    <TimePicker
      label={label}
      slotProps={{textField: {size: 'small'}}}
      sx={{...sx, minWidth: '9rem'}}
      {...bind.timePicker(value, onChange)}
    />
  )
}

export default RbTimePicker