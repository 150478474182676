import React from 'react'
import {Chart} from 'react-google-charts'
import {Box} from '@mui/material'
import RbLoading from './RbLoading'

const PieChart = ({title, data, options, sx}) => {
  const chartOptions = {
    title: title,
    pieSliceText: 'value',
    colors: ['#229954', '#F1C40F', '#E74C3C', '#3c82e7'],
    ...options
  }

  return (
    <Box sx={{fontSize: 12, ...sx}}>
      {!data && <RbLoading />}

      {data &&
        <Chart
          chartType="PieChart"
          data={data}
          options={chartOptions}
          width="100%"
          height="300px"
        />
      }
    </Box>
  )
}

export default PieChart