import React, {useEffect, useState} from 'react'
import {Box, Icon, TextField} from '@mui/material'
import useDebouncedValue from '../../../hooks/useDebouncedValue'

const RbTextField = ({type, label, value, icon, onChange, onKeyDown, required, sx, className, containerClassName, min, max, autoFocus, multiline, variant = 'standard', size = 'small', placeholder, isDebounced, minLength}) => {
  const [currentValue, setCurrentValue] = useState(value)
  const debouncedValue = useDebouncedValue(currentValue)

  const handleChange = e => {
    setCurrentValue(e.target.value)
  }

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  useEffect(() => {
    if (!isDebounced) {
      onChange(currentValue)
    }
  }, [currentValue])

  useEffect(() => {
    if (isDebounced) {
      onChange(debouncedValue)
    }
  }, [debouncedValue])

  return (
    <Box sx={{display: 'flex', alignItems: variant === 'standard' ? 'flex-end' : 'center', flexGrow: 1, ...sx}} className={containerClassName}>
      {icon && <Icon sx={{mr: 1}}>{icon}</Icon>}
      <TextField
        type={type}
        label={label}
        variant={variant}
        size={size}
        value={currentValue ?? ''}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        required={required}
        sx={{
          flexGrow: 1,
        }}
        className={className}
        inputProps={{
          min: min,
          max: max,
          minLength: minLength
        }}
        autoFocus={autoFocus}
        multiline={multiline}
        placeholder={placeholder}
      />
    </Box>
  )
}

export default RbTextField