import React, {useState} from 'react'
import RbModal from '../common/components/RbModal'
import RbCheckbox from '../common/inputs/RbCheckbox'
import {bind} from '../../utils/helpers'
import RbButton from '../common/inputs/RbButton'
import apiRequests from '../../api/apiRequests'
import {useCurrentUser} from '../../context/CurrentUserContext'
import RbText from '../common/components/RbText'
import useApiRequest from '../../hooks/useApiRequest'

const TermsOfService = () => {
  const [tosAccepted, setTosAccepted] = useState(false)
  const {currentUserId} = useCurrentUser()

  const acceptTermsRequest = useApiRequest({
    request: apiRequests.users.acceptTerms,
    errorText: 'Failed to accept terms of service. Please try again.',
    onResponse: () => window.location.reload()
  })

  const submit = e => {
    e.preventDefault()

    if (!tosAccepted) {
      return
    }

    acceptTermsRequest.send(currentUserId)
  }

  return (
    <RbModal title="Accept Terms of Service" open>
      <RbText>Before using Rebentify Scheduling, please read and agree to the <a href="https://rebentify.com/terms/" target="_blank" rel="noopener noreferrer">terms of service:&nbsp;</a></RbText>

      <form onSubmit={submit}>
        <RbCheckbox label="Agree" {...bind.checkbox(tosAccepted, setTosAccepted)} required />

        <RbButton disabled={!tosAccepted} sx={{mt: 2}} type="submit">Submit</RbButton>
      </form>
    </RbModal>
  )
}

export default TermsOfService