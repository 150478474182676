import {Box} from '@mui/material'
import React from 'react'
import {useSchedule} from '../../schedule/ScheduleContext'
import Week from '../../schedule/Week'

const StudentSchedule = () => {
  const {weekStartDates, viewingUser} = useSchedule()
  return (
    <Box p={2}>
      {viewingUser && weekStartDates.map(date => <Week key={date} date={date} />)}
    </Box>
  )
}

export default StudentSchedule