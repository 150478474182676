import React from 'react'
import {useSisIntegration} from '../../../SisIntegrationContext'
import {toast} from 'react-toastify'
import Results from './Results'
import Flex from '../../../../../../common/components/Flex'
import RbButton from '../../../../../../common/inputs/RbButton'
import RbText from '../../../../../../common/components/RbText'

// This component is used for both the import and simulate steps.
const ImportLayout = ({isSimulation}) => {
  const {doImport, simulatedData, setSimulatedData, importedData, setImportedData, importIsLoading} = useSisIntegration()

  const data = isSimulation ? simulatedData : importedData
  const setData = isSimulation ? setSimulatedData : setImportedData
  const error = isSimulation ? 'simulating the import' : 'importing'
  const buttonText = isSimulation ? 'Simulate' : 'Import'

  const buttonIsVisible = !importIsLoading && (isSimulation || !importedData)

  const handleImport = () => {
    setData(null)

    doImport(isSimulation)
      .then(setData)
      .catch(() => toast.error(`There was an error ${error}. Please try again.`))
  }

  return (
    <>
      {importIsLoading &&
        <>
          <Flex justifyCenter>
            <span className="import-loader" />
          </Flex>

          <RbText sx={{mt: 3}}>Importing can take several minutes. Please do not navigate away from this window.</RbText>
        </>
      }

      {!importIsLoading &&
        <>
          {buttonIsVisible && <RbButton onClick={handleImport}>{buttonText}</RbButton>}

          {data && <Results data={data} isSimulation={isSimulation} />}
        </>
      }
    </>
  )
}

export default ImportLayout