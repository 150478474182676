import {Box, Checkbox} from '@mui/material'
import React from 'react'
import Flex from '../../common/components/Flex'
import {STATUS_CONFIGS} from '../constants'

const AttendanceCheck = ({record, isAllCheckbox, onChange, statusCode, allSelected, onAllSelected}) => {
  const statusConfig = STATUS_CONFIGS[statusCode]

  const handleChange = () => {
    if (isAllCheckbox) {
      onAllSelected(statusCode)
    } else {
      onChange(record.attenId, statusCode)
    }
  }

  const isChecked = isAllCheckbox ?
    allSelected === statusCode :
    record.attendanceStatus === statusCode || allSelected === statusCode

  return (
    <Box>
      {isAllCheckbox &&
        <Box style={{textAlign: 'center', width: '100%'}}>
          <span>{statusConfig.title}</span>
        </Box>
      }

      <Flex justifyCenter>
        <Checkbox
          checked={isChecked}
          onChange={() => handleChange(statusCode)}
          sx={{
            '& .MuiSvgIcon-root': {fontSize: 30},
            color: statusConfig.backgroundColor,
            '&.Mui-checked': {
              color: statusConfig.backgroundColor,
            }
          }}
        />
      </Flex>
    </Box>
  )
}

export default AttendanceCheck