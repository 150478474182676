import React from 'react'
import {useCurrentUser} from '../../context/CurrentUserContext'
import RbText from '../common/components/RbText'
import {Card, CardContent, Grid} from '@mui/material'
import PageTitle from '../common/components/PageTitle'

const tutorialConfigs = [
  {imageFileName: 'teacher_2023_features.svg', videoUrl: 'https://youtu.be/jIXOfass-so'},
  {imageFileName: 'admin_2023_features.svg', videoUrl: 'https://youtu.be/MB0Bi1KQ59Q', isAdminOnly: true},
  {imageFileName: 'teacher_schedule.svg', videoUrl: 'https://youtu.be/XOYJK8TZf6I'},
  {imageFileName: 'student_schedule.svg', videoUrl: 'https://youtu.be/-2MYppeFnbE'},
  {imageFileName: 'blocked_approved.svg', videoUrl: 'https://youtu.be/_txeedr-EZo'},
  {imageFileName: 'groups_page.svg', videoUrl: 'https://youtu.be/ygIikPsGY5Y'},
  {imageFileName: 'attendance.svg', videoUrl: 'https://youtu.be/A-1I592OWw4'},
]

const Tutorials = () => {
  document.title = 'Rebentify - Tutorials'

  const {currentUserIsAdminOrAbove} = useCurrentUser()

  const currentCards = currentUserIsAdminOrAbove ? tutorialConfigs : tutorialConfigs.filter(card => !card.isAdminOnly)

  return (
    <>
      <PageTitle>
        <RbText h2>Tutorials</RbText>
        <RbText h5>Click any tutorial to get started!</RbText>
      </PageTitle>

      <Grid container spacing={4}>
        {currentCards.map(card => (
          <Grid
            item
            sm={12}
            md={6}
            lg={3}
            sx={{
              transition: 'transform 0.2s ease-in-out',
              cursor: 'pointer',
              '&:hover': {
                transform: 'scale(1.05)'
              }
            }}
            onClick={() => window.open(card.videoUrl, '_blank')}
            key={card.imageFileName}
          >
            <Card>
              <CardContent>
                <img src={'https://storage.googleapis.com/rebentify_scheduling_images/tutorial_thumbnails/' + card.imageFileName} alt="video thumbnail with same text as video description" />
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default Tutorials