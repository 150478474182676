import React, {useEffect, useState} from 'react'
import RbTextField from '../../common/inputs/RbTextField'
import {bind} from '../../../utils/helpers'
import Flex from '../../common/components/Flex'
import RbSelect from '../../common/inputs/RbSelect'
import {Grid, Stack} from '@mui/material'
import RbButton from '../../common/inputs/RbButton'
import {parseLevel} from '../../../context/CurrentUserContext'
import UserSelect from '../../common/inputs/UserSelect'
import RbText from '../../common/components/RbText'
import useUsers from '../../../hooks/useUsers'

const UserForm = ({user, onChange, onSubmit, title, submitButtonLabel, showPassword, isLoading}) => {
  const [pendingUser, setPendingUser] = useState({})

  const {userLevels, teachers} = useUsers({includeTeachers: true})

  const handleSubmit = e => {
    e.preventDefault()

    const userToSubmit = {...pendingUser}
    // If the user is new, remove the id field bc c# doesn't like it
    if (userToSubmit?.id === null) {
      delete userToSubmit.id
    }

    onSubmit(userToSubmit, onSuccess)
  }

  const onSuccess = () => {
    setPendingUser(prev => (
      {
        ...prev,
        firstName: '',
        lastName: '',
        email: '',
        grade: '',
        plainTextPassword: '',
        levelId: ''
      })
    )
  }

  const isUserStudent = u => parseLevel(userLevels?.find(l => l.id === u.levelId)).isStudent

  useEffect(() => {
    if (onChange) {
      onChange(pendingUser)
    }
  }, [pendingUser])

  useEffect(() => {
    setPendingUser({
      id: user?.id ?? null,
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      email: user?.email ?? '',
      grade: user?.grade ?? null,
      levelId: user?.levelId ?? null,
      homeroomAccountId: user?.homeroomAccountId ?? null,
      week1Disabled: false,
      week2Disabled: false,
      plainTextPassword: '',
      tosAccepted: true // For now, eventually we will massive mark false
    })
  }, [user])

  return (
    <Grid item xs={12} md={6}>
      <RbText h4>{title}</RbText>
      <form onSubmit={e => handleSubmit(e, pendingUser)}>
        <Stack spacing={2}>
          {pendingUser &&
            <Flex justifyBetween gap={2}>
              <RbTextField
                label="First Name"
                autoFocus
                required
                {...bind.object.input(pendingUser, 'firstName', setPendingUser)}
              />

              <RbTextField
                label="Last Name"
                {...bind.object.input(pendingUser, 'lastName', setPendingUser)}
              />
            </Flex>
          }

          <RbTextField
            label="Email"
            type="email"
            required
            {...bind.object.input(pendingUser, 'email', setPendingUser)}
          />

          {showPassword &&
            <RbTextField
              label="Password"
              type="password"
              required
              minLength={9}
              {...bind.object.input(pendingUser, 'plainTextPassword', setPendingUser)}
            />
          }

          <Flex gap={2} alignItems="flex-end">
            <RbSelect
              options={userLevels ?? []}
              label="User Level"
              labelKey="name"
              valueKey="id"
              required
              {...bind.object.select(pendingUser, 'levelId', setPendingUser)}
            />

            {pendingUser?.levelId && isUserStudent(pendingUser) &&
              <>
                <RbTextField
                  label="Grade"
                  type="number"
                  sx={{width: 70, flexGrow: 0}}
                  required
                  {...bind.object.input(pendingUser, 'grade', setPendingUser)}
                />

                <UserSelect
                  users={teachers}
                  label="Homeroom Teacher"
                  selectedRecords={{id: pendingUser?.homeroomAccountId}} // must pass in ID, won't have HR acct object
                  onChange={hr => setPendingUser(prev => ({...prev, homeroomAccountId: hr?.id}))}
                  required
                  sx={{flexGrow: 1}}
                />
              </>
            }
          </Flex>

          <RbButton type="submit" sx={{width: 150}} isLoading={isLoading}>
            {submitButtonLabel ?? 'Submit'}
          </RbButton>
        </Stack>
      </form>
    </Grid>

  )
}

export default UserForm