import React, {useState} from 'react'
import AttendanceStatusGraph from './AttendanceStatusGraph'
import BarGraph from './BarGraph'
import apiRequests from '../../../api/apiRequests'
import RbDatePicker from '../../common/inputs/RbDatePicker'
import {bind} from '../../../utils/helpers'
import RbText from '../../common/components/RbText'
import {Card, CardContent, Grid} from '@mui/material'
import PageTitle from '../../common/components/PageTitle'
import useWeek from '../../../hooks/useWeek'
import RbLoading from '../../common/components/RbLoading'

const AnalyticGraphs = () => {
  // Set the title in the browser's tab
  document.title = 'Rebentify - Analytic Graphs'

  const [selectedDate, setSelectedDate] = useState(new Date())

  const {weekId} = useWeek(selectedDate)

  if (!weekId) {
    return <RbLoading />
  }

  return (
    <>
      <PageTitle
        title="Site Analytic Graphs"
        subtitle={<>Hello! Please suggest new graphs that will help in weekly administration of your site! <br /> Note: Only admins+ can see this page</>}
        extraContent={<RbDatePicker {...bind.datePicker(selectedDate, setSelectedDate)} incrementByWeek />}
      />

      <Grid container spacing={3} sx={{width: {xs: '100%', xl: '80%'}, margin: 'auto'}}>
        {/* A graph that shows how many kids autoscheduled for a week */}
        <Grid item xs={12} lg={6}>
          <Card variant="outlined">
            <CardContent>
              <RbText h4>Autoscheduled Students</RbText>
              <BarGraph weekId={weekId} request={apiRequests.analytics.getAutoscheduledPerDay} title="Autoscheduled Students Per Day" />
            </CardContent>
          </Card>
        </Grid>

        {/* Pie chart of present/absent/etc. */}
        <Grid item xs={12} lg={6}>
          <Card variant="outlined">
            <CardContent>
              <RbText h4>Student Attendance</RbText>
              <AttendanceStatusGraph weekId={weekId} />
            </CardContent>
          </Card>
        </Grid>

        {/* A graph of the levels used for requests in a given week */}
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardContent>
              <RbText h4>Request Levels</RbText>
              <BarGraph weekId={weekId} request={apiRequests.analytics.getRequestLevelsPerDay} title="Request Levels Utilized Per Day" />
            </CardContent>
          </Card>
        </Grid>

        {/* Shows seats filled per day on its own row*/}
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardContent>
              <RbText h4>Seats Filled</RbText>
              <BarGraph weekId={weekId} request={apiRequests.analytics.getSeatsPerDay} title="Seats Filled vs Seats Needed" />
            </CardContent>
          </Card>
        </Grid>

      </Grid>
    </>
  )
}

export default AnalyticGraphs