import React from 'react'
import {Box, FormControlLabel, FormGroup, Switch, Tooltip} from '@mui/material'
import {Info} from '@mui/icons-material'

const RbSwitch = ({label, checked, onChange, justifyContent, tooltip, disabled, required}) => {
  return (
    <Box>
      <FormGroup sx={{display: 'flex', alignItems: 'center', flexGrow: 1, flexDirection: 'row', gap: 1}}>
        <FormControlLabel
          labelPlacement="start"
          label={label}
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          required={required}
          control={<Switch />}
          sx={{
            marginLeft: 0,
            justifyContent: justifyContent ?? '',
            flexGrow: justifyContent === 'space-between' ? 1 : 0, // If we need to justify between, then we will want the whole container to grow to fill the parent. (See teacher day card block/approved for example)
          }}
        />

        {tooltip && <Tooltip title={tooltip}><Info /></Tooltip>}
      </FormGroup>
    </Box>
  )
}

export default RbSwitch