import {Typography} from '@mui/material'
import React from 'react'

const RbText = ({children, sx, h1, h2, h3, h4, h5, h6, alignLeft, alignCenter, alignRight, noWrap, color, noBottomMargin}) => {
  const variant = h1 ? 'h1'
    : h2 ? 'h2'
      : h3 ? 'h3'
        : h4 ? 'h4'
          : h5 ? 'h5'
            : h6 ? 'h6' : undefined

  const isHeader = variant?.startsWith('h')

  const style = isHeader ? {
    fontWeight: 500,
    marginBottom: noBottomMargin ? 0 : (sx?.mb ?? '0.5rem')
  } : {}

  const textColor = color ?? (isHeader ? 'primary' : '')

  style.whiteSpace = noWrap ? 'nowrap' : ''

  const align = alignLeft ? 'left'
    : alignCenter ? 'center'
      : alignRight ? 'right' : undefined

  return (
    <Typography variant={variant} sx={{...sx, ...style}} align={align} color={textColor}>
      {children}
    </Typography>
  )
}

export default RbText