import React, {useEffect, useState} from 'react'
import apiRequests from '../../../api/apiRequests'
import {useSchedule} from '../ScheduleContext'
import TeacherDayCard from './TeacherDayCard'
import RbLoading from '../../common/components/RbLoading'
import useApiRequest from '../../../hooks/useApiRequest'

const TeacherSchedule = ({weekInfo}) => {
  const {viewingUser} = useSchedule()
  const [locations, setLocations] = useState()

  const getLocationsRequest = useApiRequest({
    request: apiRequests.signUpLocations.get,
    errorText: 'An error occurred while fetching the schedule.',
    onResponse: setLocations
  })

  const getLocationForDay = day => locations?.find(location => location.absolutePeriod?.period?.day?.weekDay === day.weekDay)

  const updateLocation = location => {
    getLocationsRequest.setData(prev => prev.map(l => l.id === location.id ? location : l))
  }

  const refreshLocations = () => {
    getLocationsRequest.send(viewingUser.id, weekInfo.id)
  }

  useEffect(refreshLocations, [weekInfo])

  return (
    <>
      {!locations && <RbLoading />}

      {locations && weekInfo.days.map(day => (
        <TeacherDayCard
          dayInfo={day}
          key={day.date}
          location={getLocationForDay(day)}
          onChange={updateLocation}
          onRefresh={refreshLocations}
        />
      ))}
    </>
  )
}

export default TeacherSchedule