import {useEffect, useState} from 'react'
import apiRequests from '../api/apiRequests'
import useApiRequest from './useApiRequest'

// You can omit the date parameter to automatically fetch the current week
const useWeek = date => {
  const [weekOfDate, setWeekOfDate] = useState(null)

  const requests = {
    get: useApiRequest({
      request: apiRequests.signUpWeeks.getWeekOfDate,
      onResponse: setWeekOfDate,
    }),
  }

  useEffect(() => {
    requests.get.send(date ?? new Date())
  }, [date])

  return {
    weekOfDate,
    weekId: weekOfDate?.id,
  }
}

export default useWeek