import {useState} from 'react'
import apiRequests from '../api/apiRequests'
import useApiRequest from './useApiRequest'

const usePriorities = () => {
  const [priorities, setPriorities] = useState()

  useApiRequest({
    request: apiRequests.priorities.get,
    onResponse: data => setPriorities(data.sort((a, b) => a.priority - b.priority)),
    sendImmediately: true
  })

  return {
    priorities
  }
}

export default usePriorities