import React from 'react'
import {bind} from '../../../../../utils/helpers'
import RbSwitch from '../../../../common/inputs/RbSwitch'
import RecordTable from '../../../../common/components/RecordTable'
import {useAutomatedTasks} from '../AutomatedTasksContext'
import moment from 'moment'
import RbMenu from '../../../../common/components/RbMenu'
import DayOfTheWeekSelect from '../../../../common/inputs/DayOfTheWeekSelect'
import RbTimePicker from '../../../../common/inputs/RbTimePicker'

const DailyConfigTable = ({dayConfigs, onChange, actions, dayLabel, timeLabel, canDisableDaily = true}) => {
  const {getDayTitleForPeriod, getDayIntForPeriod} = useAutomatedTasks()

  const mappedConfigs = dayConfigs.map(dayConfig => ({
    ...dayConfig,
    id: dayConfig.periodId,
    dayTitle: getDayTitleForPeriod(dayConfig.periodId),
    dayInt: getDayIntForPeriod(dayConfig.periodId)
  }))
    .sort((a, b) => a.dayInt - b.dayInt)

  const getColumnConfigs = () => {
    const columns = [
      {title: 'Day', dataKey: 'dayTitle', width: 125},
    ]

    if (canDisableDaily) {
      columns.push({title: 'Enabled', renderCell: renderEnableCell, width: 75})
    }

    if (dayLabel) {
      columns.push({title: dayLabel, renderCell: renderDatePickerCell, textAlign: 'left'})
    }

    if (timeLabel) {
      columns.push({title: timeLabel, renderCell: renderTimePickerCell, width: 170, textAlign: 'left'})
    }

    if (actions) {
      columns.push({title: '', renderCell: () => null})
      columns.push({title: 'Actions', renderCell: renderActionsCell, width: 75, textAlign: 'center'})
    }

    return columns
  }

  const dayIsEnabled = record => !canDisableDaily || record.configEnabled

  const handleUpdate = (record, key, value) => {
    const newConfigs = [...mappedConfigs]
    const index = newConfigs.findIndex(dayConfig => dayConfig.id === record.id)
    newConfigs[index][key] = value
    onChange(newConfigs)
  }

  const renderEnableCell = record => {
    return (
      <RbSwitch
        {...bind.switch(record.configEnabled, value => handleUpdate(record, 'configEnabled', value))}
      />
    )
  }

  const renderDatePickerCell = record => {
    if (!dayIsEnabled(record)) {
      return null
    }

    return (
      <DayOfTheWeekSelect
        size="small"
        variant="outlined"
        // Intentionally not using object bind here because we need to use handleUpdate.
        {...bind.select(record.dayIntToRun, value => handleUpdate(record, 'dayIntToRun', value))}
      />
    )
  }

  const renderTimePickerCell = record => {
    if (!dayIsEnabled(record)) {
      return null
    }

    const parsedTime = moment(record.timeToRun, 'HH:mm:ss').toDate()

    return (
      <RbTimePicker
        {...bind.timePicker(parsedTime, value => handleUpdate(record, 'timeToRun', moment(value).format('HH:mm:ss')))}
      />
    )
  }

  const renderActionsCell = record => {
    if (!dayIsEnabled(record)) {
      return null
    }

    return (
      <RbMenu
        value={record}
        options={actions}
      />
    )
  }

  const columnConfigs = getColumnConfigs()

  return (
    <RecordTable
      columnConfigs={columnConfigs}
      records={mappedConfigs}
      height={mappedConfigs?.length * 75}
      isPaginated={false}
    />
  )
}

export default DailyConfigTable