/* eslint-disable react/no-array-index-key */
import React, {useState} from 'react'
import {Divider, Icon, IconButton, ListItemIcon, Menu, MenuItem} from '@mui/material'
import Flex from './Flex'
import RbButton from '../inputs/RbButton'

// options will have label and onClick()
const RbMenu = ({label, labelIcon = 'more_vert', options, value, optionLabelKey = 'label', sx, itemSx, iconEnd, renderButton, onOpen}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    if (onOpen) {
      onOpen()
    }

    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => setAnchorEl(null)

  return (
    <>
      {renderButton && renderButton(handleClick)}

      {!renderButton &&
        <>
          {label &&
            <RbButton
              id="basic-button"
              onClick={handleClick}
              sx={{...sx}}
              icon={labelIcon}
              iconEnd={iconEnd}
            >
              {label}
            </RbButton>
          }

          {!label && labelIcon &&
            <IconButton
              sx={{...sx}}
              onClick={handleClick}
            >
              <Icon>
                {labelIcon}
              </Icon>
            </IconButton>
          }
        </>
      }

      <Menu
        disableAutoFocusItem
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options?.map((option, index) => {
          if (option.isDivider) {
            return <Divider key={index} sx={{margin: '0.1rem 0 !important'}} />
          }

          if (option.render) {
            return option.render(value)
          }

          return (
            <MenuItem
              key={index}
              onClick={() => {
                handleClose()
                if (option.onClick) {
                  option.onClick(value)
                }
                if (option.link) {
                  window.open(option.link(value), '_blank')
                }
              }}
              sx={{...itemSx, ...option.sx}}
              disabled={option.disabled}
            >
              <Flex alignCenter justifyBetween>
                {option.icon &&
                  <ListItemIcon>
                    {typeof option.icon === 'string' ?
                      <Icon>{option.icon}</Icon>
                      : option.icon
                    }
                  </ListItemIcon>
                }
                {option[optionLabelKey]}
              </Flex>
            </MenuItem>
          )
        })
        }
      </Menu>
    </>
  )
}

export default RbMenu