import React, {useState} from 'react'
import apiRequests from '../../api/apiRequests'
import RecordTable from '../common/components/RecordTable'
import RbButton from '../common/inputs/RbButton'
import RbModal from '../common/components/RbModal'
import {toast} from 'react-toastify'
import RbMenu from '../common/components/RbMenu'
import {useCurrentUser} from '../../context/CurrentUserContext'
import RbTabs from '../common/components/RbTabs'
import TabContainer from './partials/TabContainer'
import RbText from '../common/components/RbText'
import RbLoading from '../common/components/RbLoading'
import GroupInfoModal from './partials/GroupInfoModal'
import useGroups from '../../hooks/useGroups'
import useApiRequest from '../../hooks/useApiRequest'
import PageTitle from '../common/components/PageTitle'
import {useConfirm} from '../../context/ConfirmationContext'

const Groups = () => {
  document.title = 'Rebentify - Groups'

  const {currentUser, currentUserIsAdminOrAbove} = useCurrentUser()
  const {groups, setGroups, createGroup, updateGroup, deleteGroup, groupApiRequests} = useGroups(false)
  const confirm = useConfirm()

  const [focusedGroup, setFocusedGroup] = useState(null) // Group being edited/added by user
  const [addGroupModalIsVisible, setAddGroupModalIsVisible] = useState(false)
  const [editGroupModalIsVisible, setEditGroupModalIsVisible] = useState(false)
  const [editMembersModalIsVisible, setEditMembersModalIsVisible] = useState(false)

  const requests = {
    addToApprovedList: useApiRequest({
      request: apiRequests.partitionList.create,
      errorText: 'There was an error adding the group to your approved list',
    }),
    addToBlockedList: useApiRequest({
      request: apiRequests.partitionList.create,
      errorText: 'There was an error adding the group to your blocked list'
    })
  }

  const handleAddGroup = group => {
    createGroup(group)
      .then(() => {
        setAddGroupModalIsVisible(false)
        setFocusedGroup(null)
      })
  }

  const handleUpdateGroup = group => {
    updateGroup(group)
      .then(() => {
        setEditGroupModalIsVisible(false)
        setFocusedGroup(null)
      })
  }

  const handleDeleteGroup = group => {
    confirm({
      title: `Are you sure you want to delete ${group.name}?`,
      confirmButtonText: 'Yes, delete it',
    })
      .then(() => deleteGroup(group))
  }

  const handleStudentChange = (updatedGroup, updatedStudentCount) => {
    const tempGroup = groups.find(group => group.id === updatedGroup.id)
    tempGroup.members = updatedGroup.members
    tempGroup.currentStudentCount = updatedStudentCount

    setGroups(prevGroups => prevGroups.map(group => group.id === tempGroup.id ? tempGroup : group))
  }

  const handleAddToApprovedList = group => {
    requests.addToApprovedList.send({
      accountId: currentUser.id,
      type: 'APPROVE',
      groupId: group.id
    })
      .then(() => toast.success(`${group.name} added to your approved list`))
  }

  const handleAddToBlockedList = group => {
    confirm({
      title: `Please enter the reason you are blocking ${group.name}`,
      confirmButtonText: 'Block',
      cancelButtonText: 'Cancel',
      userInputProps: {
        label: 'Reason',
        required: true,
        multiline: true
      }
    })
      .then(reason => {
        requests.addToBlockedList.send({
          accountId: currentUser.id,
          type: 'BLOCK',
          groupId: group.id,
          reason
        })
          .then(() => toast.success(`${group.name} added to your blocked list`))
      })
  }

  const optionData = [
    {
      label: 'Edit Group Info',
      onClick: g => {
        setFocusedGroup(g)
        setEditGroupModalIsVisible(true)
      }
    },
    {
      label: 'Manage Members',
      onClick: g => {
        setFocusedGroup(g)
        setEditMembersModalIsVisible(true)
      }
    },
    {label: 'Add Group To Your Approved List', onClick: handleAddToApprovedList},
    {label: 'Add Group To Your Blocked List', onClick: handleAddToBlockedList},
  ]

  if (currentUserIsAdminOrAbove) {
    optionData.push({isDivider: true})
    optionData.push({label: 'Delete Group', onClick: handleDeleteGroup})
  }

  return (
    <>
      <PageTitle
        title="Groups"
        extraContent={(
          <RbButton
            onClick={() => {
              setFocusedGroup({})
              setAddGroupModalIsVisible(true)
            }}
            icon="group_add"
          >
            Add Group
          </RbButton>
        )}
      />

      {!groups && <RbLoading />}

      {groups &&
        <>
          {groups.length === 0 && <RbText>No groups found</RbText>}

          {groups.length > 0 &&
            <RecordTable
              columnConfigs={[
                {title: 'Group Name', dataKey: 'name', isSortable: true, dataType: 'string'},
                {title: 'Description', dataKey: 'description', isSortable: true, dataType: 'string'},
                {title: 'Student Count', dataKey: 'currentStudentCount', isSortable: true, dataType: 'number'},
                {title: 'Private', dataKey: 'private', isSortable: true, renderCell: value => value.private ? 'Yes' : 'No', dataType: 'string'},
                {
                  title: 'Options', textAlign: 'center', width: 100, renderCell: group =>
                    <RbMenu options={optionData} labelIcon="settings" value={group} />
                }
              ]}
              height={800}
              records={groups}
              defaultSortKey="name"
            />
          }
        </>
      }

      {addGroupModalIsVisible &&
        <GroupInfoModal
          title="Add Group"
          group={focusedGroup}
          onClose={() => setAddGroupModalIsVisible(false)}
          onSave={handleAddGroup}
          isSaving={groupApiRequests.createGroup.isLoading}
        />
      }

      {editGroupModalIsVisible &&
        <GroupInfoModal
          title="Edit Group"
          group={focusedGroup}
          onClose={() => setEditGroupModalIsVisible(false)}
          onSave={handleUpdateGroup}
          isSaving={groupApiRequests.updateGroup.isLoading}
        />
      }

      {editMembersModalIsVisible && focusedGroup &&
        <RbModal
          open={editMembersModalIsVisible}
          onClose={() => setEditMembersModalIsVisible(false)}
          title={`Manage ${focusedGroup.name} Group Members`}
          minWidth={450}
        >
          <RbTabs tabs={[
            {label: 'Students', render: () => <TabContainer key="studentTab" isStudents={true} group={focusedGroup} onStudentChange={handleStudentChange} />},
            {label: 'Admins', render: () => <TabContainer key="teacherTab" isStudents={false} group={focusedGroup} />}
          ]}
          />
        </RbModal>
      }
    </>
  )
}

export default Groups