import {useEffect, useState} from 'react'
import apiRequests from '../api/apiRequests'
import {parseLevel} from '../context/CurrentUserContext'
import useApiRequest from './useApiRequest'

const useUsers = ({includeStudents, includeTeachers, includeAllUsers} = {}) => {
  const [userLevels, setUserLevels] = useState()
  const [rawStudents, setRawStudents] = useState()
  const [rawTeachers, setRawTeachers] = useState()
  const [rawAllUsers, setRawAllUsers] = useState()
  const [students, setStudents] = useState()
  const [teachers, setTeachers] = useState()
  const [allUsers, setAllUsers] = useState()

  const requests = {
    getUserLevels: useApiRequest({
      request: apiRequests.users.getLevels,
      errorText: 'An error occurred while fetching user levels.',
      onResponse: setUserLevels,
      sendImmediately: true
    }),
    getStudents: useApiRequest({
      request: apiRequests.users.getStudents,
      errorText: 'An error occurred while fetching students.',
      onResponse: setRawStudents,
      sendImmediately: includeStudents
    }),
    getTeachers: useApiRequest({
      request: apiRequests.users.getTeachers,
      errorText: 'An error occurred while fetching teachers.',
      onResponse: setRawTeachers,
      sendImmediately: includeTeachers
    }),
    getAllUsers: useApiRequest({
      request: apiRequests.users.getAll,
      errorText: 'An error occurred while fetching all users.',
      onResponse: setRawAllUsers,
      sendImmediately: includeAllUsers
    }),
    getUser: useApiRequest({
      request: apiRequests.users.getUserInfo,
      errorText: 'An error occurred while fetching user info.'
    }),
    updateUser: useApiRequest({
      request: apiRequests.users.update,
      errorText: 'An error occurred while updating user info.',
    }),
    deleteUser: useApiRequest({
      request: apiRequests.users.delete,
      errorText: 'An error occurred while de-activating the user.'
    }),
  }

  const setAll = callback => {
    setRawAllUsers(callback)
    setRawStudents(callback)
    setRawTeachers(callback)
  }

  const parseUser = user => {
    if (!user.level) {
      user.level = userLevels.find(l => l.id === user.levelId)
    }
    user.levelInfo = parseLevel(user.level)
    user.levelName = user.level?.name
    return user
  }

  const parseUsers = (setUsers, users) => {
    setUsers(prevUsers => {
      const usersToUpdate = users ?? prevUsers // If no users are passed in, just update the existing users
      return usersToUpdate.map(u => parseUser(u))
        .sort((a, b) => a.lastName.localeCompare(b.lastName))
    })
  }

  const getUser = userId => {
    return requests.getUser.send(userId)
      .then(user => parseUser(user))
  }

  const updateUser = (userId, user) => {
    return requests.updateUser.send(userId, user)
      .then(updatedUser => {
        const parsedUser = parseUser(updatedUser)
        const callback = prev => prev?.map(prevUser => prevUser.id === userId ? parsedUser : prevUser)
        setAll(callback)

        return parsedUser
      })
  }

  const deleteUser = userId => {
    return requests.deleteUser.send(userId)
      .then(() => {
        const callback = prev => prev?.filter(prevUser => prevUser.id !== userId)
        setAll(callback)
      })
  }

  // Re-parse users any time the user levels change
  useEffect(() => {
    if (!userLevels) {
      return
    }

    if (rawStudents) {
      parseUsers(setStudents, rawStudents)
    }

    if (rawTeachers) {
      parseUsers(setTeachers, rawTeachers)
    }

    if (rawAllUsers) {
      parseUsers(setAllUsers, rawAllUsers)
    }
  }, [userLevels, rawStudents, rawTeachers, rawAllUsers])

  return {
    userLevels,
    students,
    teachers,
    allUsers,
    getUser,
    updateUser,
    updateUserIsLoading: requests.updateUser.isLoading,
    deleteUser,
  }
}

export default useUsers