export const STATUSES = {
  APPROVED: 0,
  BLOCKED: 1
}

export const STATUS_CONFIGS = {
  [STATUSES.APPROVED]: {title: 'Approved'},
  [STATUSES.BLOCKED]: {title: 'Blocked'}
}

export const getStatusTitle = statusCode => STATUS_CONFIGS[statusCode]?.title ?? 'Unknown'

export const STATUS_SELECT_OPTIONS = [
  {value: STATUSES.APPROVED, label: getStatusTitle(STATUSES.APPROVED)},
  {value: STATUSES.BLOCKED, label: getStatusTitle(STATUSES.BLOCKED)}
]
