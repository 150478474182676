export const getColor = (attendanceCount, studentLimit) => {
  const limit = Number(studentLimit) ?? 0
  /* eslint-disable nonblock-statement-body-position */
  if (limit === 0) return 'gray'

  const count = Number(attendanceCount) ?? 0
  const percent = count / limit

  if (percent < 0.1) return '#5FFF7F '
  else if (percent < 0.2) return '#7FFF7F'
  else if (percent < 0.3) return '#9FFF7F'
  else if (percent < 0.4) return '#BFFF7F'
  else if (percent < 0.5) return '#DFFF7F'
  else if (percent < 0.6) return '#FCFC67 '
  else if (percent < 0.7) return '#FFDF7F'
  else if (percent < 0.8) return '#FFBF7F'
  else if (percent < 0.9) return '#F5825B'

  return '#E65454'
}