import React, {useEffect, useState} from 'react'
import {Box, IconButton, Stack, Tooltip} from '@mui/material'
import Flex from '../../common/components/Flex'
import {ContentPaste, People, PersonAdd, PersonRemove} from '@mui/icons-material'
import RbButton from '../../common/inputs/RbButton'
import RbModal from '../../common/components/RbModal'
import RbTextField from '../../common/inputs/RbTextField'
import {bind, eCoal} from '../../../utils/helpers'
import RbSwitch from '../../common/inputs/RbSwitch'
import apiRequests from '../../../api/apiRequests'
import {toast} from 'react-toastify'
import RbTabs from '../../common/components/RbTabs'
import RequestTab from './requests/Requests'
import RosterTab from './RosterTab'
import DayCardContainer from '../DayCardContainer'
import {useSchedule} from '../ScheduleContext'
import RbText from '../../common/components/RbText'
import {getColor} from './utils'
import useApiRequest from '../../../hooks/useApiRequest'
import RbMenu from '../../common/components/RbMenu'
import SaveButtons from '../../common/inputs/SaveButtons'

const TeacherDayCard = ({dayInfo, location, onChange, onRefresh}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [currentLocation, setCurrentLocation] = useState(location)
  const [requestsAndRosterIsVisible, setRequestsAndRosterIsVisible] = useState(false)

  const {copiedLocation, copyLocation} = useSchedule()

  const requests = {
    updateLocation: useApiRequest({
      request: apiRequests.signUpLocations.update,
      errorText: 'Failed to update location. Please try again.'
    }),
    createPeriodTemplate: useApiRequest({
      request: apiRequests.periodTemplates.create,
      errorText: 'Failed to create period template. Please try again.'
    }),
  }

  const isSaving = requests.updateLocation.isLoading

  const save = e => {
    e.preventDefault()

    currentLocation.studentLimit = Number(currentLocation.studentLimit) ?? 0

    requests.updateLocation.send(currentLocation.id, currentLocation)
      .then(() => {
        toast.success('Location updated!')
        setIsEditing(false)
        onChange(currentLocation)
      })
  }

  const saveAsTemplate = () => {
    requests.createPeriodTemplate.send({
      summary: currentLocation.summary,
      description: currentLocation.description,
      studentLimit: currentLocation.studentLimit,
      roomNumber: currentLocation.roomNumber,
      approveList: currentLocation.approveList,
      blockList: currentLocation.blockList,
    })
      .then(() => toast.success('Template created!'))
  }

  const cancelEditing = () => {
    setCurrentLocation(location)
    setIsEditing(false)
  }

  const getHoverContents = () => (
    <RbButton iconStart icon="edit" color="secondary" onClick={() => setIsEditing(true)}>Edit</RbButton>
  )

  const getHeaderContents = () => (
    <Flex justifyBetween alignCenter gap={1} sx={{flexGrow: 1}}>
      <Flex justifyBetween alignCenter sx={{ml: 2}} gap={1}>
        {currentLocation?.approveList && <Tooltip title="Approved List"><PersonAdd sx={{color: '#767676'}} /></Tooltip>}
        {currentLocation?.blockList && <Tooltip title="Blocker List"><PersonRemove sx={{color: '#767676'}} /></Tooltip>}
      </Flex>

      <Flex alignCenter>
        {copiedLocation &&
          <Tooltip title={`Paste: ${copiedLocation.summary} - ${copiedLocation.description}`}>
            <IconButton onClick={paste}><ContentPaste /></IconButton>
          </Tooltip>
        }

        <RbMenu
          options={[
            {label: 'Copy Period', onClick: () => copyLocation(currentLocation)},
            {label: 'Save as New Template', onClick: saveAsTemplate},
          ]}
        />
      </Flex>
    </Flex>
  )

  const paste = () => {
    const updatedLocation = {
      ...currentLocation,
      summary: copiedLocation.summary ?? '',
      approveList: copiedLocation.approveList,
      blockList: copiedLocation.blockList,
      roomNumber: copiedLocation.roomNumber ?? '',
      description: copiedLocation.description ?? '',
      studentLimit: copiedLocation.studentLimit ?? 0,
      wasAutoscheduled: false,
    }

    requests.updateLocation.send(currentLocation.id, updatedLocation)
      .then(() => {
        setCurrentLocation(updatedLocation)
        toast.success('Period Updated!')
      })
  }

  // Reset the current location if the location prop changes, signifying that the whole schedule has refreshed
  useEffect(() => setCurrentLocation(location), [location])

  return (
    <DayCardContainer
      dayInfo={dayInfo}
      indicatorColor={getColor(currentLocation?.attendanceCount, currentLocation?.studentLimit)}
      hoverContents={getHoverContents()}
      headerContents={getHeaderContents()}
      disableHover={isEditing}
    >
      {!isEditing && currentLocation &&
        <Box>
          <RbText h5>{eCoal(currentLocation.summary, <i>No Summary</i>)}</RbText>
          <RbText>{eCoal(currentLocation.description, <i>No Description</i>)}</RbText>
          <Flex justifyBetween alignCenter sx={{mt: 2}}>
            <Flex centered>
              <People />
              <RbText h5 noBottomMargin sx={{ml: 1}}>{currentLocation.attendanceCount ?? 0}/{currentLocation.studentLimit ?? 0}</RbText>
            </Flex>

            <RbText h5 noBottomMargin sx={{mx: 2}} maxWidth={200} alignRight fontSize={currentLocation.roomNumber?.length > 30 ? '1rem' : '1.25rem'}>{eCoal(currentLocation.roomNumber, <i>No Location</i>)}</RbText>
          </Flex>
        </Box>
      }

      {isEditing &&
        <form onSubmit={save}>
          <Stack spacing={1}>
            <RbTextField
              label="Summary"
              autoFocus
              {...bind.object.input(currentLocation, 'summary', setCurrentLocation)}
            />

            <RbTextField
              multiline
              label="Description"
              {...bind.object.input(currentLocation, 'description', setCurrentLocation)}
            />

            <Flex justifyBetween alignCenter>
              <RbTextField
                label="Location"
                {...bind.object.input(currentLocation, 'roomNumber', setCurrentLocation)}
              />

              <RbTextField
                label="Student Limit"
                type="number"
                min={0}
                {...bind.object.input(currentLocation, 'studentLimit', setCurrentLocation)}
              />
            </Flex>

            <Box>
              <RbSwitch
                label="Approved List"
                {...bind.object.switch(currentLocation, 'approveList', setCurrentLocation)}
                justifyContent="space-between"
              />

              <RbSwitch
                label="Blocker List"
                {...bind.object.switch(currentLocation, 'blockList', setCurrentLocation)}
                justifyContent="space-between"
              />
            </Box>

            <RbText h5><b>{location.attendanceCount ?? 0} Enrolled</b></RbText>

            <Box>
              <RbButton
                icon="people"
                color="secondary"
                onClick={() => setRequestsAndRosterIsVisible(true)}
                sx={{width: '100%', my: 2}}
              >
                Requests/Roster
              </RbButton>
            </Box>

            <SaveButtons isLoading={isSaving} onCancel={cancelEditing} />
          </Stack>
        </form>
      }

      {requestsAndRosterIsVisible &&
        <RbModal
          open
          title="Requests/Roster"
          onClose={() => {
            setRequestsAndRosterIsVisible(false)
            onRefresh() // Refresh the locations after we are done, since the attendance probably changed.
          }}
          minWidth={500}
        >
          <RbTabs
            tabs={[
              {label: 'Requests', render: () => <RequestTab locationId={currentLocation.id} />},
              {label: 'Roster', render: () => <RosterTab location={currentLocation} dayInfo={dayInfo} />},
            ]}
          />
        </RbModal>
      }
    </DayCardContainer>
  )
}

export default TeacherDayCard