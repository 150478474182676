import React, {useEffect, useState} from 'react'
import {bind} from '../../../utils/helpers'
import RbTextField from '../inputs/RbTextField'

const RbRecordSearch = ({records, onFilter, searchKeys}) => {
  const [search, setSearch] = useState('')

  useEffect(() => {
    const filteredRecords = (search && search.length > 0)
      ? records.filter(record => {
        const searchableText = searchKeys.map(key => record[key]).join(' ').toLowerCase()
        return searchableText.includes(search.toLowerCase())
      })
      : records

    onFilter(filteredRecords)
  }, [search, records])

  return (
    <RbTextField
      icon="search"
      label="Search"
      autoFocus
      isDebounced
      {...bind.input(search, setSearch)}
    />
  )
}

export default RbRecordSearch
