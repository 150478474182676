import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router'
import StudentAttendanceLog from './StudentAttendanceLog'
import StudentAttendanceHistory from './StudentAttendanceHistory'
import {Box, Divider} from '@mui/material'
import PageTitle from '../common/components/PageTitle'
import useUsers from '../../hooks/useUsers'

const Statistics = () => {
  // Set the title in the browser's tab
  document.title = 'Rebentify - Statistics'

  const {id} = useParams()
  const queryParams = new URLSearchParams(window.location.search)
  const date = queryParams.get('date')
  const [currentUser, setUserInfo] = useState(null)

  const {getUser} = useUsers()

  // Gets the viewed user if they are different from the logged in user
  useEffect(() => {
    if (!id) {
      return
    }

    getUser(id)
      .then(setUserInfo)
  }, [id])

  return (
    <>
      <PageTitle>Statistics - {currentUser?.fullName}</PageTitle>

      <Box>
        {currentUser &&
          <>
            <StudentAttendanceLog userId={id} defaultDate={date} />

            <Divider sx={{my: 3}} />

            <StudentAttendanceHistory userId={id} />
          </>
        }
      </Box>
    </>
  )
}

export default Statistics