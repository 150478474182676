import React, {useEffect, useState} from 'react'
import ErrorBoundaryCore from './ErrorBoundaryCore'
import {Box} from '@mui/material'
import ErrorSvg from '../assets/error.svg'
import RbText from '../components/common/components/RbText'
import {reportError} from './helpers'

// Only used at the root of the project in case something catastrophic happens. No additional info can be gathered here.
// Is essentially a wrapper for ErrorBoundaryCore and handles all of the error reporting and rendering.
const BasicErrorBoundary = ({children, resetErrorIfChanged, extraErrorInfo}) => {
  const [hasError, setHasError] = useState(false)

  const handleError = (error, reactInfo) => {
    setHasError(true)
    const extraInfo = extraErrorInfo || {}
    extraInfo['URL'] = window.location.href

    reportError(error, reactInfo, extraInfo)
  }

  const renderError = () => (
    <Box sx={{textAlign: 'center', mt: 3}}>
      <img src={ErrorSvg} alt="Error" width={250} />
      <RbText h4 sx={{mt: 4}}>Sorry! Something went wrong...</RbText>
      <RbText>Please try refreshing. If the issue persists please reach out to your school administrator</RbText>
    </Box>
  )

  // The parent component can pass in a value to resetErrorIfChanged. We watch this value, and if it changes we reset the error state.
  useEffect(() => setHasError(false), [resetErrorIfChanged])

  return (
    <ErrorBoundaryCore
      onError={handleError}
      hasError={hasError}
      renderError={renderError}
    >
      {children}
    </ErrorBoundaryCore>
  )
}

export default BasicErrorBoundary