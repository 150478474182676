import React from 'react'
import RbSelect from './RbSelect'
import {bind, daysOfTheWeek} from '../../../utils/helpers'

const DayOfTheWeekSelect = ({value, onChange, label, size, variant, sx, disabled}) => {
  return (
    <RbSelect
      label={label}
      options={daysOfTheWeek}
      valueKey="dayOfWeek"
      labelKey="title"
      sx={{...sx, minWidth: '9rem'}}
      variant={variant}
      size={size}
      disabled={disabled}
      {...bind.select(value, onChange)}
    />
  )
}

export default DayOfTheWeekSelect