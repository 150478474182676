import React from 'react'
import SisIntegrationStepButtons from './SisIntegrationStepButtons'
import {Box} from '@mui/material'
import RbText from '../../../../../common/components/RbText'

// Modular container for each step. Incudes the title, and the navigation buttons
const SisIntegrationStep = ({step}) => {
  const StepComponent = step.component

  return (
    <>
      <Box textAlign="center" margin="auto">
        <RbText h2>{step.title}</RbText>
        <StepComponent />
      </Box>

      <SisIntegrationStepButtons />
    </>
  )
}

export default SisIntegrationStep