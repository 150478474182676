import React, {useState, useEffect} from 'react'
import PieChart from '../../../../../../common/components/PieChart'
import {useSisIntegration} from '../../../SisIntegrationContext'
import {Box, Divider, TableCell, TableRow} from '@mui/material'
import Flex from '../../../../../../common/components/Flex'
import RbButton from '../../../../../../common/inputs/RbButton'
import RecordTable from '../../../../../../common/components/RecordTable'
import {v4 as uuid} from 'uuid'
import RbText from '../../../../../../common/components/RbText'
import RbInfoTooltip from '../../../../../../common/components/RbInfoTooltip'

const Results = ({data, isSimulation}) => {
  const {setAllSimulationDataIsViewed} = useSisIntegration()
  const [selectedConfig, setSelectedConfig] = useState()
  const [overviewData, setOverviewData] = useState() // Chart data
  const [chartColorOptions, setChartColorOptions] = useState()
  const [currentConfigs, setCurrentConfigs] = useState([])
  const [viewedConfigKeys, setViewedConfigKeys] = useState({})

  const getParsedRows = () => {
    let rows = []

    if (selectedConfig.isDuplicate) {
      Object.keys(selectedConfig.data).forEach(key => (
        selectedConfig.data[key].forEach(item => {
          rows.push({...item.account, wasImported: item.wasImported})
        })
      ))
    } else {
      rows = selectedConfig.data
    }

    return rows.map(record => ({
      ...record,
      teacherFirstName: record.homeroomAccount?.firstName,
      teacherLastName: record.homeroomAccount?.lastName,
      teacherEmail: record.homeroomAccount?.email,
      teacherInternalId: record.homeroomAccount?.internalId,
      id: isSimulation ? uuid() : record.id,
    }))
  }

  const renderFirstNameCell = record => (
    <Flex alignCenter>
      {selectedConfig.isDuplicate &&
        <RbInfoTooltip
          title={record.wasImported ? 'This record was imported' : 'This record was NOT imported'}
          icon={record.wasImported ? 'check' : 'cancel'}
          sx={{mr: 2, ml: 0}}
        />
      }
      <span>{record.firstName}</span>
    </Flex>
  )

  useEffect(() => {
    const parsedConfigs = [
      {shortText: 'Overview', title: 'Overview', key: 'overview', isOverview: true}, // Key is only used for viewedConfigKeys
    ]

    setSelectedConfig(parsedConfigs[0])

    const DATA_CONFIGS = [
      {shortText: 'Created', title: 'Users to Create', key: 'accountsToCreate', color: '#0dbd94'}, // Green
      {shortText: 'Updated', title: 'Users to Update', key: 'accountsToUpdate', color: '#bac95b'}, // Yellow
      {shortText: 'Unchanged', title: 'Unchanged Users', key: 'accountsToDelete', color: '#0287d4'}, // Red

      // Duplicate data is stored as an object with the key being the value that is duplicated (name or email). We also don't want to include these in the pie charts, because they are already included in the other data above
      {shortText: 'Duplicate Names', title: 'Users with Duplicate Names', key: 'accountsWithDuplicateNames', isDuplicate: true, excludeFromOverview: true},
      {shortText: 'Duplicate Emails', title: 'Users with Duplicate Emails', key: 'accountsWithDuplicateEmails', isDuplicate: true, excludeFromOverview: true},

      {shortText: 'No Homeroom', title: 'Users with No Homeroom', key: 'accountsWithNoHomeroom', excludeFromOverview: true},
    ]

    const parsedOverviewData = [['Action', '# of Users']]

    DATA_CONFIGS.forEach(config => {
      config.data = data[config.key] ?? []

      let dataIsPresent = false
      if (config.data.length > 0) {
        dataIsPresent = true
      }

      const dataLength = config.data.length

      // Only add configs that have data
      if (!config.excludeFromOverview && dataLength > 0) {
        parsedOverviewData.push([config.shortText, dataLength])
      }

      // Since duplicate values are stored in an object, we check if there are any keys in the object
      if (config.isDuplicate && Object.keys(config.data).length > 0) {
        dataIsPresent = true
      }

      // Only add configs that have data
      if (dataIsPresent) {
        parsedConfigs.push(config)
      }
    })

    setCurrentConfigs(parsedConfigs)
    setOverviewData(parsedOverviewData)
  }, [data])

  // Handles the colors of the pie chart.
  // The colors are just an array of hex values, corresponding to the order of the data in the pie chart
  useEffect(() => {
    const colors = []
    currentConfigs?.forEach(config => {
      if (config.excludeFromOverview || config.isOverview) {
        return
      }

      let color = '#a7a7a7'

      // In the overview, all the colors are the same. In each tab, we highlight the color on the chart for that tab
      if (selectedConfig?.isOverview || selectedConfig === config) {
        color = config.color
      }

      colors.push(color)
    })

    setChartColorOptions(colors)
  }, [selectedConfig, currentConfigs])

  // Reset the viewedConfigKeys when the configs change
  useEffect(() => {
    setViewedConfigKeys([])
  }, [currentConfigs])

  // When the selected config changes, we want to mark it as viewed if we are in simulation mode
  useEffect(() => {
    if (!selectedConfig || !isSimulation) {
      return
    }

    setViewedConfigKeys(prevData => ({...prevData, [selectedConfig.key]: true}))
  }, [selectedConfig])

  // If all the configs have been viewed during simulation mode, we let the context know
  useEffect(() => {
    if (isSimulation) {
      setAllSimulationDataIsViewed(Object.keys(viewedConfigKeys).length === currentConfigs.length)
    }
  }, [viewedConfigKeys])

  return (
    <Box textAlign="center">
      {currentConfigs.length > 0 &&
        <>
          <Divider sx={{my: 3}} />

          <RbText h2>Results</RbText>
          {isSimulation && <RbText color="gray">Please review all simulated data before proceeding</RbText>}

          <Flex centered gap={2} sx={{mt: 2}}>
            {currentConfigs.map(config => {
              const configWasViewed = !isSimulation || viewedConfigKeys[config.key]

              return (
                <RbButton
                  key={config.shortText}
                  color={config === selectedConfig ? 'primary' : 'cancel'}
                  onClick={() => setSelectedConfig(config)}
                  sx={{border: configWasViewed ? '' : '1px solid red'}}
                >
                  {config.shortText}
                </RbButton>
              )
            })}
          </Flex>
        </>
      }

      {selectedConfig &&
        <>
          <RbText h2 alignLeft sx={{ml: 5, mt: 5}}>{selectedConfig.title}</RbText>

          <Flex>
            {!selectedConfig.excludeFromOverview &&
              <PieChart
                data={overviewData}
                sx={{width: `${selectedConfig.isOverview ? '100%' : '25%'} `}}
                options={{
                  legend: selectedConfig.isOverview ? '' : 'none',
                  pieSliceText: selectedConfig.isOverview ? 'value' : 'label',
                  colors: chartColorOptions
                }}
              />
            }

            {selectedConfig && !selectedConfig.isOverview &&
              <Box sx={{minWidth: 0, flexGrow: 1}}>
                {selectedConfig &&
                  <RecordTable
                    records={getParsedRows()}
                    preHeaderSlot={(
                      <TableRow>
                        <TableCell component="th" colSpan={5}>Student</TableCell>
                        <TableCell component="th" colSpan={4} sx={{borderLeft: '1px solid rgba(224, 224, 224, 1)'}}>Homeroom</TableCell>
                      </TableRow>
                    )}
                    columnConfigs={[
                      {title: 'First', renderCell: renderFirstNameCell},
                      {title: 'Last', dataKey: 'lastName', dataType: 'string'},
                      {title: 'Email', dataKey: 'email', dataType: 'string'},
                      {title: 'Grade', dataKey: 'grade', dataType: 'number'},
                      {title: 'Internal Id', dataKey: 'internalId', dataType: 'string'},
                      {title: 'First', dataKey: 'teacherFirstName', headerSx: {borderLeft: '1px solid  rgba(224, 224, 224, 1)'}, dataType: 'string'},
                      {title: 'Last', dataKey: 'teacherLastName', dataType: 'string'},
                      {title: 'Email', dataKey: 'teacherEmail', dataType: 'string'},
                      {title: 'Internal Id', dataKey: 'teacherInternalId', dataType: 'string'},
                    ]}
                    headerTextNoWrap
                  />
                }
              </Box>
            }
          </Flex>
        </>
      }
    </Box>
  )
}

export default Results