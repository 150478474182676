import React from 'react'
import App from './App'
import './index.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import {ThemeProvider} from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import theme from './theme'
import {CurrentUserProvider} from './context/CurrentUserContext'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import {LocalizationProvider} from '@mui/x-date-pickers'
import {BrowserRouter} from 'react-router-dom'
import {createRoot} from 'react-dom/client'
import {ConfirmationProvider} from './context/ConfirmationContext'
import BasicErrorBoundary from './errors/BasicErrorBoundary'
import ErrorBoundary from './errors/ErrorBoundary'

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript

root.render(
  <React.StrictMode>
    <BasicErrorBoundary>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <CurrentUserProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <ConfirmationProvider>
                <ErrorBoundary>
                  <ToastContainer />
                  <App />
                </ErrorBoundary>
              </ConfirmationProvider>
            </LocalizationProvider>
          </CurrentUserProvider>
        </ThemeProvider>
      </BrowserRouter>
    </BasicErrorBoundary>
  </React.StrictMode>
)

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
  })
}