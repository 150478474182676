import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import apiRequests from '../../../api/apiRequests'
import {useCurrentUser} from '../../../context/CurrentUserContext'
import {Container, FormControl} from '@mui/material'
import RbModal from '../../common/components/RbModal'
import Flex from '../../common/components/Flex'
import RbButton from '../../common/inputs/RbButton'
import UserSelect from '../../common/inputs/UserSelect'
import {bind} from '../../../utils/helpers'
import RbText from '../../common/components/RbText'
import useUsers from '../../../hooks/useUsers'
import useApiRequest from '../../../hooks/useApiRequest'
import RbLoading from '../../common/components/RbLoading'
import RecordTable from '../../common/components/RecordTable'

const EditHomeroom = ({onClose, user}) => {
  const {currentUser} = useCurrentUser()
  const {students} = useUsers({includeStudents: true})

  const [userBeingEdited, setUserBeingEdited] = useState(null)
  const [pendingStudents, setPendingStudents] = useState([])
  const [currentStudents, setCurrentStudents] = useState([])

  const requests = {
    getHomeroomStudents: useApiRequest({
      request: apiRequests.users.getHomeroomStudents,
      errorText: 'There was an error loading the homeroom students',
      onResponse: setCurrentStudents
    }),
    createHomeroomStudents: useApiRequest({
      request: apiRequests.users.createHomeroomStudents,
      errorText: 'There was an error adding the students to the homeroom',
    })
  }

  useEffect(() => {
    setUserBeingEdited(user ?? currentUser)
    setCurrentStudents([])
  }, [currentUser, user])

  useEffect(() => {
    if (!userBeingEdited) {
      return
    }

    requests.getHomeroomStudents.send(userBeingEdited.id)
  }, [userBeingEdited])

  const handleSubmit = () => {
    const mappedStudents = pendingStudents.map(student => ({
      ...student,
      homeroomAccountId: userBeingEdited.id
    }))

    requests.createHomeroomStudents.send(userBeingEdited.id, mappedStudents)
      .then(data => {
        toast.success('Successfully added ' + mappedStudents.length + ' student(s)')
        setCurrentStudents(data)
        setPendingStudents([])
      })
  }

  return (
    <RbModal open title={`Edit ${userBeingEdited?.fullName ?? 'User\'s'} Homeroom`} onClose={onClose}>
      <Container>
        <FormControl fullWidth sx={{mb: 4}}>
          <Flex alignItems="flex-end" justifyBetween>
            <UserSelect
              users={students}
              label="Students"
              ignoreUsers={currentStudents}
              sx={{mr: 2}}
              {...bind.recordSelect(pendingStudents, setPendingStudents)}
              multiple
            />

            <RbButton
              disabled={pendingStudents.length === 0}
              onClick={handleSubmit}
              type="button"
              isLoading={requests.createHomeroomStudents.isLoading}
            >
              Add
            </RbButton>
          </Flex>
        </FormControl>

        {!currentStudents && <RbLoading />}

        {currentStudents &&
          <>
            {currentStudents.length === 0 && <RbText h5>No Students</RbText>}

            {currentStudents.length > 0 &&
              <>
                <RbText h4>Current Students</RbText>

                <RecordTable
                  records={currentStudents}
                  columnConfigs={[
                    {dataKey: 'firstName', title: 'First Name', dataType: 'string'},
                    {dataKey: 'lastName', title: 'Last Name', dataType: 'string'},
                  ]}
                  isPaginated={false}
                  isSortable
                  defaultSortKey="lastName"
                />
              </>
            }
          </>
        }
      </Container>
    </RbModal>
  )
}

export default EditHomeroom