import React, {useEffect, useState} from 'react'
import apiRequests from '../../../api/apiRequests'
import {useSchedule} from '../ScheduleContext'
import StudentDayCard from './StudentDayCard'
import {useCurrentUser} from '../../../context/CurrentUserContext'
import RbLoading from '../../common/components/RbLoading'
import useApiRequest from '../../../hooks/useApiRequest'

const StudentSchedule = ({weekInfo}) => {
  const {currentUserIsStudent} = useCurrentUser()
  const {viewingUser, setNumberOfWeeks} = useSchedule()
  const [locationInfo, setLocationInfo] = useState(null)
  const [scheduleInfo, setScheduleInfo] = useState(null)

  const requests = {
    getStudentSchedule: useApiRequest({
      request: apiRequests.attendance.getStudentSchedule,
      errorText: 'There was an error getting the schedule',
    }),
    getLocationsForWeek: useApiRequest({
      request: apiRequests.signUpLocations.getLocationsForWeek,
      errorText: 'There was an error getting the open locations',
    })
  }

  const getCurrentLocation = day => scheduleInfo?.days?.find(schedule => schedule.dayNum === day.weekDay)?.periods[0]

  const getLocationsForDay = day => {
    const period = locationInfo?.days?.find(location => location.dayNum === day.weekDay)?.periods[0]

    if (!period) {
      return []
    }

    return period.locations?.map(location => ({
      ...location,
      name: location.teacherName + ' - ' + (location.locationSummary ?? '(No Summary)'),
      id: location.locationId,
      asupId: period.asupId
    }))
      .sort((a, b) => {
        const lastNameA = a.teacherLastName
        const lastNameB = b.teacherLastName
        return (lastNameA < lastNameB) ? -1
          : ((lastNameA > lastNameB) ? 1 : 0)
      })

  }

  const refresh = () => {
    requests.getStudentSchedule.send(viewingUser.id, weekInfo.id)
      .then(data => {
        setScheduleInfo(data)

        if (currentUserIsStudent) {
          setNumberOfWeeks(data.secondWeekEnabled ? 2 : 1)
        }
      })

    requests.getLocationsForWeek.send(weekInfo.id)
      .then(data => setLocationInfo(data))
  }

  useEffect(() => {
    refresh()
  }, [])

  return (
    <>
      {!scheduleInfo && <RbLoading />}

      {scheduleInfo && weekInfo.days.map(day => (
        <StudentDayCard
          dayInfo={day}
          key={day.date}
          location={getCurrentLocation(day)}
          allLocations={getLocationsForDay(day)}
          onRefreshNeeded={refresh}
        />
      ))}
    </>
  )
}
export default StudentSchedule