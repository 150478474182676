import React from 'react'
import TakeAttendanceTab from './take-attendance/TakeAttendanceTab'
import RbTabs from '../common/components/RbTabs'
import HomeroomTab from './homeroom/HomeroomTab'
import PageTitle from '../common/components/PageTitle'

const Attendance = () => {
  // Set the title in the browser's tab
  document.title = 'Attendance'

  return (
    <>
      <PageTitle>Attendance</PageTitle>

      <RbTabs
        tabs={[
          {label: 'Take Attendance', render: () => <TakeAttendanceTab />},
          {label: 'Homeroom', render: () => <HomeroomTab />},
        ]}
      />
    </>
  )
}

export default Attendance