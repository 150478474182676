import React from 'react'
import Flex from '../components/Flex'
import RbButton from './RbButton'
const SaveButtons = ({onSave, onCancel, isLoading, sx, saveButtonText = 'Save', saveIsDisabled}) => {
  return (
    <Flex alignCenter justifyContent="flex-end" gap={2} sx={sx}>
      <RbButton
        color="cancel"
        onClick={onCancel}
      >
        Cancel
      </RbButton>
      <RbButton type="submit" onClick={onSave} isLoading={isLoading} disabled={saveIsDisabled}>{saveButtonText}</RbButton>
    </Flex>
  )
}
export default SaveButtons