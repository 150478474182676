import React, {useEffect} from 'react'
import apiRequests from '../../../../../../../api/apiRequests'
import {useSisIntegration} from '../../../SisIntegrationContext'
import ImportType from './ImportType'
import RbLoading from '../../../../../../common/components/RbLoading'
import {Box} from '@mui/material'
import Flex from '../../../../../../common/components/Flex'
import RbSelect from '../../../../../../common/inputs/RbSelect'
import {bind} from '../../../../../../../utils/helpers'
import RbText from '../../../../../../common/components/RbText'
import useApiRequest from '../../../../../../../hooks/useApiRequest'

const ImportSettings = () => {
  const {currentConnection, setCurrentSchoolId, currentSchoolId} = useSisIntegration()

  const requests = {
    getSchools: useApiRequest({
      request: apiRequests.sis.migration.getSchools,
      errorText: 'There was an error loading schools.',
    })
  }

  const schools = requests.getSchools.data

  // Load schools when the connection is set
  useEffect(() => {
    if (!currentConnection) {
      return
    }

    requests.getSchools.send(currentConnection.id)
  }, [currentConnection])

  return (
    <>
      {!schools && <RbLoading />}

      {schools &&
        <Box textAlign="left" mt={5}>
          <Box sx={{maxWidth: 400, margin: 'auto'}}>
            <RbText h5>Selected Connection</RbText>
            <RbText h6>{currentConnection.connectionNickname}</RbText>

            <RbText h5 sx={{mt: 4}}>School</RbText>
            <Flex justifyCenter>
              <RbSelect options={schools} labelKey="name" valueKey="sourcedId" {...bind.select(currentSchoolId, setCurrentSchoolId)} />
            </Flex>
          </Box>

          {/* Import type */}
          {currentSchoolId && <ImportType />}
        </Box>
      }
    </>
  )
}

export default ImportSettings