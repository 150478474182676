import moment from 'moment'
import React, {useMemo, useState, useEffect} from 'react'
import apiRequests from '../../api/apiRequests'
import {parseUserLevel, useCurrentUser} from '../../context/CurrentUserContext'
import useApiRequest from '../../hooks/useApiRequest'
import useUsers from '../../hooks/useUsers'
import {toast} from 'react-toastify'
import RbText from '../common/components/RbText'
import {Divider} from '@mui/material'
const ScheduleContext = React.createContext()

const ScheduleProvider = ({children, viewingUserId, date, overrideNumberOfWeeks}) => {
  const [schedule, setSchedule] = useState(null)
  const [days, setDays] = useState([])
  const [viewingUser, setViewingUser] = useState(null)
  const [selectedDate, setSelectedDate] = useState(date ?? new Date())
  const [weekStartDates, setWeekStartDates] = useState([])
  const [copiedLocation, setCopiedLocation] = useState(null)
  const [numberOfWeeks, setNumberOfWeeks] = useState(2)

  const {currentUser} = useCurrentUser()
  const {getUser} = useUsers()

  const isMe = () => !viewingUserId || viewingUserId === currentUser?.id

  const viewingUserLevelInfo = parseUserLevel(viewingUser)

  const getSignUpDaysRequest = useApiRequest({
    request: apiRequests.signUpDays.get,
    onResponse: setDays,
    errorText: 'There was an error getting the sign up days'
  })

  const copyLocation = location => {
    setCopiedLocation(location)
    toast.success((
      <>
        <RbText h4>Location Copied</RbText>
        <Divider />
        <RbText h5>{location.summary}</RbText>
        <RbText>{location.description}</RbText>
      </>
    ), {autoClose: 5000, position: 'top-center'})
  }

  useEffect(() => {
    if (overrideNumberOfWeeks) {
      setNumberOfWeeks(overrideNumberOfWeeks)
    }
  }, [overrideNumberOfWeeks])

  useEffect(() => {
    if (isMe()) {
      setViewingUser(currentUser)
    } else if (viewingUserId) {
      getUser(viewingUserId)
        .then(setViewingUser)
    }
  }, [viewingUserId, currentUser])

  useEffect(() => {
    if (days.length === 0) {
      getSignUpDaysRequest.send()
    }

    let currentStartDate = moment(selectedDate).weekday(0)
    const startDates = []

    for (let x = 0; x < numberOfWeeks; x++) {
      startDates.push(currentStartDate.toDate())
      currentStartDate = currentStartDate.add(1, 'week')
    }

    setWeekStartDates(startDates)
  }, [selectedDate, numberOfWeeks])

  const contextValue = useMemo(() => ({
    schedule,
    setSchedule,
    days,
    isMe: isMe(),
    viewingUser,
    selectedDate,
    setSelectedDate,
    isTeacherSchedule: viewingUserLevelInfo.isTeacherOrAbove,
    isStudentSchedule: viewingUserLevelInfo.isStudent,
    weekStartDates,
    copiedLocation,
    copyLocation,
    setCopiedLocation,
    setNumberOfWeeks,
    viewScheduleForUser: id => window.location.replace(`/schedule${id ? `/${id}` : ''}`)
  }), [schedule, days, viewingUser, selectedDate, weekStartDates, copiedLocation, numberOfWeeks])

  return (
    <ScheduleContext.Provider value={contextValue}>
      {children}
    </ScheduleContext.Provider>
  )
}

const useSchedule = () => {
  const context = React.useContext(ScheduleContext)
  if (context === undefined) {
    throw new Error('useSchedule must be used within a ScheduleProvider')
  }
  return context
}

export {ScheduleProvider, useSchedule}