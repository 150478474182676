import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {saveAs} from 'file-saver'
import RbSelect from '../../common/inputs/RbSelect'
import RbButton from '../../common/inputs/RbButton'
import {bind, camelToTitleCase, getLocalData, saveLocalData} from '../../../utils/helpers'
import {Box, Checkbox, FormControlLabel, Grid, Stack} from '@mui/material'
import apiRequests from '../../../api/apiRequests'
import RbText from '../../common/components/RbText'
import useApiRequest from '../../../hooks/useApiRequest'
import DayOfTheWeekSelect from '../../common/inputs/DayOfTheWeekSelect'
import moment from 'moment'

const Reports = () => {
  const [dayNum, setDayNum] = useState(moment().weekday())
  const [reportType, setReportType] = useState('absent')
  const [allColumns, setAllColumns] = useState()
  const [columnsWithValues, setColumnsWithValues] = useState()
  const [selectedColumns, setSelectedColumns] = useState([])

  const CACHE_DATA_KEY = 'admin-student-attendance-report-selected-columns'

  const requests = {
    export: {
      absent: useApiRequest({
        request: apiRequests.attendance.exportStudents.absent,
        errorText: 'There was an error exporting the absent students'
      }),
      tardy: useApiRequest({
        request: apiRequests.attendance.exportStudents.tardy,
        errorText: 'There was an error exporting the tardy students'
      })
    }
  }

  const currentRequest = requests.export[reportType]

  const getColumns = () => {
    if (!(reportType && dayNum >= 0)) {
      return
    }

    setColumnsWithValues(null)
    setAllColumns(null)

    currentRequest.send(dayNum)
      .then(students => {
        const all = []
        const withValues = []
        // Loop through the students, and extract the different columns we can download.
        students.forEach(student => {
          Object.keys(student).forEach(key => {
            // Track columns with values
            if (student[key] && !withValues?.includes(key)) {
              withValues.push(key)
            }

            // Track all columns
            if (!all?.includes(key)) {
              all.push(key)
            }
          })
        })

        setAllColumns(all)
        setColumnsWithValues(withValues)

        // Removed selected columns that no longer have values
        setSelectedColumns(prevData => prevData.filter(col => withValues.includes(col)))
      })
  }

  const generate = () => {
    saveLocalData(CACHE_DATA_KEY, selectedColumns)

    currentRequest.send(dayNum)
      .then(data => {
        const csvData = []

        const headers = selectedColumns.map(column => camelToTitleCase(column))

        // Add the headers
        csvData.push(headers)
        // Add the values
        data.forEach(record => {
          const row = selectedColumns.map(column => record[column])
          csvData.push(row)
        })

        const csvContent = csvData.map(row => row.join(',')).join('\n')

        const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8'})
        saveAs(blob, `${reportType}-students-${new Date().toISOString()}.csv`)
        toast.success('Successfully downloaded the report')
      })
  }

  const toggleColumn = columnToToggle => {
    setSelectedColumns(prevData => {
      if (prevData.includes(columnToToggle)) {
        return prevData.filter(col => col !== columnToToggle)
      } else {
        return [...prevData, columnToToggle]
      }
    })
  }

  // Load selected columns from local storage
  useEffect(() => {
    setSelectedColumns(getLocalData(CACHE_DATA_KEY) || [])
  }, [])

  // Refresh columns when report type or day of the week changes
  useEffect(getColumns, [reportType, dayNum])

  return (
    <>
      <RbText h4 sx={{mb: 4}}>Attendance Report</RbText>

      <Stack spacing={2} sx={{maxWidth: '100%', width: 500}}>
        <RbSelect
          label="Report Type"
          options={[
            {title: 'Absent Students', value: 'absent'},
            {title: 'Tardy Students', value: 'tardy'}
          ]}
          valueKey="value"
          labelKey="title"
          disabled={!allColumns}
          {...bind.select(reportType, setReportType)}
        />
        {reportType &&
          <>
            <DayOfTheWeekSelect
              label="Week Day"
              disabled={!allColumns}
              {...bind.select(dayNum, setDayNum)}
            />

            {allColumns &&
              <>
                {allColumns.length === 0 && <RbText h5>No {reportType} students found for this day</RbText>}

                {allColumns.length > 0 &&
                  <>
                    <Box>
                      <RbText h5 sx={{mt: 1}}>Select the columns you want to include in the report</RbText>
                      <Grid container spacing={1}>
                        {allColumns.map(column => (
                          <Grid item key={column}>
                            <FormControlLabel
                              label={camelToTitleCase(column)}
                              control={(
                                <Checkbox
                                  disabled={!columnsWithValues?.includes(column)}
                                  checked={selectedColumns?.includes(column)}
                                  onChange={() => toggleColumn(column)}
                                />
                              )}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>

                    <RbButton
                      icon="schedule"
                      onClick={generate}
                      isLoading={currentRequest.isLoading}
                      disabled={selectedColumns?.length === 0}
                    >
                      Generate CSV
                    </RbButton>
                  </>
                }
              </>
            }
          </>
        }
      </Stack>
    </>
  )
}

export default Reports
