// Bind allows easier binding of state to inputs. It also allows for binding to object keys. This is useful for when you have a state object that you want to bind to multiple inputs. Instead of having to create a separate state for each input, you can just create one state object and bind to it.
// To implement the bindings, you can call the function and spread it, essentially passing the return values of the bind as properties to the input.
// For example, instead of:
// <input value={value} onChange={e => onChange(e.target.value)} />
// You can do:
// <input {...bind.input(value, onChange)} />
// Or, if you want to bind to an object key:
// <input {...bind.object.input(obj, key, setObj)} />
// This will bind the input to the value of obj[key] and call setObj with the new value when the input changes.
// The key is to match the keys of the return values of the bind functions to the names of the properties of the input. For example, the bind.input function returns an object with a "value" and "onChange" property. These are the same names as the actual properties for the input.
// Copilot wrote all of this. It might be a little long winded, but it's very helpful.
export const bind = {
  input: (value, onChange) => ({
    value,
    onChange: val => onChange(val),
  }),
  datePicker: (value, onChange) => ({value, onChange}),
  switch: (value, onChange) => ({
    checked: Boolean(value),
    onChange: e => onChange(e.target.checked),
  }),
  select: (value, onChange) => ({value, onChange}),
  recordSelect: (selectedRecords, onChange) => ({selectedRecords, onChange}),
  checkbox: (checked, onChange) => ({
    checked: Boolean(checked),
    onChange: e => onChange(e.target.checked),
  }),
  timePicker: (value, onChange) => ({value, onChange}),
  recordSearch: (records, onFilter) => ({records, onFilter}),
  recordTableSelection: (selectedRecords, onSelectChange) => ({selectedRecords, onSelectChange}),
  object: {
    do: (obj, key, setter, func) => func(obj[key], value => setObjectKeyState(setter, key, value)),
    input: (obj, key, setter) => bind.object.do(obj, key, setter, bind.input),
    switch: (obj, key, setter) => bind.object.do(obj, key, setter, bind.switch),
    select: (obj, key, setter) => bind.object.do(obj, key, setter, bind.select),
    recordSelect: (obj, key, setter) => bind.object.do(obj, key, setter, bind.recordSelect),
    recordSearch: (obj, key, setter) => bind.object.do(obj, key, setter, bind.recordSearch),
  }
}

// Sets the value of a key in an object state
export const setObjectKeyState = (setter, key, value) => {
  setter(prev => ({...prev, [key]: value}))
}

// "Empty Coalesce" - returns the first non-empty value
export const eCoal = (val, coalVal = '-') => {
  val = val?.toString()
  val = val?.trim()
  val = val ?? ''

  return val === '' ? coalVal : val
}

export const daysOfTheWeek = [
  {dayOfWeek: 0, title: 'Sunday'},
  {dayOfWeek: 1, title: 'Monday'},
  {dayOfWeek: 2, title: 'Tuesday'},
  {dayOfWeek: 3, title: 'Wednesday'},
  {dayOfWeek: 4, title: 'Thursday'},
  {dayOfWeek: 5, title: 'Friday'},
  {dayOfWeek: 6, title: 'Saturday'}
]

export const getDayOfWeekTitle = dayInt => {
  return daysOfTheWeek[dayInt]?.title ?? ''
}

export const camelToTitleCase = str => {
  const result = str.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export const saveLocalData = (key, data) => localStorage.setItem(key, JSON.stringify(data))

export const getLocalData = key => JSON.parse(localStorage.getItem(key))

export const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

export const isLocalDev = () => window.location.hostname === 'localhost'

export const getPlurality = (count, singular, plural) => {
  const actualPlural = plural ?? `${singular}s`
  return count === 1 ? singular : actualPlural
}