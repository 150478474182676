import {eCoal} from '../../utils/helpers'

export const getRequestType = (groupOrTeacherEnum, priorityNum, wasAutoScheduled) => {
  let returnValue
  if (priorityNum) {
    returnValue = groupOrTeacherEnum ? 'Group (lvl ' + priorityNum + ')' : 'Teacher (lvl ' + priorityNum + ')'
  } else if (wasAutoScheduled) {
    returnValue = 'Autoscheduled (lvl 0)'
  }
  return eCoal(returnValue, '-')
}