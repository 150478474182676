import {Box} from '@mui/material'
import React from 'react'

const Flex = ({children, centered, alignCenter, justifyCenter, justifyBetween, column, direction = 'row', justifyContent = 'flex-start', alignItems = 'flex-start', sx, onClick, className, wrap, gap}) => {
  const justify = justifyBetween ? 'space-between' : justifyContent
  return (
    <Box
      sx={{
        ...sx,
        display: 'flex',
        flexDirection: column ? 'column' : direction,
        justifyContent: (centered || justifyCenter) ? 'center' : justify,
        alignItems: (centered || alignCenter || !alignItems) ? 'center' : alignItems, // Default to center if alignItems is undefined
        flexWrap: wrap ? 'wrap' : 'nowrap',
        gap: gap
      }}
      onClick={onClick}
      className={className}
    >
      {children}
    </Box>
  )
}

export default Flex