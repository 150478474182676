import React, {useEffect, useState} from 'react'
import RbModal from '../../common/components/RbModal'
import apiRequests from '../../../api/apiRequests'
import useUsers from '../../../hooks/useUsers'
import RbText from '../../common/components/RbText'
import {useSchedule} from '../ScheduleContext'
import {Box, Divider} from '@mui/material'
import moment from 'moment'
import RecordTable from '../../common/components/RecordTable'
import RbLoading from '../../common/components/RbLoading'
import {bind, eCoal} from '../../../utils/helpers'
import RbTextField from '../../common/inputs/RbTextField'
import useWeek from '../../../hooks/useWeek'
import useApiRequest from '../../../hooks/useApiRequest'

const MasterSchedule = ({onClose}) => {
  const [schedules, setSchedules] = useState(null)
  const [schedulesByTeacher, setSchedulesByTeacher] = useState(null)
  const [search, setSearch] = useState('')

  const {teachers} = useUsers({includeTeachers: true})
  const {days} = useSchedule()
  const {weekId} = useWeek()

  const today = moment()

  const parsedDays = days.map(day => {
    const currentDate = today.weekday(day.weekDay)

    return {
      ...day,
      date: currentDate,
      prettyDate: currentDate.format('M/D'),
    }
  })

  const requests = {
    getSignUpLocations: useApiRequest({
      request: apiRequests.signUpLocations.get,
      errorText: 'There was an error loading the schedules',
      onResponse: setSchedules
    })
  }

  useEffect(() => {
    if (!weekId) {
      return
    }

    requests.getSignUpLocations.send(null, weekId)
  }, [weekId])

  useEffect(() => {
    if (!schedules || !teachers) {
      return
    }

    let filteredTeachers = teachers

    if (search?.length > 0) {
      filteredTeachers = teachers.filter(teacher => teacher.fullName.toLowerCase().includes(search.toLowerCase()))
    }

    const newSchedule = []

    filteredTeachers.forEach(teacher => {
      const teacherSchedule = schedules.filter(schedule => schedule.accountId === teacher.id)

      const dailySchedules = []

      parsedDays.forEach(day => {
        const scheduleForDay = teacherSchedule.find(schedule => schedule.absolutePeriod?.period?.day?.id === day.id)

        dailySchedules.push({
          id: scheduleForDay?.id,
          prettyDate: day.prettyDate,
          summary: eCoal(scheduleForDay?.summary),
          description: eCoal(scheduleForDay?.description),
          location: eCoal(scheduleForDay?.roomNumber),
          attendance: `${scheduleForDay?.attendanceCount ?? 0}/${scheduleForDay.studentLimit ?? 0}`
        })
      })

      newSchedule.push({
        teacher,
        days: dailySchedules
      })
    })

    setSchedulesByTeacher(newSchedule)
  }, [schedules, teachers, search])

  return (
    <RbModal title="Teacher Schedules" open onClose={onClose} width={900}>
      {requests.getSignUpLocations.isLoading && <RbLoading logo size={80} />}

      {schedulesByTeacher &&
        <>
          <RbTextField
            icon="search"
            type="text"
            label="Search"
            isDebounced
            autoFocus
            sx={{mb: 2}}
            {...bind.input(search, setSearch)}
          />

          <Divider sx={{mt: 1, mb: 3}} />

          {schedulesByTeacher?.length === 0 &&
            <RbText h4>No Schedules Found</RbText>
          }

          {schedulesByTeacher?.length > 0 &&
            <Box sx={{overflow: 'auto', height: 600, px: 2}}>
              {schedulesByTeacher?.map(info => (
                <React.Fragment key={info.teacher.id}>
                  <RbText h4>{info.teacher.fullName}</RbText>
                  <RecordTable
                    records={info.days}
                    columnConfigs={[
                      {title: 'Date', dataKey: 'prettyDate', dataType: 'date'},
                      {title: 'Summary', dataKey: 'summary', dataType: 'string'},
                      {title: 'Description', dataKey: 'description', dataType: 'string'},
                      {title: 'Location', dataKey: 'location', dataType: 'string'},
                      {title: 'Attendance', dataKey: 'attendance', dataType: 'string'}
                    ]}
                    isPaginated={false}
                    sx={{mb: 4}}
                  />
                </React.Fragment>
              ))}
            </Box>
          }
        </>
      }
    </RbModal>
  )
}

export default MasterSchedule