import React, {useEffect, useState} from 'react'
import {useAttendance} from '../AttendanceContext'
import apiRequests from '../../../api/apiRequests'
import {Divider} from '@mui/material'
import RecordTable from '../../common/components/RecordTable'
import AttendanceCheck from './AttendanceCheck'
import {toast} from 'react-toastify'
import {STATUS_CODES} from '../constants'
import RbButton from '../../common/inputs/RbButton'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import Flex from '../../common/components/Flex'
import moment from 'moment'
import RbDatePicker from '../../common/inputs/RbDatePicker'
import {bind} from '../../../utils/helpers'
import RbText from '../../common/components/RbText'
import RbLoading from '../../common/components/RbLoading'
import useApiRequest from '../../../hooks/useApiRequest'

const TakeAttendanceTab = () => {
  const {selectedDate, weekId, viewingUser, setSelectedDate} = useAttendance()
  const [attendance, setAttendance] = useState(null)
  const [allSelected, setAllSelected] = useState(null)

  const requests = {
    getMyPeriod: useApiRequest({
      request: apiRequests.attendance.getMyPeriod,
      errorText: 'There was an error loading the attendance.',
    }),
    updateAttendance: useApiRequest({
      request: apiRequests.attendance.update,
      errorText: 'There was an error updating the attendance.',
      onResponse: () => toast.success('Attendance Successfully Taken!'),
    })
  }

  const refresh = () => {
    if (!weekId || !viewingUser) {
      return
    }

    requests.getMyPeriod.send(viewingUser.id, weekId)
      .then(data => {
        const currentDay = data.days.find(day => day.dayNum === selectedDate.getDay())
        const period = currentDay?.periods[0] ?? {}
        const parsedRecords = (period.attendanceAndAccounts ?? []).map(record => ({
          id: record.attenId,
          ...record
        }))
        setAttendance(parsedRecords)
      })
  }

  const renderActionHeader = statusCode => {
    return (
      <AttendanceCheck
        statusCode={statusCode}
        allSelected={allSelected}
        onAllSelected={handleAllSelected}
        isAllCheckbox
      />
    )
  }

  const renderActionCell = (record, statusCode) => {
    return (
      <AttendanceCheck
        record={record}
        statusCode={statusCode}
        allSelected={allSelected}
        onChange={handleChange}
      />
    )
  }

  const handleAllSelected = value => {
    setAttendance(prevData => {
      prevData.forEach(record => record.attendanceStatus = value)
      return [...prevData]
    })
  }

  const handleChange = (recordId, value) => {
    setAttendance(prevData => {
      const record = prevData.find(rec => rec.attenId === recordId)

      if (record) {
        record.attendanceStatus = value
      }

      return [...prevData]
    })
  }

  const save = () => {
    const body = attendance.map(record => ({
      id: record.attenId,
      status: record.attendanceStatus,
    }))

    requests.updateAttendance.send(body)
      .then(refresh)
  }

  const generatePdf = (name, cols, rows, date) => {
    const doc = new jsPDF('p', 'pt', 'letter')

    autoTable(doc, {
      head: cols,
      body: rows,
      didDrawPage: () => {
        doc.text(30, 30, name + '\'s Rebentify Attendance for ' + date)
      }
    })

    doc.save(date.replace('/', '_') + '_' + name.replace(' ', '') + '_attendance')
  }

  const handlePdfButton = () => {
    const cols = [['Name', 'Grade', 'Present', 'Tardy', 'Absent']]
    const rows = []

    attendance.sort((a, b) => {
      return a.studentLastName.localeCompare(b.studentLastName)
    })

    attendance.forEach(atten => {
      const status = atten.attendanceStatus
      const row = [
        atten.studentName,
        atten.studentGrade,
        `[ ${status === STATUS_CODES.PRESENT ? 'X' : ' '} ]`,
        `[ ${status === STATUS_CODES.TARDY ? 'X' : ' '} ]`,
        `[ ${status === STATUS_CODES.ABSENT ? 'X' : ' '} ]`
      ]
      rows.push(row)
    })

    generatePdf(viewingUser?.fullName, cols, rows, selectedDate.toLocaleDateString())
  }

  useEffect(() => {
    refresh()
  }, [weekId, viewingUser, selectedDate])

  // As the attendance data changes, check if all the attendance statuses are the same for each status code.
  useEffect(() => {
    if (!attendance) {
      return
    }

    const allPresent = attendance.every(record => record.attendanceStatus === STATUS_CODES.PRESENT)
    const allAbsent = attendance.every(record => record.attendanceStatus === STATUS_CODES.ABSENT)
    const allTardy = attendance.every(record => record.attendanceStatus === STATUS_CODES.TARDY)

    if (allPresent) {
      setAllSelected(STATUS_CODES.PRESENT)
    } else if (allAbsent) {
      setAllSelected(STATUS_CODES.ABSENT)
    } else if (allTardy) {
      setAllSelected(STATUS_CODES.TARDY)
    } else {
      setAllSelected(null)
    }
  }, [attendance])

  return (
    <>
      <Flex justifyBetween alignCenter>
        <RbText h5 sx={{mt: 2}}>
          <b>{moment(selectedDate).format('dddd, MMMM Do')}</b>
          {attendance ? ` - ${attendance.length} Students` : ''}
        </RbText>

        <RbDatePicker {...bind.datePicker(selectedDate, setSelectedDate)} />
      </Flex>

      <Divider sx={{my: 2}} />

      {!attendance && <RbLoading />}

      {attendance &&
        <>
          {attendance.length === 0 && <RbText h2>There is no period for {moment(selectedDate).format('dddd, MMMM Do, YYYY')}</RbText>}

          {attendance.length > 0 &&
            <>
              <RecordTable
                columnConfigs={[
                  {title: 'First Name', dataKey: 'studentFirstName', isSortable: true, dataType: 'string'},
                  {title: 'Last Name', dataKey: 'studentLastName', isSortable: true, dataType: 'string'},
                  {title: 'Grade', dataKey: 'studentGrade', isSortable: true, dataType: 'number'},
                  {title: 'Taken By', dataKey: 'takenByAccountName', isSortable: true, dataType: 'string'},
                  {title: 'Present', renderHeader: () => renderActionHeader(STATUS_CODES.PRESENT), renderCell: record => renderActionCell(record, STATUS_CODES.PRESENT), width: 75},
                  {title: 'Tardy', renderHeader: () => renderActionHeader(STATUS_CODES.TARDY), renderCell: record => renderActionCell(record, STATUS_CODES.TARDY), width: 75},
                  {title: 'Absent', renderHeader: () => renderActionHeader(STATUS_CODES.ABSENT), renderCell: record => renderActionCell(record, STATUS_CODES.ABSENT), width: 75}
                ]}
                getRowStyle={record => record?.attendanceStatus < 1 ? {backgroundColor: '#ffefef !important'} : {}}
                records={attendance}
                defaultSortKey="studentLastName"
                height={600}
                isPaginated={false}
              />

              <Flex justifyContent="flex-end" sx={{mt: 2}}>
                <RbButton color="cancel" onClick={handlePdfButton} sx={{mr: 1}}>Generate PDF of Roster</RbButton>
                <RbButton color="primary" onClick={save} isLoading={requests.updateAttendance.isLoading}>Submit Changes</RbButton>
              </Flex>
            </>
          }
        </>
      }
    </>
  )
}
export default TakeAttendanceTab