import React, {useState} from 'react'
import {toast} from 'react-toastify'
import RbSwitch from '../../../../../common/inputs/RbSwitch'
import {bind} from '../../../../../../utils/helpers'
import {useAutomatedTasks} from '../../AutomatedTasksContext'
import apiRequests from '../../../../../../api/apiRequests'
import DailyConfig from '../../partials/DailyConfig'
import RbButton from '../../../../../common/inputs/RbButton'
import AutoscheduleExclusions from './Exclusions/AutoscheduleExclusions'
import useApiRequest from '../../../../../../hooks/useApiRequest'
import {useConfirm} from '../../../../../../context/ConfirmationContext'

const Autoschedule = () => {
  const {autoscheduleConfig, updateAutoscheduleConfig} = useAutomatedTasks()
  const [exclusionWindowIsVisible, setExclusionWindowIsVisible] = useState(false)
  const confirm = useConfirm()

  const smartscheduleEnabled = autoscheduleConfig?.smartscheduleEnabled
  const scheduleType = smartscheduleEnabled ? 'Smart Schedule' : 'Auto-Schedule'
  const actionString = 'Manually ' + scheduleType

  const requests = {
    autoscheduleStudents: useApiRequest({
      request: apiRequests.signUpPeriodConfig.autoscheduleStudents,
      throwOnError: true,
    }),
    unscheduleAutoscheduled: useApiRequest({
      request: apiRequests.attendance.unscheduleAutoscheduled,
      throwOnError: true,
    })
  }

  const manuallyAutoscheduleDay = dayConfig => {
    if (!dayConfig) {
      return
    }

    toast.promise(requests.autoscheduleStudents.send([dayConfig], smartscheduleEnabled),
      {
        pending: 'Sending ' + scheduleType + ' Request \n(this can actually take several minutes)',
        success: 'Successfully ' + scheduleType + 'd Students',
        error: 'There was an error scheduling students'
      })
  }

  const unAutoscheduleDay = dayConfig => {
    if (!dayConfig) {
      return
    }

    confirm({
      title: 'Are you sure you want to clear the schedule of autoscheduled students for this day?',
      description: 'This will clear only autoscheduled student\'s periods.'
        + ' This will not clear any manually scheduled or requested student\'s periods.'
        + ' Just note, if you don\'t run the autoscheduler manually, there is a chance the students won\'t be scheduled for the day.'
    })
      .then(() => {
        toast.promise(requests.unscheduleAutoscheduled.send([dayConfig]),
          {
            pending: 'Removing all autoscheduled students for day',
            success: 'Successfully cleared autoscheduled students for day',
            error: 'There was an error removing autoscheduled students for day'
          }
        )
      })
  }

  const renderHeaderContents = () => (
    <RbSwitch
      label="Smart Schedule"
      {...bind.switch(smartscheduleEnabled, val => updateAutoscheduleConfig('smartscheduleEnabled', val))}
    />
  )

  const renderExclusionButton = () => (
    <>
      {exclusionWindowIsVisible &&
        <AutoscheduleExclusions
          onClose={() => setExclusionWindowIsVisible(false)}
        />
      }

      <RbButton
        color="secondary"
        onClick={() => setExclusionWindowIsVisible(true)}
      >
        Exclusions
      </RbButton>
    </>
  )

  return (
    <DailyConfig
      config={autoscheduleConfig}
      updateConfig={updateAutoscheduleConfig}
      enableKey="autoscheduleEnabled"
      enableLabel="Auto-Schedule Homeroom"
      renderHeaderContents={renderHeaderContents}
      dayConfigsKey="autoscheduleDailyConfigList"
      dayLabel={`${scheduleType} Students On`}
      timeLabel="At"
      saveRequest={apiRequests.signUpPeriodConfig.updateAutoscheduleConfig}
      configName="Auto-Schedule Time"
      saveButtonText="Save Auto-Schedule Settings"
      actions={[
        {label: actionString, onClick: manuallyAutoscheduleDay, icon: 'event_available'},
        {label: 'Un-Autoschedule Students', onClick: unAutoscheduleDay, icon: 'event_busy'},
      ]}
      footerContent={renderExclusionButton()}
      canDisableDaily={false}
    />
  )
}
export default Autoschedule