import React, {useEffect, useRef, useState} from 'react'
import logo from './../../assets/rebentify_header_light_3.png'
import {Alert, Box} from '@mui/material'
import RbTextField from '../common/inputs/RbTextField'
import RbButton from '../common/inputs/RbButton'
import Flex from '../common/components/Flex'
import apiRequests from '../../api/apiRequests'
import {useCurrentUser} from '../../context/CurrentUserContext'
import {bind} from '../../utils/helpers'
import useApiRequest from '../../hooks/useApiRequest'
import {PRIVACY_POLICY_URL} from '../../utils/constants'
import RbText from '../common/components/RbText'
import moment from 'moment'
import {Copyright} from '@mui/icons-material'
import {toast} from 'react-toastify'

function Login() {
  const {saveUserToken} = useCurrentUser()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const googleSignInRef = useRef()

  const closeGoogleOneTap = () => window.google.accounts.id.cancel()

  const doLogin = token => {
    closeGoogleOneTap()
    saveUserToken(token)
  }

  const requests = {
    login: useApiRequest({
      request: apiRequests.users.login,
      onResponse: doLogin,
    }),
    googleSignIn: useApiRequest({
      request: apiRequests.users.googleSignIn,
      onResponse: doLogin,
    })
  }

  // Set the title in the browser's tab
  document.title = 'Rebentify - Login'

  const login = e => {
    e.preventDefault()

    requests.login.send(email, password)
  }

  useEffect(() => {
    window.google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      ux_mode: 'popup',
      auto_select: 'true',
      itp_support: 'true',
      callback: (res, error) => {
        if (error) {
          toast.error('There was an issue setting up Google Sign In')
        } else {
          requests.googleSignIn.send(res)
        }
      },
      cancel_on_tap_outside: false
    })
    window.google.accounts.id.renderButton(googleSignInRef.current, {
      theme: 'outlined',
      size: 'large',
      type: 'standard',
      text: 'signin_with',
    })

    window.google.accounts.id.prompt() // Open up one tap
  }, [])

  return (
    <Box className="login-background">
      <form onSubmit={login} className="login-form fade-in-incremental">
        <Flex centered sx={{mb: 4, mt: 5}}>
          <img src={logo} alt="Rebentify Scheduling" width="50%" />
        </Flex>

        {requests.login.error && <Alert severity="error" sx={{mb: 2}}>Email or Password Incorrect</Alert>}

        <Box className="fade-in">
          <RbTextField
            type="email"
            label="Email"
            {...bind.input(email, setEmail)}
            icon="account_circle"
            required
          />
        </Box>

        <Box className="fade-in">
          <RbTextField
            type="password"
            label="Password"
            {...bind.input(password, setPassword)}
            icon="lock"
            required
            sx={{mt: 2}}
          />
        </Box>

        <Box className="fade-in">
          <RbButton type="submit" isLoading={requests.login.isLoading || requests.googleSignIn.isLoading} sx={{my: 3, width: '200px'}}>
            Log In
          </RbButton>
        </Box>

        {requests.googleSignIn.error && <Alert severity="error" sx={{mb: 2}}>Google Sign In Failed</Alert>}

        <div className="fade-in">
          <div ref={googleSignInRef} />
        </div>

      </form>

      <Flex alignCenter gap={0.5} sx={{position: 'absolute', bottom: 10, right: 10, color: '#FAF9F6'}}>
        <RbText>Copyright</RbText>
        <Copyright />
        <RbText>Rebentify Technologies LLC {moment().year()} | <a href={PRIVACY_POLICY_URL} target="_blank" rel="noreferrer" style={{color: '#FAF9F6'}}>Privacy Policy</a></RbText>
      </Flex>
    </Box>
  )
}

export default Login