import {Icon, Tooltip} from '@mui/material'
import React from 'react'

const RbInfoTooltip = ({title, icon = 'info', sx}) => {
  return (
    <Tooltip sx={{ml: 2, ...sx}} title={title}>
      <Icon sx={{cursor: 'help'}}>{icon}</Icon>
    </Tooltip>
  )
}

export default RbInfoTooltip
