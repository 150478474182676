import React from 'react'
import RbButton from '../../common/inputs/RbButton'
import {toast} from 'react-toastify'
import RbText from '../../common/components/RbText'
import {useCSVReader} from 'react-papaparse'

const AddByEmail = ({onChange}) => {
  const {CSVReader} = useCSVReader()

  const handleOnFileLoad = file => {
    const emails = file.data.map(a => a[0].replace('\r\n', '').replace('\n', '')).filter(a => (a ?? '') !== '')
    onChange(emails)
  }

  return (
    <>
      <RbText alignCenter sx={{mt: 3}}>
        <b>CSV Format:</b> One email per line
      </RbText>

      <CSVReader
        onUploadAccepted={handleOnFileLoad}
        onError={() => toast.error('Something went wrong!')}
      >
        {({getRootProps}) => (
          <RbButton
            icon="upload_file"
            type="button"
            sx={{width: '100%', mt: 1}}
            {...getRootProps()}
          >
            Upload Students via CSV
          </RbButton>
        )}
      </CSVReader>
    </>
  )
}
export default AddByEmail