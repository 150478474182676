import {Box, Checkbox, FormControlLabel} from '@mui/material'
import React from 'react'

const RbCheckbox = ({label, checked, onChange, sx, disabled, required, labelPlacement}) => {
  return (
    <Box>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={onChange} disabled={disabled} required={required} />}
        label={label}
        labelPlacement={labelPlacement}
        sx={sx}
      />
    </Box>
  )
}

export default RbCheckbox