import React, {useEffect, useState} from 'react'
import {Chart} from 'react-google-charts'
import apiRequests from '../../../api/apiRequests'
import RbLoading from '../../common/components/RbLoading'
import RbSelect from '../../common/inputs/RbSelect'
import {bind} from '../../../utils/helpers'
import useApiRequest from '../../../hooks/useApiRequest'

const AttendanceStatusGraph = ({weekId}) => {
  const [attendanceData, setAttendanceData] = useState(null)
  const [attendanceDataKey, setAttendanceDataKey] = useState(0)

  const request = useApiRequest({
    request: apiRequests.analytics.getAttendanceStatusPerDay,
    errorText: 'There was an error getting attendance data.',
    onResponse: data => setAttendanceData(data.graphData),
  })

  useEffect(() => {
    if (!weekId) {
      return
    }

    request.send(weekId)
  }, [weekId])

  const options = {
    title: 'Attendance Status Per Day',
    colors: ['#229954', '#F1C40F', '#E74C3C', '#3c82e7'],
  }

  return (
    <>
      {!attendanceData && <RbLoading />}

      {attendanceData &&
        <>
          <RbSelect
            options={attendanceData.map((item, index) => ({title: item.title, value: index}))}
            labelKey="title"
            valueKey="value"
            {...bind.select(attendanceDataKey, val => setAttendanceDataKey(parseInt(val)))}
          />

          <Chart
            chartType="PieChart"
            data={(attendanceData[attendanceDataKey]?.array ?? [])}
            options={options}
            width="100%"
            height="300px"
          />
        </>
      }
    </>
  )
}

export default AttendanceStatusGraph