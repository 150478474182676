import React, {useEffect} from 'react'
import {Chart} from 'react-google-charts'
import RbLoading from '../../common/components/RbLoading'
import {Box} from '@mui/material'
import useApiRequest from '../../../hooks/useApiRequest'

const BarGraph = ({weekId, request, title}) => {
  const requestHook = useApiRequest({
    request: request,
    errorText: 'There was an error getting the data',
  })

  const data = requestHook.data?.array

  useEffect(() => {
    if (!weekId) {
      return
    }

    requestHook.send(weekId)
  }, [weekId])

  const options = {
    title: title,
    colors: ['#229954', '#F1C40F', '#E74C3C', '#0000FF'],
    curveType: 'function',
    vAxis: {minValue: 0},
  }

  return (
    <>
      {!data && <RbLoading sx={{m: 5}} />}

      {data &&
        <Box>
          <Chart
            chartType="Bar"
            data={data}
            options={options}
            width="100%"
            height="100%"
          />
        </Box>
      }
    </>
  )
}

export default BarGraph