import React, {useEffect, useState} from 'react'
import useUsers from '../../../../../../../hooks/useUsers'
import useGroups from '../../../../../../../hooks/useGroups'
import {EXCLUSION_TYPES} from './constants'
import useApiRequest from '../../../../../../../hooks/useApiRequest'
import apiRequests from '../../../../../../../api/apiRequests'
import {Stack} from '@mui/material'
import RbSelect from '../../../../../../common/inputs/RbSelect'
import {bind} from '../../../../../../../utils/helpers'
import UserSelect from '../../../../../../common/inputs/UserSelect'
import RbTextField from '../../../../../../common/inputs/RbTextField'
import GroupSelect from '../../../../../../common/inputs/GroupSelect'
import SaveButtons from '../../../../../../common/inputs/SaveButtons'
import {toast} from 'react-toastify'

const NewExclusionForm = ({onClose, exclusions}) => {
  const [selectedOption, setSelectedOption] = useState()
  const [selectedStudents, setSelectedStudents] = useState()
  const [selectedGroups, setSelectedGroups] = useState()
  const [selectedGrade, setSelectedGrade] = useState()
  const [newExclusionReason, setNewExclusionReason] = useState()

  const {students} = useUsers({includeStudents: true})
  const {groups} = useGroups(false)

  const newExclusionValue = selectedOption === EXCLUSION_TYPES.STUDENT ? selectedStudents :
    selectedOption === EXCLUSION_TYPES.GROUP ? selectedGroups :
      selectedOption === EXCLUSION_TYPES.GRADE ? selectedGrade : null

  const isReadyToAdd = Boolean(newExclusionValue)

  const requests = {
    createExclusions: useApiRequest({
      request: apiRequests.autoscheduleExclusions.create,
      errorText: 'There was an issue creating the autoschedule exclusion. Please try again later',
    })
  }

  const existingStudents = exclusions.filter(exclusion => exclusion.studentId).map(exclusion => exclusion.student)
  const existingGroups = exclusions.filter(exclusion => exclusion.groupId).map(exclusion => exclusion.group)

  const handleSaveNewExclusion = () => {
    let newExclusions = []

    if (selectedOption === EXCLUSION_TYPES.STUDENT) {
      newExclusions = selectedStudents.map(student => ({
        reason: newExclusionReason,
        studentId: student.id,
      }))
    } else if (selectedOption === EXCLUSION_TYPES.GROUP) {
      newExclusions = selectedGroups.map(group => ({
        reason: newExclusionReason,
        groupId: group.id,
      }))
    } else if (selectedOption === EXCLUSION_TYPES.GRADE) {
      newExclusions = [{
        reason: newExclusionReason,
        grade: selectedGrade,
      }]
    }

    requests.createExclusions.send(newExclusions)
      .then(() => {
        toast.success(`Successfully created ${newExclusions.length} exclusion` + (newExclusions.length > 1 ? 's' : ''))
        onClose()
      })
  }

  // Clear form when switching exclusion type
  useEffect(() => {
    setSelectedStudents()
    setSelectedGroups()
    setSelectedGrade()
  }, [selectedOption])

  return (
    <Stack gap={2}>
      <RbSelect
        label="Exclusion Type"
        options={[EXCLUSION_TYPES.STUDENT, EXCLUSION_TYPES.GROUP, EXCLUSION_TYPES.GRADE]}
        {...bind.select(selectedOption, setSelectedOption)}
      />

      {selectedOption &&
        <>
          {selectedOption === EXCLUSION_TYPES.STUDENT &&
            <UserSelect
              multiple
              label="Select Students"
              users={students}
              ignoreUsers={existingStudents}
              {...bind.recordSelect(selectedStudents, setSelectedStudents)}
            />
          }

          {selectedOption === EXCLUSION_TYPES.GROUP &&
            <GroupSelect
              multiple
              label="Select Groups"
              groups={groups}
              ignoreGroups={existingGroups}
              {...bind.recordSelect(selectedGroups, setSelectedGroups)}
            />
          }

          {selectedOption === EXCLUSION_TYPES.GRADE &&
            <RbTextField
              label="Grade"
              type="number"
              {...bind.input(selectedGrade, setSelectedGrade)}
            />
          }

          {isReadyToAdd &&
            <RbTextField
              label="Reason"
              {...bind.input(newExclusionReason, setNewExclusionReason)}
            />
          }
        </>
      }

      <SaveButtons
        sx={{mt: 2}}
        onCancel={onClose}
        onSave={handleSaveNewExclusion}
        saveIsDisabled={!isReadyToAdd}
        isLoading={requests.createExclusions.isLoading}
      />
    </Stack>
  )
}

export default NewExclusionForm