import React, {useState} from 'react'
import RbModal from '../../../common/components/RbModal'
import RbButton from '../../../common/inputs/RbButton'
import useApiRequest from '../../../../hooks/useApiRequest'
import apiRequests from '../../../../api/apiRequests'
import RbLoading from '../../../common/components/RbLoading'
import RbText from '../../../common/components/RbText'
import RecordTable from '../../../common/components/RecordTable'
import Flex from '../../../common/components/Flex'
import RbMenu from '../../../common/components/RbMenu'
import {IconButton, Stack} from '@mui/material'
import RbTextField from '../../../common/inputs/RbTextField'
import {bind} from '../../../../utils/helpers'
import RbSwitch from '../../../common/inputs/RbSwitch'
import {useSchedule} from '../../ScheduleContext'
import {Delete, Edit} from '@mui/icons-material'
import {useConfirm} from '../../../../context/ConfirmationContext'
import SaveButtons from '../../../common/inputs/SaveButtons'

const TemplateManager = () => {
  const [templates, setTemplates] = useState()
  const [editTemplateIsVisible, setEditTemplateIsVisible] = useState(false)
  const [focusedTemplate, setFocusedTemplate] = useState()
  const [templateManagerIsVisible, setTemplateManagerIsVisible] = useState(false)

  const {copyLocation} = useSchedule()
  const confirm = useConfirm()

  const templateApiRequests = apiRequests.periodTemplates
  const requests = {
    getMine: useApiRequest({
      onBeforeRequest: () => setTemplates(),
      request: templateApiRequests.getMine,
      errorText: 'Failed to get period templates. Please try again.',
      onResponse: setTemplates
    }),
    create: useApiRequest({
      request: templateApiRequests.create,
      errorText: 'Failed to create period template. Please try again.',
      successText: 'Template successfully created',
    }),
    update: useApiRequest({
      request: templateApiRequests.update,
      errorText: 'Failed to update period template. Please try again.',
      successText: 'Template successfully updated',
    }),
    delete: useApiRequest({
      request: templateApiRequests.delete,
      errorText: 'Failed to delete period template. Please try again.',
      successText: 'Template successfully deleted',
    })
  }
  const isUpdatingOrCreating = requests.create.isLoading || requests.update.isLoading

  const renderEditCell = template => {
    return (
      <IconButton
        onClick={() => {
          setFocusedTemplate(template)
          setEditTemplateIsVisible(true)
        }}
      >
        <Edit />
      </IconButton>
    )
  }

  const renderDeleteCell = template => {
    return (
      <IconButton
        onClick={() => {
          confirm({'title': 'Are you sure you want to delete this template?', 'description': 'This action cannot be undone', 'confirmationText': 'Yes, Delete It', 'cancellationText': 'Nevermind'})
            .then(() => {
              requests.delete.send(template.id)
                .then(() => setTemplates(prev => prev.filter(t => t.id !== template.id)))
            })
        }}
      >
        <Delete />
      </IconButton>
    )
  }

  const getTemplateBind = key => bind.object.input(focusedTemplate, key, setFocusedTemplate)

  const onSave = e => {
    e.preventDefault()

    if (focusedTemplate.id) {
      requests.update.send(focusedTemplate)
        .then(updatedRecord => {
          setTemplates(prev => prev.map(t => t.id === focusedTemplate.id ? updatedRecord : t))
          setFocusedTemplate(null)
          setEditTemplateIsVisible(false)
        })
    } else {
      requests.create.send(focusedTemplate)
        .then(newRecord => {
          setTemplates(prev => [...prev, newRecord])
          setFocusedTemplate(null)
          setEditTemplateIsVisible(false)
        })
    }
  }

  const openNewTemplateForm = () => {
    setFocusedTemplate({})
    setEditTemplateIsVisible(true)
  }

  const modalTitle = editTemplateIsVisible ?
    (focusedTemplate?.id ? 'Edit Template' : 'New Template')
    : 'Manage Templates'

  let templateMenuOptions

  if (!templates) {
    templateMenuOptions = [{label: <RbLoading small />, disabled: true, sx: {justifyContent: 'center'}}]
  } else if (templates.length > 0) {
    templateMenuOptions = [{label: 'My Templates', disabled: true}]
    const templateOptions = templates?.map(template => ({label: template.summary, icon: 'content_copy', onClick: () => copyLocation(template)}))
    templateMenuOptions = [...templateMenuOptions, ...templateOptions]
  } else {
    templateMenuOptions = [{label: 'No Templates Yet', disabled: true, sx: {justifyContent: 'center'}}]
  }

  return (
    <>
      <RbMenu
        label="My Period Templates"
        labelIcon="keyboard_arrow_down"
        iconEnd
        onOpen={() => requests.getMine.send()}
        options={[
          {
            label: 'Manage Templates',
            icon: 'settings',
            onClick: () => {
              setTemplateManagerIsVisible(true)
              setEditTemplateIsVisible(false)
            }
          },
          {isDivider: true, id: 1},
          {
            label: 'New Template',
            icon: 'add',
            onClick: () => {
              setTemplateManagerIsVisible(true)
              openNewTemplateForm()
            }
          },
          {isDivider: true, id: 2},
          ...templateMenuOptions,
        ]}
      />

      {templateManagerIsVisible &&
        <RbModal open title={modalTitle} onClose={() => setTemplateManagerIsVisible(false)} minWidth={500}>
          {!templates && <RbLoading />}

          {templates &&
            <>
              {!editTemplateIsVisible &&
                <>
                  <RbButton
                    onClick={openNewTemplateForm}
                    icon="add"
                    sx={{mb: 1}}
                  >
                    New Template
                  </RbButton>

                  {templates.length === 0 && <RbText h5 alignCenter sx={{mt: 4, mb: 2}}>No Templates Yet</RbText>}

                  {templates.length > 0 &&
                    <RecordTable
                      isPaginated={false}
                      records={templates}
                      columnConfigs={[
                        {title: 'Summary', dataKey: 'summary', dataType: 'string'},
                        {title: 'Description', dataKey: 'description', dataType: 'string'},
                        {title: 'Student Limit', dataKey: 'studentLimit', dataType: 'number'},
                        {title: 'Room', dataKey: 'roomNumber', dataType: 'string'},
                        {title: 'Approved List', dataKey: 'approveList', dataType: 'boolean', textAlign: 'center'},
                        {title: 'Blocker List', dataKey: 'blockList', dataType: 'boolean', textAlign: 'center'},
                        {title: 'Edit', renderCell: renderEditCell, textAlign: 'center'},
                        {title: 'Delete', renderCell: renderDeleteCell, textAlign: 'center'}
                      ]}
                    />
                  }
                </>
              }

              {editTemplateIsVisible &&
                <form onSubmit={onSave}>
                  <Stack spacing={2}>
                    <RbTextField
                      label="Summary"
                      required
                      autoFocus
                      {...getTemplateBind('summary')}
                    />

                    <RbTextField
                      label="Description"
                      {...getTemplateBind('description')}
                    />

                    <Flex justifyBetween alignCenter>
                      <RbTextField
                        label="Student Limit"
                        {...getTemplateBind('studentLimit')}
                      />

                      <RbTextField
                        label="Room"
                        {...getTemplateBind('roomNumber')}
                      />
                    </Flex>

                    <Flex justifyBetween alignCenter>
                      <RbSwitch
                        label="Approved List"
                        {...bind.object.switch(focusedTemplate, 'approveList', setFocusedTemplate)}
                      />

                      <RbSwitch
                        label="Blocker List"
                        {...bind.object.switch(focusedTemplate, 'blockList', setFocusedTemplate)}
                      />
                    </Flex>

                    <SaveButtons isLoading={isUpdatingOrCreating} onCancel={() => setEditTemplateIsVisible(false)} />
                  </Stack>
                </form>
              }
            </>
          }
        </RbModal>
      }
    </>
  )
}

export default TemplateManager