import React from 'react'
import RbSelect from '../../common/inputs/RbSelect'
import {STATUS_SELECT_OPTIONS} from '../constants'

const StatusSelect = ({value, onChange}) => {
  return (
    <RbSelect
      valueKey="value"
      labelKey="label"
      value={value}
      options={STATUS_SELECT_OPTIONS}
      onChange={val => onChange(val)}
    />
  )
}

export default StatusSelect