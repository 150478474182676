import * as React from 'react'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import {v4 as uuid} from 'uuid'
import {FormControl, Icon, ListSubheader} from '@mui/material'
import Flex from '../components/Flex'
import {useState} from 'react'
import RbTextField from './RbTextField'
import {bind} from '../../../utils/helpers'
import {useEffect} from 'react'
import RbButton from './RbButton'

const ITEM_HEIGHT = 30
const ITEMS_IN_VIEW = 6.5
const SEARCH_BOX_HEIGHT = 55
const CLOSE_BUTTON_HEIGHT = 45

const RbSelect = ({label, options, valueKey, labelKey, onChange, value, sx, renderOption, multiple, icon, required, variant, size, placeholder = 'Select...', isSearchable}) => {
  const id = uuid()
  const labelId = `${id}-label`
  const optionsAreObjects = options?.length > 0 && typeof options[0] === 'object'

  const [scrollPos, setScrollPos] = useState(0)
  const [filteredOptions, setFilteredOptions] = useState(options)
  const [currentSearch, setCurrentSearch] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const handleChange = e => {
    setCurrentSearch('')
    onChange(e.target.value)
  }

  const handleItemDelete = valueToRemove => {
    if (multiple) {
      onChange(value.filter(val => val !== valueToRemove))
    } else {
      onChange(null)
    }
  }

  const getObjectLabel = val => {
    const option = options.find(op => op[valueKey] === val)

    return option?.[labelKey]
  }

  const renderSelected = selected => {
    const selectedItems = multiple ? selected : [selected]

    if (selectedItems?.length === 0) {
      return
    }

    return (
      <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
        {selectedItems.map(val => {
          const selectedLabel = optionsAreObjects ? getObjectLabel(val) : val

          return <Chip
            key={val}
            label={selectedLabel}
            onDelete={() => handleItemDelete(val)}
            onMouseDown={e => {
              e.stopPropagation() // Prevents the select from preventing us from deleting the chip
            }}
          />
        })}
      </Box>
    )
  }

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
    setScrollPos(0)
    setCurrentSearch('')
  }

  const displaysPlaceholder = Boolean(!label && placeholder)
  const virtualizedItemThresholdQty = Math.max(options?.length / 100, 10) // When virtualized, we want to have at least 10 items above and below the viewport. Otherwise, if there are a ton of records, scale the padding based on the number of records

  useEffect(() => {
    setFilteredOptions(options)
  }, [options])

  useEffect(() => {
    if (!isSearchable) {
      return
    }

    const search = currentSearch.toLowerCase()

    const filtered = options?.filter(option => {
      const optionLabel = optionsAreObjects ? option[labelKey] : option
      return optionLabel.toLowerCase().includes(search)
    })

    setFilteredOptions(filtered)
  }, [currentSearch, options, isSearchable])

  const defaultValue = multiple ? [] : ''
  let currentValue = value ?? defaultValue

  // Handle a brief period where the options are not loaded yet, and causes an error
  if (filteredOptions?.length === 0) {
    currentValue = defaultValue
  }

  const parsedLabel = required ? `${label} *` : label

  return (
    <Flex sx={{flexGrow: 1, ...sx}} alignItems="flex-end">
      {icon && <Icon sx={{mr: 1}}>{icon}</Icon>}

      <FormControl variant={variant ?? 'standard'} sx={{flexGrow: 1}} size={size ?? 'medium'}>
        {label &&
          <InputLabel id={labelId}>
            {parsedLabel}
          </InputLabel>
        }
        <Select
          multiple={multiple}
          labelId={labelId}
          label={parsedLabel} // For rendering the background for the outlined variant so that the border doesn't overlap the label
          id={id}
          defaultValue={defaultValue}
          value={currentValue}
          onChange={handleChange}
          renderValue={multiple ? renderSelected : null}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: (ITEM_HEIGHT * ITEMS_IN_VIEW) + (multiple ? CLOSE_BUTTON_HEIGHT : 0) + (isSearchable ? SEARCH_BOX_HEIGHT : 0),
                width: 250,
                '& .MuiList-root': {
                  padding: 0,
                },
              },
              onScroll: e => setScrollPos(e.target.scrollTop),
            },
            autoFocus: false,
          }}
          required={required}
          displayEmpty={displaysPlaceholder}
          onOpen={handleOpen}
          onClose={handleClose}
          open={isOpen}
        >
          {isSearchable &&
            <ListSubheader sx={{py: 1, height: SEARCH_BOX_HEIGHT}}>
              <RbTextField
                size="small"
                autoFocus
                placeholder="Search"
                icon="search"
                variant="outlined"
                onKeyDown={e => {
                  if (e.key !== 'Escape') {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation()
                  }
                }}
                isDebounced
                {...bind.input(currentSearch, setCurrentSearch)}
              />
            </ListSubheader>
          }

          {displaysPlaceholder &&
            <MenuItem disabled value="" sx={{height: ITEM_HEIGHT}}>
              <em>{placeholder}</em>
            </MenuItem>
          }

          {filteredOptions?.map((option, index) => {
            let optionValue = option
            let optionLabel = option

            if (typeof option === 'object') {
              optionValue = option[valueKey]
              optionLabel = option[labelKey]
            }

            // Virtualize the list if it's more than 50 records
            // Do not virtualize this item if it's the currently selected item, since we need it to render for the selected value to display correctly
            if (optionValue !== currentValue && filteredOptions.length > 50) {
              const itemPos = index * ITEM_HEIGHT

              const topThresholdSize = ITEM_HEIGHT * virtualizedItemThresholdQty // The size of the threshold before the viewport
              const bottomThresholdSize = ITEM_HEIGHT * (ITEMS_IN_VIEW + virtualizedItemThresholdQty) // The size of the threshold after the viewport. This has to include the items within the viewport, since the scroll position is the top of the viewport

              const isBeforeThreshold = itemPos < (scrollPos - topThresholdSize)
              const isAfterThreshold = itemPos > (scrollPos + bottomThresholdSize)

              // If the item is before or after the thresholds we set above, just render an empty box
              if (isBeforeThreshold || isAfterThreshold) {
                return <Box key={optionValue} sx={{height: ITEM_HEIGHT}} />
              }
            }

            return (
              <MenuItem
                key={optionValue}
                value={optionValue}
                sx={{height: ITEM_HEIGHT}}
              >
                {renderOption ? renderOption(option) : optionLabel}
              </MenuItem>
            )
          })}

          {multiple &&
            <ListSubheader sx={{bottom: 0, p: 0, height: CLOSE_BUTTON_HEIGHT}}>
              <RbButton onClick={handleClose} sx={{width: '100%', height: '100%', color: 'black'}} color="cancel" small>Close</RbButton>
            </ListSubheader>
          }
        </Select>
      </FormControl>
    </Flex>
  )
}

export default RbSelect