import React, {useState} from 'react'
import {AppBar, Avatar, IconButton, Toolbar} from '@mui/material'
import {useCurrentUser} from '../../context/CurrentUserContext'
import EditHomeroom from './user-dropdown/EditHomeroom'
import {Menu as MenuIcon} from '@mui/icons-material'
import RbText from '../common/components/RbText'
import UserPreferences from './user-dropdown/UserPreferences'
import RbMenu from '../common/components/RbMenu'
import PushNotifications from '../push-notifications/PushNotifications'
import {PRIVACY_POLICY_URL} from '../../utils/constants'

const Navbar = ({onSidebarToggle, hasSidebar, height}) => {
  const [editHomeRoomIsVisible, setEditHomeRoomIsVisible] = useState(false)
  const [userPreferencesIsVisible, setUserPreferencesIsVisible] = useState(false)

  const {currentUserIsTeacherOrAbove, currentUserInitials, logOut} = useCurrentUser()

  const options = []

  // Teachers and above only
  if (currentUserIsTeacherOrAbove) {
    options.push({label: 'Edit Homeroom', icon: 'groups_3', onClick: () => setEditHomeRoomIsVisible(true)})
    options.push({label: 'Preferences', icon: 'settings', onClick: () => setUserPreferencesIsVisible(true)})
  }

  // Everyone
  options.push({render: () => <PushNotifications key="push" />})
  options.push({isDivider: true})
  options.push({label: 'Privacy Policy', icon: 'admin_panel_settings', link: () => PRIVACY_POLICY_URL})
  options.push({label: 'Log Out', icon: 'logout', onClick: logOut})

  return (
    <>
      <AppBar position="sticky" sx={{height: height}}>
        <Toolbar sx={{alignItems: 'center'}}>
          {hasSidebar &&
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{mr: 2}}
              onClick={onSidebarToggle}
            >
              <MenuIcon />
            </IconButton>
          }

          <RbText h4 sx={{flexGrow: 1}} alignCenter color="white">Rebentify Scheduling</RbText>

          <RbMenu
            options={options}
            renderButton={handleClick => (
              <Avatar sx={{bgcolor: '#81b0cc', cursor: 'pointer'}} onClick={handleClick}>{currentUserInitials}</Avatar>
            )}
          />
        </Toolbar>
      </AppBar>

      {editHomeRoomIsVisible && <EditHomeroom onClose={() => setEditHomeRoomIsVisible(false)} />}
      {userPreferencesIsVisible && <UserPreferences onClose={() => setUserPreferencesIsVisible(false)} />}
    </>
  )
}

export default Navbar