import React from 'react'
import {useSisIntegration} from '../../SisIntegrationContext'
import {useConfirm} from '../../../../../../context/ConfirmationContext'
import Flex from '../../../../../common/components/Flex'
import {Box} from '@mui/material'
import RbButton from '../../../../../common/inputs/RbButton'
import RbCheckbox from '../../../../../common/inputs/RbCheckbox'
import {bind} from '../../../../../../utils/helpers'

// Buttons for navigating between steps. These buttons are automatically based on the context values
const SisIntegrationStepButtons = () => {
  const {currentStep, currentStepIsReady, goToNextStep, goToPreviousStep, importIsLoading, simulatedData, isSkippingSimulation, setIsSkippingSimulation, importedData} = useSisIntegration()
  const confirm = useConfirm()

  const handleSimulationSkipChange = async isChecked => {
    if (isChecked) {
      await confirm({title: 'Are you sure?', description: 'Skipping the simulation can cause irreversible damage if the data is incorrect. Are you sure you want to skip the simulation?'})
        .catch(() => isChecked = false)
    }

    setIsSkippingSimulation(isChecked)
  }

  return (
    <Flex justifyBetween alignItems="flex-end" sx={{marginTop: 15}}>
      <Box>
        {/* Hide the back button on the first step or if we have completed the import */}
        {currentStep > 0 && !importedData &&
          <RbButton disabled={importIsLoading} onClick={goToPreviousStep} color="cancel">Back</RbButton>
        }
      </Box>

      <Box textAlign="right">
        {currentStep === 2 && !simulatedData && !importIsLoading &&
          <RbCheckbox
            label="I Acknowledge that not simulating can cause irreversible damage"
            labelPlacement="start"
            sx={{width: 350, mr: 1, mb: 2}}
            {...bind.checkbox(isSkippingSimulation, handleSimulationSkipChange)}
          />
        }

        {currentStep < 3 &&
          <RbButton sx={{ml: 2}} disabled={importIsLoading || !currentStepIsReady} onClick={goToNextStep}>Next</RbButton>
        }
      </Box>
    </Flex>
  )
}

export default SisIntegrationStepButtons