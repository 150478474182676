import React, {useState, useEffect} from 'react'
import apiRequests from '../../api/apiRequests'
import {useCurrentUser} from '../../context/CurrentUserContext'
import RbTabs from '../common/components/RbTabs'
import RbButton from '../common/inputs/RbButton'
import AddRecords from './partials/AddRecords'
import {getStatusTitle} from './constants'
import TabContainer from './partials/TabContainer'
import {useParams} from 'react-router-dom'
import useApiRequest from '../../hooks/useApiRequest'
import PageTitle from '../common/components/PageTitle'

const BlockedApproved = () => {
  document.title = 'Blocked / Approved'

  const {currentUserId} = useCurrentUser()
  const {id: paramUserId} = useParams()
  const userIdBeingViewed = paramUserId ?? currentUserId
  const [studentRecords, setStudentRecords] = useState(null)
  const [groupRecords, setGroupRecords] = useState(null)
  const [isAdding, setIsAdding] = useState(false)
  const [addingType, setAddingType] = useState()

  const request = useApiRequest({
    request: apiRequests.partitionList.getForUser,
    errorText: 'There was an error fetching the blocked/approved list',
  })

  const deleteStudent = record => setStudentRecords(prev => prev.filter(r => r.id !== record.id))
  const deleteGroup = record => setGroupRecords(prev => prev.filter(r => r.id !== record.id))

  const renderAddButton = tab => {
    const type = tab === 0 ? 'Students' : 'Groups'
    return (
      <RbButton
        icon={tab === 0 ? 'person_add' : 'group_add'}
        onClick={() => {
          setIsAdding(true)
          setAddingType(type)
        }}
      >
        Add {type}
      </RbButton>
    )
  }

  const refresh = () => {
    request.send(userIdBeingViewed)
      .then(data => {
        const currentStudentRecords = []
        const currentGroupRecords = []

        data.forEach(record => {
          record.statusTitle = getStatusTitle(record.type)

          if (record.student) {
            currentStudentRecords.push({
              ...record,
              studentFirstName: record.student.firstName,
              studentLastName: record.student.lastName,
              studentGrade: record.student.grade,
            })
          } else if (record.group) {
            currentGroupRecords.push({
              ...record,
              groupName: record.group.name,
            })
          }
        })

        setStudentRecords(currentStudentRecords)
        setGroupRecords(currentGroupRecords)
      })
  }

  useEffect(refresh, [userIdBeingViewed])

  return (
    <>
      <PageTitle>Blocked / Approved</PageTitle>

      <RbTabs
        tabs={[
          {label: 'Students', render: () => <TabContainer records={studentRecords} isStudents onDelete={deleteStudent} />},
          {label: 'Groups', render: () => <TabContainer records={groupRecords} isGroups onDelete={deleteGroup} />},
        ]}
        renderHeaderContents={renderAddButton}
      />

      {isAdding &&
        <AddRecords
          type={addingType}
          onNewRecords={refresh}
          onClose={() => setIsAdding(false)}
          studentsToIgnore={studentRecords.map(r => ({id: r.student.id}))}
          groupsToIgnore={groupRecords.map(r => ({id: r.group.id}))}
          userIdBeingViewed={userIdBeingViewed}
        />
      }
    </>
  )
}

export default BlockedApproved