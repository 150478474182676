import UserForm from './UserForm'
import React, {useState} from 'react'
import {toast} from 'react-toastify'
import apiRequests from '../../../api/apiRequests'
import {Grid, IconButton} from '@mui/material'
import RecordTable from '../../common/components/RecordTable'
import RbLoading from '../../common/components/RbLoading'
import {RestoreFromTrash} from '@mui/icons-material'
import {useConfirm} from '../../../context/ConfirmationContext'
import RbText from '../../common/components/RbText'
import useApiRequest from '../../../hooks/useApiRequest'

const CreateUser = () => {
  const [pendingNewUser, setPendingNewUser] = useState()

  const confirm = useConfirm()

  const requests = {
    getAllDeleted: useApiRequest({
      request: apiRequests.users.getAllDeleted,
      errorText: 'Failed to get deleted users. Please try again later.',
      sendImmediately: true
    }),
    createUser: useApiRequest({
      request: apiRequests.users.createUser,
      throwOnError: true
    }),
    unDeleteUser: useApiRequest({
      request: apiRequests.users.undelete,
      errorText: 'Failed to reactivate user',
    })
  }

  const deletedUsers = requests.getAllDeleted.data

  const REACTIVATE_EXISTING_USER_TITLE = 'An account with this email already exists, but is deactivated. Do you want to reactivate it?'
  const REACTIVATION_DESCRIPTIONS = {
    STUDENTS: (<RbText><b>*Note:</b> Students will not be signed up anywhere for the current week (unless you run the default to homeroom feature manually)</RbText>),
    RETAIN: (<RbText><b>*Note:</b> The reactivated user will retain their original information.</RbText>)
  }

  const handleCreateUserError = e => {
    const CODE_EXISTING_DEACTIVATED_ACCOUNT = 'EXISTING_DEACTIVATED_ACCOUNT'
    const ERROR_CODES = {
      MISSING_DATA: 'A required value is missing',
      EXISTING_EMAIL: 'An account with this email already exists',
      [CODE_EXISTING_DEACTIVATED_ACCOUNT]: 'The account with this email is deactivated. Please reactivate it in the Deactivated Users table (on the right side of your screen).',
    }

    const response = e.response.data
    const errorCode = response?.error
    const errorMessage = ERROR_CODES[errorCode]

    if (errorMessage) {
      // Allow quick reactivation of deactivated user. We should really never run in to this. Our check on the user form email should cover 99% of cases. The only time this would happen is if the email they are trying to add was literally just deactivated as they were on this screen, and that user wasn't in the deleted users list yet.
      if (errorCode === CODE_EXISTING_DEACTIVATED_ACCOUNT) {
        unDeleteUser(response.account, REACTIVATE_EXISTING_USER_TITLE, REACTIVATION_DESCRIPTIONS.RETAIN)
        return
      }

      toast.error(errorMessage, {autoClose: false})
      return
    }

    toast.error('Failed to add user. Please try again later.')
  }

  const createUser = (user, onSuccess) => {
    requests.createUser.send(user)
      .then(() => {
        onSuccess()
        toast.success('Added User Successfully')
      })
      .catch(handleCreateUserError)
  }

  const renderReactivateButton = record => {
    return (
      <IconButton onClick={() => unDeleteUser(record, 'Are you sure you want to reactivate ' + (record.firstName ?? '') + ' ' + (record.lastName ?? '') + '?', REACTIVATION_DESCRIPTIONS.STUDENTS)}>
        <RestoreFromTrash />
      </IconButton>
    )
  }

  const unDeleteUser = (record, title, description) => {
    confirm({
      title: title,
      description: description
    })
      .then(() => doUnDelete(record.id))
  }

  const doUnDelete = id => {
    requests.unDeleteUser.send(id)
      .then(() => {
        requests.getAllDeleted.setData(prev => prev.filter(u => u.id !== id))
        toast.success('Successfully reactivated user')
      })
  }

  const handleUserChange = user => {
    const existingDeletedUser = deletedUsers?.find(u => u.email === user.email)
    if (existingDeletedUser) {
      unDeleteUser(existingDeletedUser, REACTIVATE_EXISTING_USER_TITLE, REACTIVATION_DESCRIPTIONS.RETAIN)

      setPendingNewUser({...user, email: ''}) // Clear email in form
    }
  }

  return (
    <Grid container spacing={2}>
      <UserForm
        submitButtonLabel="Add User"
        onSubmit={createUser}
        title="New User"
        showPassword
        isLoading={requests.createUser.isLoading}
        user={pendingNewUser}
        onChange={handleUserChange}
      />

      <Grid item xs={12} md={6}>
        {!deletedUsers && <RbLoading />}

        {deletedUsers &&
          <>
            {deletedUsers.length === 0 &&
              <RbText h5 alignCenter sx={{mb: 2}}>No Deactivated Users</RbText>
            }

            {deletedUsers.length > 0 &&
              <>
                <RbText h4 sx={{mb: 2}}>Deactivated Users</RbText>

                <RecordTable
                  records={deletedUsers}
                  columnConfigs={[
                    {title: 'First', dataKey: 'firstName', dataType: 'string'},
                    {title: 'Last', dataKey: 'lastName', dataType: 'string'},
                    {title: 'Email', dataKey: 'email', dataType: 'string'},
                    {title: 'Grade', dataKey: 'grade', dataType: 'number'},
                    {title: 'Reactivate', renderCell: renderReactivateButton, textAlign: 'center'}
                  ]}
                />
              </>
            }
          </>
        }
      </Grid>
    </Grid>
  )
}

export default CreateUser