import React, {useState} from 'react'
import './schedule.css'
import {useCurrentUser, parseUserLevel} from '../../context/CurrentUserContext'
import {Box} from '@mui/material'
import Flex from '../common/components/Flex'
import {bind} from '../../utils/helpers'
import RbButton from '../common/inputs/RbButton'
import {useSchedule} from './ScheduleContext'
import Week from './Week'
import RbDatePicker from '../common/inputs/RbDatePicker'
import RbText from '../common/components/RbText'
import RbLoading from '../common/components/RbLoading'
import UserSelect from '../common/inputs/UserSelect'
import useUsers from '../../hooks/useUsers'
import MasterSchedule from './teacher/MasterSchedule'
import PageTitle from '../common/components/PageTitle'
import {Home} from '@mui/icons-material'
import WorkaroundTooltip from '../_workarounds/WorkaroundTooltip'
import TemplateManager from './teacher/templates/TemplateManager'

const Schedule = () => {
  document.title = 'Rebentify - Schedule'

  const {currentUser} = useCurrentUser()
  const {viewingUser, selectedDate, setSelectedDate, isMe, weekStartDates, days, viewScheduleForUser} = useSchedule()
  const {teachers} = useUsers({includeTeachers: true})

  const [colorLegendIsVisible, setColorLegendIsVisible] = useState(false)
  const [masterScheduleIsVisible, setMasterScheduleIsVisible] = useState(false)

  const viewingUserLevelInfo = parseUserLevel(viewingUser)
  const isTeacher = viewingUserLevelInfo.isTeacherOrAbove
  const isStudent = viewingUserLevelInfo.isStudent

  const colors = [{hex: '#b3b3b3', label: 'Not Signed Up'}, {hex: '#75dc37', label: 'Signed Up'}, {hex: '#D7063A', label: 'Teacher Requested'}, {hex: '#2358B0', label: 'Group Requested'}, {hex: '#D5972F', label: 'Autoscheduled'}]

  if ((isMe && !currentUser) || (!isMe && !viewingUser) || days.length === 0) {
    return <RbLoading />
  }

  return (
    <>
      <PageTitle
        title={`${isMe ? 'Your' : viewingUser.fullName + '\'s'} Schedule `}
        extraContent={(
          <>
            {/* Only show the select if we are looking at teacher schedules. If a teacher is viewing a student's schedule, we hide the select. */}
            {isTeacher &&
              <Flex alignItems="flex-end" gap={1}>
                <UserSelect
                  users={teachers}
                  label="View Schedule for"
                  selectedRecords={{id: viewingUser?.id}} // must pass in ID, won't have HR acct object
                  onChange={user => viewScheduleForUser(user.id)}
                  sx={{width: 250}}
                />

                {viewingUser?.id !== currentUser.id &&
                  <WorkaroundTooltip title="Back to your schedule">
                    <RbButton
                      onClick={() => viewScheduleForUser()}
                      sx={{p: 1}}
                      color="cancel"
                    >
                      <Flex centered>
                        <Home fontSize="small" sx={{p: 0}} />
                      </Flex>
                    </RbButton>
                  </WorkaroundTooltip>
                }
              </Flex>
            }

            {isTeacher &&
              <RbButton onClick={() => setMasterScheduleIsVisible(true)}>View Teacher Schedules</RbButton>
            }

            <Box>
              {isStudent &&
                <>
                  <RbText h4>Homeroom: {viewingUser.homeroomTeacher ?? '(No Homeroom)'}</RbText>
                  <RbText h4>Grade: {viewingUser.grade ?? '(No Grade)'}</RbText>
                </>
              }

              {isTeacher &&
                <RbDatePicker {...bind.datePicker(selectedDate, setSelectedDate)} incrementByWeek />
              }
            </Box>

            {isStudent &&
              <Flex alignCenter justifyBetween>
                <Flex alignCenter justifyContent="flex-start" wrap>
                  {colors.map(color => (
                    <Flex alignCenter key={color.label} sx={{mx: 3}} className={`color-legend ${colorLegendIsVisible ? '' : 'hidden'}`}>
                      <Box sx={{width: 17, height: 17, backgroundColor: color.hex, mr: 1, flexShrink: 0}} />
                      <RbText noWrap>{color.label}</RbText>
                    </Flex>
                  ))}
                </Flex>

                <RbButton sx={{mr: 1}} onClick={() => setColorLegendIsVisible(prev => !prev)}>Color Legend</RbButton>
              </Flex>
            }
          </>
        )}
      />

      {isTeacher &&
        <Flex justifyContent="flex-end" sx={{mb: 2}}>
          <TemplateManager />
        </Flex>
      }

      {isTeacher && masterScheduleIsVisible && <MasterSchedule onClose={() => setMasterScheduleIsVisible(false)} />}

      {viewingUser && weekStartDates.map(date => <Week key={date} date={date} />)}
    </>
  )
}

export default Schedule