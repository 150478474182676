import React, {useState, useEffect} from 'react'
import {Checkbox, IconButton, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel} from '@mui/material'
import Flex from './Flex'
import {Check, Close, Visibility, VisibilityOff} from '@mui/icons-material'
import RbInfoTooltip from './RbInfoTooltip'
import RbText from './RbText'
import {eCoal} from '../../../utils/helpers'

// Column config options: {
//   title: string,
//   dataKey: string,
//   isSortable: bool,
//   dataType: string,
//   renderHeader: function,
//   renderCell: function,
//   colSpan: int,
//   headerTooltip: string,
//   textAlign: string,
//   width: string,
//   headerSx: object,
//   cellSx: object,
//   togglesChildRow: bool, // If true, a toggle icon will appear in this column within the row. Clicking it will show/hide the child row.
//   eCoal: bool // Empty-coalesces the cell
// }
const RecordTable = ({
  columnConfigs,
  records,
  defaultSortDirection = 'asc',
  defaultSortKey,
  isSortable,
  getRowStyle,
  renderChildRow,
  height,
  sx,
  headerTextNoWrap,
  cellTextNoWrap,
  preHeaderSlot,
  isPaginated = true,
  isSelectable,
  selectedRecords = [],
  onSelectChange,
  isRecordSelectable
}) => {
  const [currentRecords, setCurrentRecords] = useState([])
  const [sortKey, setSortKey] = useState(defaultSortKey ?? columnConfigs[0].dataKey ?? null)
  const [sortDirection, setSortDirection] = useState(defaultSortDirection)
  const [visibleChildRowRecordIds, setVisibleChildRowRecordIds] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [paginationInfo, setPaginationInfo] = useState({})
  const [lastSelectedRecordIndex, setLastSelectedRecordIndex] = useState() // Used to allow shift selecting

  const recordsPerPage = 50

  const getSelectableRecords = () => {
    return currentRecords.filter(record => {
      if (isRecordSelectable) {
        return isRecordSelectable(record)
      }

      return true
    })
  }

  const setSort = key => {
    let direction = 'asc'

    if (key === sortKey) {
      direction = sortDirection === 'asc' ? 'desc' : 'asc'
    }

    setSortKey(key)
    setSortDirection(direction)
  }

  const toggleChildRow = recordId => {
    setVisibleChildRowRecordIds(prev => {
      let ids = [...prev]

      if (ids.includes(recordId)) {
        ids = ids.filter(id => id !== recordId)
      } else {
        ids.push(recordId)
      }
      return ids
    })
  }

  const childRowIsVisible = recordId => visibleChildRowRecordIds.includes(recordId)

  const columnIsSortable = configItem => {
    const sortingIsDisabled = configItem.isSortable === false || configItem.renderCell // If renderCell is defined, the column is not sortable, or if isSortable is explicitly set to false
    return (isSortable && !sortingIsDisabled) || configItem.isSortable
  }

  const handleSelect = (e, record, recordIndex) => {
    // If they shift clicked, select all records between the last selected record and this one
    if (e.nativeEvent.shiftKey && lastSelectedRecordIndex >= 0) {
      // Find start and end index of the range, and get all records in that range
      const startIndex = Math.min(lastSelectedRecordIndex, recordIndex)
      const endIndex = Math.max(lastSelectedRecordIndex, recordIndex)
      const recordsInRange = currentRecords.slice(startIndex, endIndex + 1)

      // Look at the last selected record to determine if we should select or deselect the records in the range
      const lastToggledRecord = currentRecords[lastSelectedRecordIndex]

      // If the last selected record is not currently selected, we want to deselect all records in the range
      if (!selectedRecords.includes(lastToggledRecord)) {
        onSelectChange(selectedRecords.filter(item => !recordsInRange.includes(item)))
      } else { // Otherwise, we want to select all records in the range
        const newSelectedRecords = recordsInRange.filter(item => !selectedRecords.includes(item)) // Only select records that aren't already selected
        onSelectChange([...selectedRecords, ...newSelectedRecords])
      }
    } else if (selectedRecords.includes(record)) { // For a single select, if they clicked a record that is already selected, deselect it
      onSelectChange(selectedRecords.filter(item => item !== record))
    } else { // Lastly, if they clicked a record and it is not already selected, select it
      onSelectChange([...selectedRecords, record])
    }

    // Set the last selected record index so we can shift select
    setLastSelectedRecordIndex(recordIndex)
  }

  const handleSelectAll = () => {
    const selectableRecords = getSelectableRecords()

    if (selectedRecords.length === selectableRecords.length) {
      onSelectChange([])
    } else {
      onSelectChange(selectableRecords)
    }
    setLastSelectedRecordIndex() // Clear this so shift selecting doesn't act weird afterwards
  }

  // Sort records when sortKey or sortDirection changes, or when records change
  useEffect(() => {
    let pendingRecords = [...records] // Copy the records so we don't mutate the original. This is important for the sort to work properly.

    const isSorting = isSortable || columnConfigs.some(configItem => configItem.isSortable)

    if (isSorting) {
      const configItem = columnConfigs.find(item => item.dataKey === sortKey)

      if (configItem) {
        pendingRecords = pendingRecords.sort((a, b) => {
          const dataType = configItem?.dataType

          if (columnIsSortable(configItem) && !dataType) {
            throw Error('Column config dataType is null on one or more entries')
          }

          let aVal = a[sortKey] ?? ''
          let bVal = b[sortKey] ?? ''
          const isAsc = sortDirection === 'asc'

          // Date sorting
          if (dataType === 'date') {
            aVal = new Date(aVal)
            bVal = new Date(bVal)

            if (isAsc) {
              return aVal - bVal
            } else {
              return bVal - aVal
            }
          }

          // Number sorting
          if (dataType === 'number') {
            aVal = Number(aVal)
            bVal = Number(bVal)

            if (isAsc) {
              return aVal - bVal
            } else {
              return bVal - aVal
            }
          }

          // Everything else is sorted as a string
          aVal = aVal.toString().toLowerCase()
          bVal = bVal.toString().toLowerCase()

          if (aVal < bVal) {
            return isAsc ? -1 : 1
          } else if (aVal > bVal) {
            return isAsc ? 1 : -1
          }

          return 0
        })
      }
    }

    // Pagination
    if (isPaginated) {
      const totalRecords = records?.length ?? 0
      const totalPages = Math.ceil(totalRecords / recordsPerPage)

      const startIndex = (currentPage - 1) * recordsPerPage
      const endIndex = Math.min(startIndex + recordsPerPage, totalRecords)

      const text = <span>Showing <b>{startIndex + 1} - {endIndex}</b> of <b>{totalRecords}</b></span>

      const newPaginationInfo = {
        totalPages: totalPages,
        text: text
      }

      if (currentPage > newPaginationInfo.totalPages) {
        setCurrentPage(1)
      }

      setPaginationInfo(newPaginationInfo)

      pendingRecords = pendingRecords.slice(startIndex, endIndex)
    }

    setCurrentRecords(pendingRecords)
  }, [records, sortKey, sortDirection, currentPage])

  return (
    <>
      <TableContainer component={Paper} sx={{maxHeight: height ?? 600, overflow: 'auto', maxWidth: '100%', ...sx}}>
        <Table stickyHeader size="small">
          <TableHead>
            {/* Renders anything we want before the header, like another header row on top. */}
            {preHeaderSlot}

            {/* Header row */}
            <TableRow>
              {isSelectable &&
                <TableCell>
                  <Checkbox checked={selectedRecords.length === getSelectableRecords().length} onChange={handleSelectAll} />
                </TableCell>
              }
              {columnConfigs.map(configItem => {
                let title

                // Check for custom header render
                if (configItem.renderHeader) {
                  title = configItem.renderHeader()
                } else {
                  title = configItem.title
                }

                // Header tooltip
                if (configItem.headerTooltip) {
                  title = (
                    <Flex alignCenter justifyBetween>
                      <span>{title}</span>
                      <RbInfoTooltip title={configItem.headerTooltip} />
                    </Flex>
                  )
                }

                let content

                // Render sortable header, otherwise the content will just be the title
                if (columnIsSortable(configItem)) {
                  content = (
                    <Flex alignCenter sx={{cursor: 'pointer'}} onClick={() => setSort(configItem.dataKey)}>
                      <TableSortLabel active={sortKey === configItem.dataKey} direction={sortDirection} sx={{
                        '.MuiTableSortLabel-icon': {
                          fontSize: '1.2rem'
                        }
                      }}
                      >
                        <span style={{whiteSpace: headerTextNoWrap ? 'nowrap' : 'normal'}}>{title}</span>
                      </TableSortLabel>
                    </Flex>
                  )
                } else {
                  content = title
                }

                return (
                  <TableCell
                    component="th"
                    key={`${JSON.stringify(configItem)}_title`}
                    colSpan={configItem.colSpan}
                    sx={{
                      top: -1,
                      textAlign: configItem.textAlign,
                      width: configItem.width,
                      whiteSpace: headerTextNoWrap ? 'nowrap' : 'normal',
                      ...configItem.headerSx
                    }}
                  >
                    {content}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {currentRecords.map((record, recordIndex) => {
              if (record.id === null || typeof record.id === 'undefined') {
                throw Error('All records must have an id')
              }

              let recordIsSelectable = isSelectable

              // Allow for parent component to dictate which records are selectable
              if (isSelectable && isRecordSelectable) {
                recordIsSelectable = isRecordSelectable(record)
              }

              return (
                <React.Fragment key={record.id}>
                  <TableRow
                    sx={{
                      '&:nth-of-type(odd)': {
                        backgroundColor: '#f9f9f9;',
                      },
                      ...(getRowStyle ? getRowStyle(record) : {}) // Allow for custom row styles
                    }}
                  >
                    {isSelectable &&
                      <TableCell>
                        {recordIsSelectable &&
                          <Checkbox checked={selectedRecords.includes(record)} onChange={e => handleSelect(e, record, recordIndex)} />
                        }
                      </TableCell>
                    }

                    {columnConfigs.map(configItem => {
                      let value

                      // Check for custom cell render
                      if (configItem.renderCell) {
                        value = configItem.renderCell(record)
                      } else if (configItem.togglesChildRow) { // Check for child row toggle
                        value = (
                          <IconButton onClick={() => toggleChildRow(record.id)}>
                            {childRowIsVisible(record.id) ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        )
                      } else if (configItem.dataType === 'boolean') { // Boolean data type
                        value = record[configItem.dataKey] ? <Check /> : <Close />
                      } else {
                        value = record[configItem.dataKey]
                      }

                      // Empty-coalesce the cell
                      if (configItem.eCoal) {
                        value = eCoal(value)
                      }

                      return (
                        <TableCell
                          key={`${JSON.stringify(configItem)}_cell`}
                          colSpan={configItem.colSpan}
                          sx={{
                            textAlign: configItem.textAlign,
                            whiteSpace: cellTextNoWrap ? 'nowrap' : 'normal',
                            ...configItem.cellSx
                          }}
                        >
                          {value}
                        </TableCell>
                      )
                    })}
                  </TableRow>

                  {/* Child row */}
                  {childRowIsVisible(record.id) &&
                    <TableRow>
                      <TableCell colSpan={columnConfigs.length} sx={{padding: 0}}>
                        {renderChildRow(record)}
                      </TableCell>
                    </TableRow>
                  }
                </React.Fragment>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {isPaginated &&
        <Flex justifyContent="flex-end" sx={{mt: 1}}>
          <Flex alignCenter>
            <RbText sx={{mr: 2, pr: 2, borderRight: '1px solid gray'}}>{paginationInfo.text}</RbText>

            <Pagination
              count={paginationInfo.totalPages}
              page={currentPage}
              onChange={(_, newPage) => setCurrentPage(newPage)}
              showFirstButton
              showLastButton
              variant="outlined"
              color="secondary"
            />
          </Flex>
        </Flex>
      }
    </>
  )
}

export default RecordTable