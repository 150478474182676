import React, {useEffect} from 'react'
import {Close} from '@mui/icons-material'
import {Box, Divider, IconButton, Modal} from '@mui/material'
import Flex from './Flex'
import RbText from './RbText'

const RbModal = ({title, open, onClose, children, width, minWidth, maxWidth}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width,
    minWidth: minWidth,
    maxWidth: maxWidth ?? '100%',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: 24,
    p: 3,
  }

  // Allow cancelling with the escape key
  useEffect(() => {
    const handleEsc = event => {
      if (event.keyCode === 27) {
        onClose()
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => window.removeEventListener('keydown', handleEsc)
  }, [])

  return (
    <div>
      <Modal open={open} onClose={onClose}>
        <Box sx={style}>
          {/* Header */}
          <Flex alignCenter justifyBetween>
            <RbText h4 noBottomMargin sx={{flexGrow: 1, mr: 4}}>{title}</RbText>

            {onClose &&
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            }

          </Flex>

          <Divider sx={{mt: 1, mb: 2}} />

          {/* Body */}
          <Box>
            {children}
          </Box>
        </Box>
      </Modal>
    </div>
  )
}

export default RbModal