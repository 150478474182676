import React, {useEffect, useState} from 'react'
import {getMessaging, getToken, onMessage} from 'firebase/messaging'
import {initializeApp} from 'firebase/app'
import apiRequests from '../../api/apiRequests'
import {useCurrentUser} from '../../context/CurrentUserContext'
import {ListItemIcon, MenuItem} from '@mui/material'
import {NotificationAdd, NotificationsOff} from '@mui/icons-material'
import {toast} from 'react-toastify'
import useApiRequest from '../../hooks/useApiRequest'
import {delay} from '../../utils/helpers'

// These values are duplicated in the service worker (in /public folder)
// read the comment in there for context. But if you update these, you gotta
// update those.
const firebaseConfig = ({
  apiKey: 'AIzaSyAhab3JqL509uSML5XahBpOobdfYLRmkO0',
  authDomain: 'rebentify-4aa64.firebaseapp.com',
  projectId: 'rebentify-4aa64',
  storageBucket: 'rebentify-4aa64.appspot.com',
  messagingSenderId: '953367075124',
  appId: '1:953367075124:web:c5ebc7f1ac73232f9fed61',
  measurementId: 'G-R0LPYK5GW1'
})

const GRANTED_PERMISSION = 'granted'

const PushNotifications = () => {
  const [isSubscribed, setIsSubscribed] = useState(false)
  const app = initializeApp(firebaseConfig)
  const messaging = getMessaging(app)

  const {currentUserId} = useCurrentUser()

  const errorText = 'Error setting up push notifications'
  const requests = {
    getEnrolledDevices: useApiRequest({
      request: apiRequests.pushNotifications.getEnrolledDevices,
      errorText: errorText
    }),
    subscribe: useApiRequest({
      request: apiRequests.pushNotifications.subscribe,
      errorText: errorText
    }),
    unsubscribe: useApiRequest({
      request: apiRequests.pushNotifications.unsubscribe,
      errorText: 'Error unsubscribing from push notifications'
    }),
  }

  // When a notification comes in, this function will catch and dispatch to the service worker (in the /public folder)
  useEffect(() => {
    const handleNewMessage = payload => {
      const notificationTitle = payload.notification.title
      const notificationOptions = {
        body: payload.notification.body,
        icon: payload.notification.icon,
      }
      new Notification(notificationTitle, notificationOptions)
    }

    onMessage(messaging, handleNewMessage)
  }, [])

  // Will actually handle requesting permission + getting token from firebase
  const handleSubscribe = () => {
    toast.info('Click allow on upcoming pop-up for Rebentify notifications', {position: 'top-left', autoClose: 3000})

    return delay(3000)
      .then(() => Notification.requestPermission())
      .then(permission => {
        if (permission === GRANTED_PERMISSION) {
          return getToken(messaging)
        } else {
          setIsSubscribed(false)
          toast.error('Notifications are not allowed per your browsers settings. Please speak with IT to enable push notifications.')
          throw new Error('Permission not granted')
        }
      })
  }

  // Returns a promise of a boolean for is a user is currently subscribed to push notifications (checks their sub id against database)
  const isUserSubscribed = () => {
    if (Notification.permission === GRANTED_PERMISSION) {
      return getToken(messaging)
        .then(currentToken => {
          if (currentToken) {
            return requests.getEnrolledDevices.send(currentUserId)
              .then(enrolledDevices => {
                const matchingDevices = enrolledDevices?.filter(token => token.subscriberId === currentToken)
                return matchingDevices?.length > 0
              })
          }

          return false
        })
    }

    return Promise.resolve(false)
  }

  // User has clicked the dropdown button, they either want to sub or unsub. Handle as such.
  const handleClick = () => {
    isUserSubscribed()
      .then(subscriptionStatus => {
        if (subscriptionStatus) {
          return getToken(messaging)
            .then(token => {
              const encodedToken = encodeURIComponent(token)
              return requests.unsubscribe.send(encodedToken)
            })
            .then(() => setIsSubscribed(false))
        } else {
          return handleSubscribe()
            .then(token => {
              if (!token) {
                toast.error('Please allow notifications to subscribe to Rebentify', {position: 'top-left', autoClose: 4000})
                throw new Error('Token not received')
              }
              return requests.subscribe.send({accountId: currentUserId, subscriberId: token})
            })
            .then(() => setIsSubscribed(true))
        }
      })
      .catch(() => {
        toast.error('Error in subscription process. Please reach out to your school Rebentify contact for assistance.')
        setIsSubscribed(false)
      })
  }

  // Runs on first load, sets the dropdown to correct value based on DB
  useEffect(() => {
    isUserSubscribed()
      .then(subscriptionStatus => {
        setIsSubscribed(subscriptionStatus)
      })
  }, [])

  return (
    <MenuItem onClick={handleClick}>
      <ListItemIcon>
        {isSubscribed ? <NotificationsOff /> : <NotificationAdd />}
      </ListItemIcon>
      {isSubscribed ? 'Disable Notifications' : 'Allow Notifications'}
    </MenuItem>
  )
}

export default PushNotifications
