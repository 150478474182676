import React, {useCallback, useEffect, useState} from 'react'
import {Box, Grid} from '@mui/material'
import moment from 'moment'
import {useSchedule} from './ScheduleContext'
import StudentSchedule from './student/StudentSchedule'
import TeacherSchedule from './teacher/TeacherSchedule'
import RbLoading from '../common/components/RbLoading'
import useWeek from '../../hooks/useWeek'

const Week = ({date}) => {
  const {isTeacherSchedule, days} = useSchedule()
  const {weekOfDate} = useWeek(date)

  const [weekInfo, setWeekInfo] = useState()
  const [lastRefreshTime, setLastRefreshTime] = useState()

  const ScheduleComponent = isTeacherSchedule ? TeacherSchedule : StudentSchedule

  const refresh = useCallback(() => {
    if (!weekOfDate) {
      return
    }

    const today = moment()

    setLastRefreshTime(moment())

    const newWeek = {...weekOfDate}

    const weekStartDate = moment(date)

    newWeek.startDate = weekStartDate.weekday(0).toDate()
    newWeek.endDate = weekStartDate.weekday(6).toDate()

    newWeek.days = []
    days.forEach(day => {
      const currentDate = weekStartDate.weekday(day.weekDay)

      newWeek.days.push({
        ...day,
        date: currentDate.toDate(),
        isToday: today.format('YYYY-MM-DD') === currentDate.format('YYYY-MM-DD')
      })
    })

    setWeekInfo(newWeek)
  }, [weekOfDate, days, date])

  useEffect(() => {
    const autoRefresh = () => {
      if (lastRefreshTime && moment().diff(lastRefreshTime, 'minutes') > 15) {
        refresh()
      }
    }

    window.addEventListener('focus', autoRefresh)

    return () => window.removeEventListener('focus', autoRefresh)
  }, [lastRefreshTime])

  useEffect(() => {
    refresh()
  }, [weekOfDate])

  return (
    <Box mb={6}>
      {!weekInfo && <RbLoading />}

      {weekInfo &&
        <Grid container spacing={1} mb={4}>
          <ScheduleComponent weekInfo={weekInfo} />
        </Grid>
      }
    </Box>
  )
}

export default Week