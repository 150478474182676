import React, {useState} from 'react'
import Navbar from './components/main-layout/Navbar'
import Sidebar from './components/main-layout/Sidebar'
import {Box} from '@mui/material'
import {useCurrentUser} from './context/CurrentUserContext'
import RbRoutes from './RbRoutes'
import Flex from './components/common/components/Flex'
import ErrorBoundary from './errors/ErrorBoundary'

const sidebarCookieKey = 'sidebarIsExpanded'

function App() {
  const [sidebarIsExpanded, setSidebarIsExpanded] = useState(() => localStorage.getItem(sidebarCookieKey))
  const [sidebarIsInteractedWith, setSidebarIsInteractedWith] = useState(false)

  const {currentUserIsStudent, currentUserAcceptedTos, currentUser} = useCurrentUser()

  const sidebarSize = sidebarIsExpanded ? 210 : 75
  const navbarHeight = 60

  const hasSidebar = currentUser && !currentUserIsStudent && currentUserAcceptedTos
  const marginLeft = hasSidebar ? `${sidebarSize}px` : 0

  return (
    <Flex>
      {hasSidebar && <Sidebar isExpanded={sidebarIsExpanded} size={sidebarSize} navbarHeight={navbarHeight} />}

      <Box sx={{flexGrow: 1, width: '100%'}}>
        {currentUser &&
          <Navbar hasSidebar={hasSidebar} height={navbarHeight} onSidebarToggle={() => {
            setSidebarIsInteractedWith(true)
            setSidebarIsExpanded(prev => {
              const newVal = !prev
              newVal ? localStorage.setItem(sidebarCookieKey, true) : localStorage.removeItem(sidebarCookieKey)
              return newVal
            })
          }}
          />
        }

        {/* Body */}
        <Box
          className="app-content"
          sx={{
            p: currentUser ? 3 : 0, // If user is logged in, add padding
            ml: marginLeft,
            transition: sidebarIsInteractedWith ? 'margin-left 0.2s ease-in-out' : '' // Checking for whether the sidebar has been interactive with prevents rubber banding of the left margin at the start of page load. So the sidebar only transitions after it has been interacted with.
          }}
        >
          <ErrorBoundary>
            <RbRoutes />
          </ErrorBoundary>
        </Box>
      </Box>
    </Flex>
  )
}

export default App
