import React from 'react'
import usePriorities from '../../../hooks/usePriorities'
import RbSelect from './RbSelect'
import {bind} from '../../../utils/helpers'
import RbLoading from '../components/RbLoading'

const PrioritySelect = ({value, onChange, sx, required}) => {
  const {priorities} = usePriorities()

  if (!priorities) {
    return <RbLoading small />
  }

  return (
    <RbSelect
      label="Priority"
      options={priorities}
      valueKey="id"
      labelKey="description"
      required={required}
      sx={sx}
      {...bind.select(value, onChange)}
    />
  )
}

export default PrioritySelect