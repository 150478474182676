import React, {useState} from 'react'
import apiRequests from '../../../../../api/apiRequests'
import {useConfirm} from '../../../../../context/ConfirmationContext'
import {IconButton} from '@mui/material'
import RbButton from '../../../../common/inputs/RbButton'
import Flex from './../../../../common/components/Flex'
import RbModal from './../../../../common/components/RbModal'
import RbSelect from './../../../../common/inputs/RbSelect'
import RbTextField from './../../../../common/inputs/RbTextField'
import RecordTable from './../../../../common/components/RecordTable'
import ConnectionStatusCell from './partials/ConnectionStatusCell'
import {Delete} from '@mui/icons-material'
import {bind} from '../../../../../utils/helpers'
import RbText from '../../../../common/components/RbText'
import useApiRequest from '../../../../../hooks/useApiRequest'

const SisSettings = () => {
  const confirm = useConfirm()

  const [connections, setConnections] = useState([])
  const [sisTypes, setSisTypes] = useState([])
  const [addConnectionModalIsVisible, setAddConnectionModalIsVisible] = useState(false)
  const [sisType, setSisType] = useState('')
  const [apiUrl, setApiUrl] = useState('')
  const [apiKey, setApiKey] = useState('')
  const [apiSecret, setApiSecret] = useState('')
  const [connectionNickname, setConnectionNickname] = useState('')

  const requests = {
    getConnectionDetails: useApiRequest({
      request: apiRequests.sis.getConnectionDetails,
      errorText: 'There was an error loading your current connections.',
      onResponse: setConnections,
      sendImmediately: true
    }),
    getSisTypes: useApiRequest({
      request: apiRequests.sis.getSisTypes,
      errorText: 'There was an error loading the SIS types.',
      onResponse: setSisTypes,
      sendImmediately: true
    }),
    addConnection: useApiRequest({
      request: apiRequests.sis.addConnection,
      errorText: 'There was an error adding your new connection.',
      onResponse: data => {
        setConnections(prevData => [...prevData, data])
        setAddConnectionModalIsVisible(false)
        setSisType('')
        setApiUrl('')
        setApiKey('')
        setApiSecret('')
      }
    }),
    deleteConnection: useApiRequest({
      request: apiRequests.sis.deleteConnection,
      errorText: 'There was an error deleting your connection.',
    })
  }

  const addConnection = e => {
    e.preventDefault()

    requests.addConnection.send({
      sisTypeEnum: sisType,
      apiUrl: apiUrl,
      apiKey: apiKey,
      apiSecret: apiSecret,
      apiVersionEnum: 'v1p1',
      connectionNickname: connectionNickname
    })
  }

  const deleteConnection = record => {
    confirm({title: 'Are you sure you want to delete this connection?'})
      .then(() => {
        requests.deleteConnection.send(record.id)
          .then(() => setConnections(connections.filter(connection => connection.id !== record.id)))
      })
  }

  const renderDeleteCell = record => {
    return (
      <IconButton onClick={() => deleteConnection(record)}>
        <Delete sx={{color: 'error.main'}} />
      </IconButton>
    )
  }
  return (
    <>
      <Flex justifyBetween alignItems="flex-end" sx={{mb: 1}}>
        <RbText h3 noBottomMargin>Current Connections</RbText>
        <RbButton
          onClick={() => setAddConnectionModalIsVisible(true)}
          icon="add"
        >
          Add Connection
        </RbButton>
      </Flex>

      {addConnectionModalIsVisible &&
        <RbModal title="New Connection" onClose={() => setAddConnectionModalIsVisible(false)} open>
          <form onSubmit={addConnection}>
            <RbSelect
              label="SIS System"
              required
              options={sisTypes}
              {...bind.select(sisType, setSisType)}
            />

            <RbTextField
              label="Connection Nickname"
              required
              {...bind.input(connectionNickname, setConnectionNickname)}
            />

            <RbTextField
              label="API URL"
              required
              type="url"
              {...bind.input(apiUrl, setApiUrl)}
            />

            <RbTextField
              label="API Key"
              required
              {...bind.input(apiKey, setApiKey)}
            />

            <RbTextField
              label="API Secret"
              required
              {...bind.input(apiSecret, setApiSecret)}
            />

            <RbButton type="submit" sx={{mt: 2}} isLoading={requests.addConnection.isLoading}>
              Add Connection
            </RbButton>
          </form>
        </RbModal>
      }

      {connections.length === 0 && <RbText h3 sx={{mt: 5}}>No Connections</RbText>}

      {connections.length > 0 &&
        <RecordTable
          records={connections}
          columnConfigs={[
            {title: 'Connection Name', dataKey: 'connectionNickname', dataType: 'string'},
            {title: 'Status', renderCell: record => <ConnectionStatusCell connection={record} />, width: 50, textAlign: 'center'},
            {title: 'Delete', renderCell: renderDeleteCell, width: 50, textAlign: 'center'}
          ]}
        />
      }
    </>
  )
}

export default SisSettings