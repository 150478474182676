import React, {useState} from 'react'
import apiRequests from '../../../../../../api/apiRequests'
import {Cancel, Check} from '@mui/icons-material'
import RbLoading from '../../../../../common/components/RbLoading'
import useApiRequest from '../../../../../../hooks/useApiRequest'

const ConnectionStatusCell = ({connection}) => {
  const [connectionSuccessful, setConnectionSuccessful] = useState(false)

  const testConnectionRequest = useApiRequest({
    request: apiRequests.sis.testConnection,
    params: [connection.id],
    errorText: 'There was an error testing your connection.',
    onError: () => setConnectionSuccessful(false),
    onResponse: () => setConnectionSuccessful(true),
    sendImmediately: true
  })

  return (
    <>
      {testConnectionRequest.isLoading && <RbLoading size={20} />}

      {!testConnectionRequest.isLoading &&
        <>
          {connectionSuccessful && <Check sx={{color: 'success.main'}} />}
          {!connectionSuccessful && <Cancel sx={{color: 'error.main'}} />}
        </>
      }
    </>
  )
}

export default ConnectionStatusCell