import React, {useMemo, useState, createContext, useContext, useEffect} from 'react'
import apiRequests from '../../../../api/apiRequests'
import ImportSettings from './integration/steps/ImportSettings/ImportSettings'
import Simulate from './integration/steps/Import/Simulate'
import TestConnection from './integration/steps/TestConnection'
import Import from './integration/steps/Import/Import'
import useApiRequest from '../../../../hooks/useApiRequest'

const SisIntegrationContext = createContext()

export const IMPORT_TYPES = {
  TEACHER: 'Teacher',
  STUDENT: 'Student'
}

export const STUDENT_HOMEROOM_ASSIGNMENT_METHODS = {
  NONE: 'None',
  GRADE_LEVEL: 'Grade Level',
  SIS: 'SIS'
}

export const SisIntegrationProvider = ({children}) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [currentConnection, setCurrentConnection] = useState(null)
  const [currentSchoolId, setCurrentSchoolId] = useState(null)
  const [importType, setImportType] = useState(null)
  const [studentHomeroomAssignmentMethod, setStudentHomeroomAssignmentMethod] = useState(null) // Which method of homeroom assignment we will use
  const [gradeLevelHomeroomImportSettings, setGradeLevelHomeroomImportSettings] = useState(null) // Maps the homeroom teacher selected for each grade level.
  const [studentHomeroomCourseSourcedId, setStudentHomeroomCourseSourcedId] = useState(null) // In the SIS homeroom method, we chose the first course sourced ID for the selected period
  const [isSkippingSimulation, setIsSkippingSimulation] = useState(null)
  const [simulatedData, setSimulatedData] = useState(null)
  const [allSimulationDataIsViewed, setAllSimulationDataIsViewed] = useState(false) // All simulation data is required to be viewed before the user can move on to importing
  const [importedData, setImportedData] = useState(null)

  const settingsAreReady = () => {
    if (!currentConnection || !currentSchoolId || !importType) {
      return false
    }

    if (importType === IMPORT_TYPES.TEACHER || studentHomeroomAssignmentMethod === STUDENT_HOMEROOM_ASSIGNMENT_METHODS.NONE) {
      return true
    } else if (studentHomeroomAssignmentMethod === STUDENT_HOMEROOM_ASSIGNMENT_METHODS.GRADE_LEVEL) {
      return Boolean(gradeLevelHomeroomImportSettings)
    } else if (studentHomeroomAssignmentMethod === STUDENT_HOMEROOM_ASSIGNMENT_METHODS.SIS) {
      return Boolean(studentHomeroomCourseSourcedId)
    }

    return false
  }

  const STEPS = [
    {
      name: 'Test Connection',
      title: 'Test Connection',
      component: TestConnection,
      isReady: () => Boolean(currentConnection),
      icon: 'cable'
    },
    {
      name: 'Settings',
      title: 'Select School and Import Type',
      component: ImportSettings,
      isReady: settingsAreReady,
      onBack: () => {
        setCurrentConnection(null)
        setCurrentSchoolId(null)
        setImportType(null)
      },
      icon: 'tune'
    },
    {
      name: 'Simulate',
      title: 'Simulate',
      component: Simulate,
      icon: 'manage_accounts',
      isReady: () => isSkippingSimulation || (Boolean(simulatedData) && allSimulationDataIsViewed),
      onBack: () => {
        setSimulatedData(null)
        setIsSkippingSimulation(false)
        setAllSimulationDataIsViewed(false)
      },
    },
    {
      name: 'Import',
      title: 'Import',
      component: Import,
      icon: 'group_add',
      isReady: () => Boolean(importedData),
    }
  ]

  const currentStepIsReady = () => {
    const step = STEPS[currentStep]

    if (!step.isReady) {
      return false
    }

    return step.isReady()
  }

  const goToPreviousStep = () => {
    const step = STEPS[currentStep]

    if (step.onBack) {
      step.onBack()
    }

    setCurrentStep(prev => Math.max(prev - 1, 0))
  }

  const getStudentHomeroomImplementation = () => {
    switch (studentHomeroomAssignmentMethod) {
      case STUDENT_HOMEROOM_ASSIGNMENT_METHODS.NONE:
        return 'None'
      case STUDENT_HOMEROOM_ASSIGNMENT_METHODS.GRADE_LEVEL:
        return 'HomeroomByGradeLevel'
      case STUDENT_HOMEROOM_ASSIGNMENT_METHODS.SIS:
        return 'HomeroomByCourses'
      default:
        return null
    }
  }

  const importRequests = {
    importTeachers: useApiRequest({
      request: apiRequests.sis.migration.importTeachers,
      throwOnError: true, // Let ImportLayout handle the error
    }),
    importStudents: useApiRequest({
      request: apiRequests.sis.migration.importStudents,
      throwOnError: true, // Let ImportLayout handle the error
    })
  }

  const doImport = (isSimulation = false) => {
    const request = importType === IMPORT_TYPES.TEACHER ? importRequests.importTeachers : importRequests.importStudents

    return request.send({
      sisConnectionDetails: {id: currentConnection.id},
      schoolSourcedId: currentSchoolId,
      simulateImport: isSimulation,
      studentHomeroomImplementation: getStudentHomeroomImplementation(),
      courseSourcedId: studentHomeroomCourseSourcedId,
      gradeToHomeroomTeacherMap: gradeLevelHomeroomImportSettings
    })
  }

  useEffect(() => {
    setImportType(null)
    setGradeLevelHomeroomImportSettings(null)
    setStudentHomeroomAssignmentMethod(null)
    setStudentHomeroomCourseSourcedId(null)
  }, [currentSchoolId])

  const contextValue = useMemo(() => ({
    STEPS,
    currentStep,
    setCurrentStep,
    currentConnection,
    setCurrentConnection,
    currentSchoolId,
    setCurrentSchoolId,
    importType,
    setImportType,
    gradeLevelHomeroomImportSettings,
    setGradeLevelHomeroomImportSettings,
    studentHomeroomAssignmentMethod,
    setStudentHomeroomAssignmentMethod,
    studentHomeroomCourseSourcedId,
    setStudentHomeroomCourseSourcedId,
    isSkippingSimulation,
    setIsSkippingSimulation,
    simulatedData,
    setSimulatedData,
    importedData,
    setImportedData,
    importIsLoading: importRequests.importTeachers.isLoading || importRequests.importStudents.isLoading,
    setAllSimulationDataIsViewed,
    currentStepIsReady: currentStepIsReady(),
    goToNextStep: () => setCurrentStep(prev => Math.min(prev + 1, STEPS.length - 1)),
    goToPreviousStep: goToPreviousStep,
    doImport,
  }), [currentStep, currentConnection, currentSchoolId, importType, gradeLevelHomeroomImportSettings, studentHomeroomAssignmentMethod, studentHomeroomCourseSourcedId, isSkippingSimulation, simulatedData, importedData, allSimulationDataIsViewed])

  return (
    <SisIntegrationContext.Provider value={contextValue}>
      {children}
    </SisIntegrationContext.Provider>
  )
}

export const useSisIntegration = () => {
  const context = useContext(SisIntegrationContext)

  if (context === undefined) {
    throw new Error('useSisIntegration must be used within a SisIntegrationProvider')
  }

  return context
}