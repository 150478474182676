import React, {useState} from 'react'
import {toast} from 'react-toastify'
import apiRequests from '../../../../api/apiRequests'
import {bind} from '../../../../utils/helpers'
import RbTextField from '../../../common/inputs/RbTextField'
import {Stack} from '@mui/material'
import RbButton from '../../../common/inputs/RbButton'
import GroupSelect from '../../../common/inputs/GroupSelect'
import RbText from '../../../common/components/RbText'
import PrioritySelect from '../../../common/inputs/PrioritySelect'
import useGroups from '../../../../hooks/useGroups'
import useApiRequest from '../../../../hooks/useApiRequest'

const GroupRequests = ({locationId}) => {
  const defaultGroupInfo = {
    selectedGroup: null,
    priorityLevel: null,
    reason: ''
  }
  const [groupInfo, setGroupInfo] = useState(defaultGroupInfo)

  const {groups} = useGroups()

  const requestGroupRequest = useApiRequest({
    request: apiRequests.attendance.updateGroupLocation,
    errorText: 'Failed to save Group Request. Please try again.'
  })

  const submit = e => {
    e.preventDefault()

    const groupRequest = {
      groupId: groupInfo.selectedGroup.id,
      priorityId: parseInt(groupInfo.priorityLevel),
      locationId: locationId,
      reason: groupInfo.reason
    }

    requestGroupRequest.send(groupRequest)
      .then(failedStudents => {
        if (failedStudents.length > 0) {
          toast.warning('Partial Success: ' + failedStudents.length + ' Failed Due to Higher Priorities Elsewhere', {autoClose: false})
          toast.error('Failed Students: ' + failedStudents.join(', '), {autoClose: false})
        } else {
          toast.success('Group Requested Successfully', {autoClose: 10000})
        }
        setGroupInfo(defaultGroupInfo)
      })
  }

  return (
    <>
      <RbText h6>Group Requests</RbText>

      <form onSubmit={submit}>
        <Stack spacing={1}>
          <GroupSelect
            required
            groups={groups}
            {...bind.object.recordSelect(groupInfo, 'selectedGroup', setGroupInfo)}
          />

          {groupInfo.selectedGroup &&
            <>
              <PrioritySelect
                required
                {...bind.object.select(groupInfo, 'priorityLevel', setGroupInfo)}
              />

              {groupInfo.priorityLevel &&
                <>
                  <RbTextField
                    multiline
                    label="Reason"
                    {...bind.object.input(groupInfo, 'reason', setGroupInfo)}
                  />

                  <RbButton type="submit" isLoading={requestGroupRequest.isLoading}>Submit</RbButton>
                </>
              }
            </>
          }
        </Stack>
      </form>
    </>
  )
}

export default GroupRequests