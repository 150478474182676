import React from 'react'
import {useCurrentUser} from '../context/CurrentUserContext'
import {useLocation} from 'react-router-dom'
import BasicErrorBoundary from './BasicErrorBoundary'

// Error boundary that is used throughout the app. This has access to contexts and can report more specific data.
const ErrorBoundary = ({children}) => {
  const {currentUser} = useCurrentUser()
  const location = useLocation()

  return (
    <BasicErrorBoundary
      resetErrorIfChanged={location} // This will reset the error state if the location changes
      extraErrorInfo={{'User Id': currentUser?.id}}
    >
      {children}
    </BasicErrorBoundary>
  )
}

export default ErrorBoundary