import React from 'react'
import {bind} from '../../../utils/helpers'
import RecordSelect from './RecordSelect'

const GroupSelect = ({label = 'Group', groups, sx, selectedRecords, onChange, ignoreGroups, multiple, icon, required}) => {
  return (
    <RecordSelect
      records={groups}
      label={label}
      labelKey="name"
      ignoreRecords={ignoreGroups}
      multiple={multiple}
      sx={sx}
      icon={icon}
      required={required}
      {...bind.recordSelect(selectedRecords, onChange)}
    />
  )
}

export default GroupSelect