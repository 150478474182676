import React from 'react'
import {toast} from 'react-toastify'
import {useAutomatedTasks} from '../AutomatedTasksContext'
import apiRequests from '../../../../../api/apiRequests'
import DailyConfig from '../partials/DailyConfig'
import useApiRequest from '../../../../../hooks/useApiRequest'

const AttendanceEmail = () => {
  const {attendanceEmailConfig, updateAttendanceEmailConfig} = useAutomatedTasks()

  const request = useApiRequest({
    request: apiRequests.signUpPeriodConfig.sendAttendanceEmail,
    throwOnError: true,
  })

  const handleManualEmailAttendances = dayConfig => {
    if (!dayConfig) {
      return
    }

    toast.promise(request.send(dayConfig),
      {
        pending: 'Sending Email Request',
        success: 'Successfully Emailed Staff',
        error: 'There was an error emailing staff'
      }
    )
  }

  return (
    <DailyConfig
      config={attendanceEmailConfig}
      updateConfig={updateAttendanceEmailConfig}
      enableKey="attendanceEmailEnabled"
      enableLabel="Attendance Email"
      dayConfigsKey="attendanceEmailDailyConfigList"
      timeLabel="Send At"
      saveRequest={apiRequests.signUpPeriodConfig.updateAttendanceEmailConfig}
      configName="Attendance Email Time"
      saveButtonText="Save Attendance Email Settings"
      actions={[
        {label: 'Manually Email Attendance', onClick: handleManualEmailAttendances, icon: 'email'},
      ]}
    />
  )
}
export default AttendanceEmail