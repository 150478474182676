import React, {useEffect, useState} from 'react'
import {HelpCenter} from '@mui/icons-material'
import {IconButton, LinearProgress, linearProgressClasses} from '@mui/material'
import {toast} from 'react-toastify'
import apiRequests from '../../../api/apiRequests'
import Flex from '../../common/components/Flex'
import RecordTable from '../../common/components/RecordTable'
import RbText from '../../common/components/RbText'
import RbLoading from '../../common/components/RbLoading'
import RbInfoTooltip from '../../common/components/RbInfoTooltip'
import {getColor} from './utils'
import usePriorities from '../../../hooks/usePriorities'
import moment from 'moment'
import useApiRequest from '../../../hooks/useApiRequest'
import {bind, getPlurality} from '../../../utils/helpers'
import RbButton from '../../common/inputs/RbButton'
import {useConfirm} from '../../../context/ConfirmationContext'

const RosterTab = ({location, dayInfo}) => {
  const [attendance, setAttendance] = useState()
  const [selectedAttendance, setSelectedAttendance] = useState([])

  const {priorities} = usePriorities()
  const confirm = useConfirm()

  const locationId = location?.id
  const studentLimit = location.studentLimit ?? 0
  const attendanceLength = attendance?.length ?? 0
  const percentage = studentLimit === 0 ? 0 : parseInt(attendanceLength / studentLimit * 100)

  const requests = {
    getAttendance: useApiRequest({
      request: apiRequests.signUpLocations.getAttendance,
      errorText: 'An error occurred while fetching the roster.'
    }),
    removeStudentsFromLocation: useApiRequest({
      request: apiRequests.attendance.removeStudentsFromLocation,
      errorText: 'An error occurred while removing the students.'
    })
  }

  useEffect(() => {
    if (!(locationId && priorities)) {
      return
    }

    requests.getAttendance.send(locationId)
      .then(data => {
        const parsedData = data.map(attnItem => {
          const url = 'statistics/' + attnItem.accountId
          return {
            ...attnItem,
            requestType: getRequestType(attnItem.priorityId, attnItem.requestType, attnItem.wasAutoScheduled),
            fullName: attnItem.account.fullName,
            firstName: attnItem.account.firstName ?? '',
            lastName: attnItem.account.lastName ?? '',
            grade: attnItem.account.grade,
            userId: attnItem.accountId,
            url: url,
            locationId: locationId
          }
        })

        setAttendance(parsedData)
      })
  }, [locationId, priorities])

  const getRequestType = (priorityId, requestType, wasAutoScheduled) => {
    if (priorityId) {
      const priority = priorities.find(p => p.id === priorityId)
      if (priority) {
        let returnStr = ''
        returnStr = requestType ? 'Group' : 'Teacher' // 1 is group, 0 is teacher
        returnStr += ' (' + priority.description + ')'
        return returnStr
      } else {
        return requestType ? 'Group' : 'Teacher' // 1 is group, 0 is teacher
      }
    } else if (wasAutoScheduled) {
      return 'Autoscheduled (lvl 0)'
    } else {
      return ''
    }
  }

  const removeSelectedAttendance = () => {
    if (selectedAttendance.length === 0) {
      return
    }

    const studentText = `${selectedAttendance.length} ${getPlurality(selectedAttendance.length, 'student')}`

    confirm({
      title: 'Remove Students',
      description: `Are you sure you want to remove ${studentText} from this location?`
    })
      .then(() => {
        const studentAccountIds = selectedAttendance.map(attn => attn.userId)
        const recordIds = selectedAttendance.map(attn => attn.id)

        requests.removeStudentsFromLocation.send(locationId, studentAccountIds)
          .then(() => {
            setAttendance(prev => prev.filter(attnItem => !recordIds.includes(attnItem.id)))
            setSelectedAttendance([])
            toast.success(`${studentText} removed successfully.`)
          })
      })

  }

  const renderLogButton = record => {
    return (
      <a href={`/${record.url}?date=${moment(dayInfo.date).format('YYYY-MM-DD')}`} target="_blank" rel="noreferrer">
        <IconButton color="primary"><HelpCenter /></IconButton>
      </a>
    )
  }

  const renderRequestReasonTooltip = record => {
    if (record.teacherNote) {
      return (
        <Flex centered>
          <RbInfoTooltip title={record.teacherNote} />
        </Flex>
      )
    }
  }

  return (
    <>
      {!attendance && <RbLoading />}

      {attendance &&
        <>
          {attendanceLength === 0 && <RbText h4>No one signed up</RbText>}

          {attendanceLength > 0 &&
            <>
              {selectedAttendance.length > 0 &&
                <RbButton
                  icon="close"
                  onClick={removeSelectedAttendance}
                  color="error"
                  sx={{mb: 1}}
                  isLoading={requests.removeStudentsFromLocation.isLoading}
                >
                  Remove Students
                </RbButton>
              }

              <RecordTable
                columnConfigs={[
                  {title: 'First Name', dataKey: 'firstName', isSortable: true, dataType: 'string'},
                  {title: 'Last Name', dataKey: 'lastName', isSortable: true, dataType: 'string'},
                  {title: 'Grade', dataKey: 'grade', isSortable: true, dataType: 'number'},
                  {title: 'Request Type', dataKey: 'requestType', isSortable: true, cellSx: {whiteSpace: 'nowrap'}, dataType: 'string'},
                  {renderCell: renderRequestReasonTooltip},
                  {title: 'Log', textAlign: 'center', renderCell: renderLogButton},
                ]}
                records={attendance}
                defaultSortKey="lastName"
                isSelectable
                isRecordSelectable={record => !record.wasAutoScheduled}
                {...bind.recordTableSelection(selectedAttendance, setSelectedAttendance)}
              />
              <Flex alignCenter sx={{mt: 2}}>
                <RbText h5 noBottomMargin sx={{mr: 2}} alignCenter>{attendanceLength}/{studentLimit} Students</RbText>

                <LinearProgress
                  variant="determinate"
                  value={Math.min(percentage, 100)}
                  sx={{
                    flexGrow: 1,
                    height: 15,
                    borderRadius: 5,
                    [`& .${linearProgressClasses.bar}`]: {
                      borderRadius: 5,
                      backgroundColor: getColor(attendanceLength, studentLimit),
                    }
                  }}
                />
              </Flex>
            </>
          }
        </>
      }
    </>
  )
}

export default RosterTab