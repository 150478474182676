import {Box, Dialog} from '@mui/material'
import React, {createContext, useState, useContext, useCallback} from 'react'
import RbButton from '../components/common/inputs/RbButton'
import RbTextField from '../components/common/inputs/RbTextField'
import RbText from '../components/common/components/RbText'
import {bind} from '../utils/helpers'
import Flex from '../components/common/components/Flex'

const ConfirmationContext = createContext(null)

export const ConfirmationProvider = ({children}) => {
  const [options, setOptions] = useState({})
  const [promise, setPromise] = useState({resolve: null, reject: null})
  const {reject, resolve} = promise
  const [userInput, setUserInput] = useState()

  /**
   * Options:
   * {
   *  title: string (Required),
   *  description: string,
   *  confirmButtonText: string,
   *  cancelButtonText: string,
   *  userInput: {
   *    label: string,
   *    isRequired: boolean
   *  }
   * }
   */
  const confirm = useCallback((opts = {}) => {
    setOptions(opts)

    return new Promise((res, rej) => {
      setPromise({resolve: res, reject: rej})
    })
  }, [])

  const handleClose = () => {
    setPromise({resolve: null, reject: null})
    setUserInput()
  }

  const handleConfirm = () => {
    if (resolve) {
      resolve(userInput)
      handleClose()
    }
  }

  const handleCancel = () => {
    if (reject) {
      reject()
      handleClose()
    }
  }

  return (
    <>
      <ConfirmationContext.Provider value={confirm}>
        {children}
      </ConfirmationContext.Provider>

      {resolve && reject &&
        <Dialog open>
          <form onSubmit={e => {
            e.preventDefault()
            handleConfirm()
          }}
          >
            <Box gap={2} sx={{px: 3, py: 2}}>
              <RbText h5>{options.title}</RbText>

              {options.description &&
                <span>
                  {typeof options.description === 'string' ? <RbText>{options.description}</RbText> : options.description}
                </span>
              }

              {options.userInputProps &&
                <RbTextField
                  autoFocus
                  {...options.userInputProps}
                  {...bind.input(userInput, setUserInput)}
                />
              }

              <Flex gap={2} justifyContent="flex-end" alignCenter sx={{mt: 4}}>
                <RbButton type="button" onClick={handleCancel} color="cancel">
                  {options.cancelButtonText || 'Cancel'}
                </RbButton>
                <RbButton type="submit">
                  {options.confirmButtonText || 'Yes'}
                </RbButton>
              </Flex>
            </Box>
          </form>
        </Dialog>
      }
    </>
  )
}

export const useConfirm = () => useContext(ConfirmationContext)