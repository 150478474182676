import React, {useState, useEffect} from 'react'
import apiRequests from '../../../../../../../../api/apiRequests'
import {useSisIntegration} from '../../../../SisIntegrationContext'
import RbLoading from '../../../../../../../common/components/RbLoading'
import RecordTable from '../../../../../../../common/components/RecordTable'
import UserSelect from '../../../../../../../common/inputs/UserSelect'
import {bind} from '../../../../../../../../utils/helpers'
import RbText from '../../../../../../../common/components/RbText'
import useUsers from '../../../../../../../../hooks/useUsers'
import useApiRequest from '../../../../../../../../hooks/useApiRequest'

const GradeLevels = () => {
  const {currentConnection, currentSchoolId, gradeLevelHomeroomImportSettings, setGradeLevelHomeroomImportSettings} = useSisIntegration()
  const {teachers} = useUsers({includeTeachers: true})

  const [gradeLevels, setGradeLevels] = useState()

  const handleTeacherSelect = (teacher, grade) => {
    // Create the grade level homeroom mapping. This will be used to map the homeroom teacher to each grade level.
    // It's an array of objects with the grade level and teacher id.
    setGradeLevelHomeroomImportSettings(prev => {
      return [...prev].map(r => {
        if (r.grade === Number(grade)) {
          r.teacherId = teacher?.id
          r.teacher = teacher
        }

        return r
      })
    })
  }

  useApiRequest({
    request: apiRequests.sis.migration.getGradeLevels,
    params: [currentConnection.id, currentSchoolId],
    errorText: 'There was an error loading this school\'s grade levels.',
    sendImmediately: true,
    onResponse: grades => {
      grades.sort((a, b) => Number(a) - Number(b))
      setGradeLevels(grades)
    }
  })

  // Set the initial grade level homeroom mapping when the grade levels are loaded.
  useEffect(() => {
    if (!gradeLevels) {
      return
    }

    const gradeRecords = []

    gradeLevels.forEach(grade => {
      gradeRecords.push({
        grade: grade,
        teacherId: null
      })

      setGradeLevelHomeroomImportSettings(gradeRecords)
    })
  }, [gradeLevels])

  const renderTeacherCell = gradeRecord => {
    const grade = gradeRecord.grade
    const record = gradeLevelHomeroomImportSettings?.find(r => r.grade === grade)

    return <UserSelect
      users={teachers}
      required
      {...bind.recordSelect(record?.teacher, teacher => handleTeacherSelect(teacher, grade))}
    />
  }

  return (
    <>
      {!gradeLevels && <RbLoading />}

      {gradeLevels &&
        <>
          <RbText h5>Grade Levels</RbText>

          <RecordTable
            records={gradeLevels.map(level => ({
              id: level,
              grade: level
            }))}
            columnConfigs={[
              {title: 'Grade', dataKey: 'grade', dataType: 'number'},
              {title: 'Homeroom Teacher (optional for each grade)', renderCell: renderTeacherCell, headerTooltip: 'Grades that are not assigned to teachers will be unaffected'}
            ]}
          />
        </>
      }
    </>
  )
}

export default GradeLevels