import React, {useEffect, useState} from 'react'
import RbModal from '../../common/components/RbModal'
import RbSwitch from '../../common/inputs/RbSwitch'
import {useCurrentUser} from '../../../context/CurrentUserContext'
import {bind} from '../../../utils/helpers'
import useUsers from '../../../hooks/useUsers'
import SaveButtons from '../../common/inputs/SaveButtons'
import useApiRequest from '../../../hooks/useApiRequest'
import apiRequests from '../../../api/apiRequests'
import RbLoading from '../../common/components/RbLoading'
import {toast} from 'react-toastify'

const UserPreferences = ({onClose}) => {
  const {currentUser, setCurrentUser} = useCurrentUser()
  const {updateUser, updateUserIsLoading} = useUsers()

  const [updatedUser, setUpdatedUser] = useState(null)

  const currentSchoolRequest = useApiRequest({
    request: apiRequests.schools.current.get,
    errorText: 'There was an error getting your school information.',
    sendImmediately: true
  })

  const currentSchool = currentSchoolRequest.data
  const duplicationIsEnabled = currentSchool?.scheduleDuplicationEnabled

  const settings = [
    {
      key: 'scheduleDuplicationEnabled',
      label: 'Duplicate Schedule',
      tooltip: duplicationIsEnabled ? 'When enabled, the current week\'s schedule will be duplicated to the next week. This will run once a week at a time designated by a school administrator.' : 'This feature is disabled by your school administrator.',
      disabled: !duplicationIsEnabled,
    }
  ]

  const save = () => {
    updateUser(currentUser.id, updatedUser)
      .then(() => {
        toast.success('Your preferences have been saved.')
        setCurrentUser(updatedUser)
        onClose()
      })
  }

  useEffect(() => setUpdatedUser(currentUser), [currentUser])

  const isReady = updatedUser && currentSchool

  return (
    <RbModal open title="User Preferences" onClose={onClose} minWidth={350}>
      {!isReady && <RbLoading />}

      {isReady &&
        <>
          {settings.map(setting => (
            <RbSwitch
              key={setting.key}
              label={setting.label}
              tooltip={setting.tooltip}
              disabled={setting.disabled}
              justifyContent="space-between"
              // If the setting is disabled, we just pass in a null key, because we don't want to show a "checked" switch when it's disabled, if the value happens to be true
              {...bind.object.switch(updatedUser, setting.disabled ? null : setting.key, setUpdatedUser)}
            />
          )
          )}

          <SaveButtons onSave={save} onCancel={onClose} sx={{mt: 3}} isLoading={updateUserIsLoading} />
        </>
      }
    </RbModal>
  )
}

export default UserPreferences