import {useEffect, useState} from 'react'
import apiRequests from '../api/apiRequests'
import useApiRequest from './useApiRequest'
import {toast} from 'react-toastify'

const useGroups = (includeNonstandardGroups = true) => {
  const [groups, setGroups] = useState()

  const requests = {
    getAllGroups: useApiRequest({
      onBeforeRequest: () => setGroups(null),
      request: apiRequests.groups.getAll,
      onResponse: setGroups,
      errorText: 'There was an error fetching Groups'
    }),
    createGroup: useApiRequest({
      request: apiRequests.groups.create,
      errorText: 'There was an error creating the Group'
    }),
    updateGroup: useApiRequest({
      request: apiRequests.groups.update,
      errorText: 'There was an error updating the Group'
    }),
    deleteGroup: useApiRequest({
      request: apiRequests.groups.delete,
      errorText: 'There was an error deleting the Group'
    })
  }

  const createGroup = group => {
    return requests.createGroup.send(group)
      .then(data => {
        setGroups(prevGroups => [...prevGroups, data])
        toast.success('Group created')

        return data
      })
  }

  const updateGroup = group => {
    return requests.updateGroup.send(group)
      .then(data => {
        setGroups(prevGroups => prevGroups.map(prevGroup => prevGroup.id === group.id ? group : prevGroup))
        toast.success('Group updated')

        return data
      })
  }

  const deleteGroup = group => {
    return requests.deleteGroup.send(group.id)
      .then(data => {
        setGroups(prevGroups => prevGroups.filter(g => g.id !== group.id))
        toast.success('Group deleted')

        return data
      })
  }

  useEffect(() => {
    requests.getAllGroups.send(includeNonstandardGroups)
  }, [])

  return {
    groups,
    setGroups,
    createGroup,
    updateGroup,
    deleteGroup,
    groupApiRequests: requests
  }
}

export default useGroups