import React from 'react'
import Flex from './Flex'
import {CircularProgress} from '@mui/material'
import RebentifyLoadingLogo from '../../../assets/rebentify-loading.gif'

const RbLoading = ({size, sx, color, small, logo, containerSx}) => {
  const parsedSize = small ? 20 : (size ?? 40)
  const padding = small ? 0 : 2

  return (
    <Flex centered sx={{p: padding, ...containerSx}}>
      {logo && <img src={RebentifyLoadingLogo} alt="Loading..." height={`${parsedSize}px`} width={`${parsedSize}px`} />}
      {!logo && <CircularProgress size={parsedSize} sx={{color: color, ...sx}} />}
    </Flex>
  )
}

export default RbLoading