import axios from 'axios'
import {getUserToken} from '../context/CurrentUserContext'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 1000000
})

// Add bearer token
api.interceptors.request.use(config => {
  const token = getUserToken()
  config.headers.Authorization = `Bearer ${token}`
  config.headers['Content-Type'] = 'application/json'

  return config
})

// Track all errors
api.interceptors.response.use(response => response, error => {
  // Whatever you want to do with the error
  throw error
})

export default api