import React, {useState, useEffect} from 'react'
import {bind} from '../../../utils/helpers'
import RbSelect from './RbSelect'
import RbLoading from '../components/RbLoading'

/**
 * Component workflow:
 * - When a record is selected, handleChange is called
 * - handleChange calls onChange with the selected records, passing the selected records to the parent component
 * - The parent component then updates the state of the selected records, which is passed back to this component
 * - This component then updates the selected record ids, which updates the multi-select
 * - This allows the parent component to maintain control of the selected records
 */
const RecordSelect = ({label, labelKey, records, ignoreRecords = [], selectedRecords, onChange, sx, multiple, icon, required, renderOption, isLoading}) => {
  const [allRecords, setAllRecords] = useState([])
  const [currentRecords, setCurrentRecords] = useState([]) // Holds the records that are not ignored
  const [selectedRecordIds, setSelectedRecordIds] = useState(null)

  const handleChange = value => {
    if (multiple) {
      const ids = value
      onChange(currentRecords.filter(record => ids.includes(record.id)))
    } else {
      onChange(currentRecords.find(record => Number(record.id) === Number(value)))
    }
  }

  useEffect(() => {
    setAllRecords(records ?? [])
  }, [records])

  useEffect(() => {
    if (ignoreRecords.length === 0) {
      setCurrentRecords(allRecords)
      return
    }

    const filteredRecords = allRecords.filter(record => !ignoreRecords.find(recordToIgnore => recordToIgnore.id === record.id))
    setCurrentRecords(filteredRecords)
  }, [allRecords, ignoreRecords])

  useEffect(() => {
    if (multiple) {
      setSelectedRecordIds(selectedRecords?.map(record => record.id) ?? [])
    } else {
      const selectedRecord = selectedRecords
      setSelectedRecordIds(selectedRecord?.id)
    }
  }, [selectedRecords])

  const isActuallyLoading = isLoading || !records

  return (
    <>
      {isActuallyLoading && <RbLoading small />}

      {!isActuallyLoading &&
        <RbSelect
          label={label}
          options={currentRecords}
          valueKey="id"
          labelKey={labelKey}
          sx={sx}
          renderOption={renderOption}
          multiple={multiple}
          icon={icon}
          required={required}
          isSearchable
          {...bind.select(selectedRecordIds, handleChange)}
        />
      }
    </>

  )
}

export default RecordSelect