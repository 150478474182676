import React, {useEffect, useState} from 'react'
import apiRequests from '../../api/apiRequests'
import {getRequestType} from './helpers'
import RbText from '../common/components/RbText'
import RbLoading from '../common/components/RbLoading'
import RecordTable from '../common/components/RecordTable'
import moment from 'moment'
import {getStatusTitle} from '../attendance/constants'
import {eCoal} from '../../utils/helpers'
import useApiRequest from '../../hooks/useApiRequest'

const StudentAttendanceLog = ({userId}) => {
  const [attendanceHistory, setAttendanceHistory] = useState(null)
  const [tableData, setTableData] = useState(null)

  const request = useApiRequest({
    request: apiRequests.attendance.getFullAttendanceHistory,
    onResponse: setAttendanceHistory,
    errorText: 'There was an error fetching the attendance history'
  })

  useEffect(() => {
    if (!userId) {
      return
    }

    request.send(userId)
  }, [userId])

  useEffect(() => {
    if (!attendanceHistory?.attendanceByPeriod) {
      return
    }

    const cleanedTableData = []
    attendanceHistory.attendanceByPeriod.forEach(period => {
      const date = moment(period.date)

      const tempObj = {
        locationTeacherName: eCoal(period.locationTeacherName),
        locationSummary: eCoal(period.locationSummary),
        locationRoom: eCoal(period.locationRoom),
        requestType: getRequestType(period.requestType ?? 0, period.priorityNum ?? 0, period.wasAutoScheduled ?? 0),
        statusTitle: getStatusTitle(period.status),
        dateTime: period.date ? date.format('MM/DD/YYYY') + ' (' + date.format('dddd') + ')' : '-',
        priorityTitle: eCoal(period.priorityTitle),
        priorityNum: period.priorityNum,
        teacherNote: eCoal(period.requestNote),
        periodOrdinal: period.periodOrdinal ?? 0,
        periodTitle: eCoal(period.periodTitle),
        id: period.date + ' ' + period.periodOrdinal // Need unique value for table
      }

      cleanedTableData.push(tempObj)
    })

    setTableData(cleanedTableData)
  }, [attendanceHistory])

  return (
    <>
      <RbText h2>Full Attendance History</RbText>
      <RbText h6>(Note: This is their location history and respective attendance statuses)</RbText>

      {!tableData && <RbLoading />}

      {tableData &&
        <>
          {tableData.length === 0 && <RbText h4>No history found</RbText>}

          {tableData.length > 0 &&
            <RecordTable
              records={tableData}
              headerTextNoWrap
              cellTextNoWrap
              isSortable
              defaultSortDirection="desc"
              columnConfigs={[
                {title: 'Date/Time', dataKey: 'dateTime', dataType: 'date'},
                {title: 'Teacher', dataKey: 'locationTeacherName', dataType: 'string'},
                {title: 'Summary', dataKey: 'locationSummary', dataType: 'string'},
                {title: 'Room', dataKey: 'locationRoom', dataType: 'string'},
                {title: 'Request Type', dataKey: 'requestType', dataType: 'string'},
                {title: 'Priority', dataKey: 'priorityTitle', dataType: 'string'},
                {title: 'Status', dataKey: 'statusTitle', dataType: 'string'},
                {title: 'Teacher Note', dataKey: 'teacherNote', dataType: 'string'}
              ]}
            />
          }
        </>
      }
    </>
  )
}

export default StudentAttendanceLog