import {createTheme} from '@mui/material/styles'

const getTableCellStyle = () => ({
  styleOverrides: {
    root: ({ownerState}) => {
      let style = {
        padding: '8px 10px'
      }

      if (ownerState.component === 'th') {
        style = {
          ...style,
          backgroundColor: '#f1f1f1',
          fontWeight: 'bold',
          fontSize: '1.1rem',
        }
      }

      return style
    },
  }
})

// A custom theme for this app
// These colors can be used directly in the sx prop. For example:
// <Box sx={{ color: 'primary.main' }}>
const theme = createTheme({
  palette: {
    primary: {
      main: '#3c4b64',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: '#E64A19',
    },
    warning: {
      main: '#FDD835',
    },
    info: {
      main: '#BDBDBD',
    },
    success: {
      main: '#388E3C',
    },
    cancel: {
      main: '#f5f5f5',
    },
    orange: {
      main: '#f68b1e'
    }
  },
  typography: {
    h1: {
      fontSize: '2.2rem'
    },
    h2: {
      fontSize: '1.75rem'
    },
    h3: {
      fontSize: '1.5rem'
    },
    h4: {
      fontSize: '1.3rem'
    },
    h5: {
      fontSize: '1.1rem'
    },
    h6: {
      fontSize: '.9rem'
    }
  },
  components: {
    MuiTableCell: getTableCellStyle(),
    MuiDivider: {
      styleOverrides: {
        root: {
          marginTop: '.5rem',
          marginBottom: '1rem',
          borderColor: '#00000070'
        }
      }
    }
  },
  getGradeColor: grade => {
    switch (grade) {
      case 7:
        return '#3c4b64'
      case 8:
        return '#19857b'
      case 9:
        return '#f6c90e'
      case 10:
        return '#f68b1e'
      case 11:
        return '#f64e1e'
      default:
        return '#3c4b64'
    }
  }
})

export default theme