import React from 'react'
import {useSisIntegration} from '../SisIntegrationContext'
import SisIntegrationStep from './steps/SisIntegrationStep'
import Flex from '../../../../common/components/Flex'
import {Box, Icon} from '@mui/material'
import {Done} from '@mui/icons-material'
import '../sis.css'

const SisIntegration = () => {
  const {STEPS, currentStep, currentStepIsReady} = useSisIntegration()

  return (
    <>
      {/* The stepper needs to be padded enough away from the edge, or else it causes a weird overflow for the whole page */}
      <Flex alignItems="flex-start" justifyBetween sx={{mb: 5, px: 4, mx: 3}}>
        {STEPS.map((step, index) => {
          const isCurrentStep = currentStep === index
          const isCurrentStepAndIsReady = isCurrentStep && currentStepIsReady
          const stepIsCompleted = currentStep > index || isCurrentStepAndIsReady
          const color = stepIsCompleted ? 'success.main' : 'info.main'
          const stepWidth = 36

          return (
            <React.Fragment key={step.name}>
              <Flex column alignCenter sx={{width: stepWidth}}>
                {/* Icon Logic:
                    - If the step is the current step, show the step's icon
                    - If the step is completed, or is ready, the background is green
                    - If the step is completed, show a checkmark
                    - If the step is a future step, show a blank circle
                  */}
                <Flex centered sx={{borderRadius: '50%', width: stepWidth, height: stepWidth, backgroundColor: color, transition: 'background-color 0.5s ease'}}>
                  {stepIsCompleted && <Done sx={{color: 'white'}} />}
                  {!stepIsCompleted && isCurrentStep && <Icon sx={{color: 'white'}}>{step.icon}</Icon>}
                </Flex>
                <Box position="relative" sx={{width: 150, mt: 2}}>
                  <Box textAlign="center" position="absolute" style={{wordWrap: 'break-word', width: '100%', fontSize: '15px', color: color}}>
                    <span>{step.name}</span>
                  </Box>
                </Box>
              </Flex>

              {index < STEPS.length - 1 &&
                <Box sx={{flexGrow: 1, height: '2px', backgroundColor: color, marginTop: `${stepWidth / 2}px`}} />
              }
            </React.Fragment>
          )
        })}
      </Flex>

      {/* Step Content */}
      <Box>
        {STEPS.map((step, index) => {
          if (currentStep === index) {
            return <SisIntegrationStep key={step.name} step={step} />
          }

          return null
        })}
      </Box>
    </>
  )
}

export default SisIntegration