import {Button, Icon} from '@mui/material'
import React from 'react'
import RbLoading from '../components/RbLoading'
const RbButton = ({variant = 'contained', children, disabled, onClick, onDrop, onDragEnter, onDragOver, onDragLeave, size, color = 'primary', icon, iconStart, iconEnd, sx, type, className, isLoading}) => {
  return (
    <Button
      variant={variant}
      disabled={disabled || isLoading}
      onClick={onClick}
      onDrop={onDrop}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      size={size}
      color={color}
      startIcon={(!iconEnd || iconStart) && icon && <Icon>{icon}</Icon>} // Assume icon should be on the left if iconEnd is false
      endIcon={iconEnd && icon && <Icon>{icon}</Icon>}
      sx={{
        ...sx,
        whiteSpace: 'nowrap',
        minWidth: 'max-content',
        position: 'relative',
      }}
      type={type}
      className={className}
    >
      <span style={{visibility: isLoading ? 'hidden' : 'visible'}}>{children}</span>

      {/* Style loading so that it does not affect the size of the button */}
      <RbLoading
        small
        containerSx={{
          p: 0.25, position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          visibility: isLoading ? 'visible' : 'hidden',
        }}
      />
    </Button>
  )
}
export default RbButton