import React, {useState} from 'react'
import apiRequests from '../../../../api/apiRequests'
import {getDayOfWeekTitle} from '../../../../utils/helpers'
import RbText from '../../../common/components/RbText'
import RecordTable from '../../../common/components/RecordTable'
import Flex from '../../../common/components/Flex'
import RbInfoTooltip from '../../../common/components/RbInfoTooltip'
import useApiRequest from '../../../../hooks/useApiRequest'
import {Divider} from '@mui/material'

const getPeriodTitle = (periodName, dayInt) => {
  const dayTitle = getDayOfWeekTitle(dayInt)

  return periodName === dayTitle ? periodName : (dayTitle + ' ' + periodName)
}

const AttendanceStats = ({weekId}) => {
  const [attendanceInfo, setAttendanceInfo] = useState([])
  const [columnConfigs, setColumnConfigs] = useState(null)
  const [totalStudentsAttenNotTaken, setTotalStudentsAttenNotTaken] = useState(null)
  const [totalStudents, setTotalStudents] = useState(null)// Whole schools students

  const parse = locationInfo => {
    // Assert all items here and validated
    if (!locationInfo?.days?.[0].periods?.[0].teachersPeriodCaps) {
      return
    }

    // Generate headers
    const newColumnConfigs = [{title: 'Teacher', dataKey: 'teacherName'}]
    const periodMap = new Map() // Because of stupid design, we will be mapping everything key: teacherId, val: table ready objs
    locationInfo && locationInfo.days.forEach(day => {
      day.periods.forEach(period => {
        const tempPeriodHeaderDay = getPeriodTitle(period.periodName, day.dayInt)

        newColumnConfigs.push({
          title: 'Not Taken on ' + tempPeriodHeaderDay,
          dataKey: tempPeriodHeaderDay,
          dataType: 'number'
        })

        // Now map data for each teacher to json Obj cleanly for table
        period.teachersPeriodCaps.forEach(sul => {
          let tempObj = periodMap.get(sul.id) ?? {} // Get or make a new
          tempObj = {
            ...tempObj,
            teacherName: sul.name,
            id: sul.id,
            teacherId: sul.id,
            [tempPeriodHeaderDay]: sul.notTakenCt,
            totalNotTaken: sul.notTakenCt + (tempObj.totalNotTaken ?? 0),
            totalInPeriod: sul.allCt + (tempObj.totalInPeriod ?? 0),
          }

          periodMap.set(sul.id, tempObj)
        })
      })
    })

    // Set header for table
    newColumnConfigs.push({title: 'Total Not Taken', dataKey: 'totalNotTaken', dataType: 'number'})
    setColumnConfigs(newColumnConfigs)

    // Set data for table
    const attenInfo = Array.from(periodMap.values())
    setAttendanceInfo(attenInfo)

    // Set total students
    setTotalStudents(locationInfo.totalStudents)

    // Set total students whose attn is not taken
    let totalStudentsWhoseAttenNotTaken = 0
    attenInfo.forEach(atten => {
      totalStudentsWhoseAttenNotTaken += atten.totalNotTaken
    })
    setTotalStudentsAttenNotTaken(totalStudentsWhoseAttenNotTaken)
  }

  useApiRequest({
    request: apiRequests.analytics.getAttendanceStats,
    params: [weekId],
    errorText: 'There was an error getting attendance stats.',
    sendImmediately: true,
    onResponse: parse
  })

  // Plop on table
  return (
    <>
      <Flex alignCenter>
        <RbText h4 noBottomMargin sx={{mr: 1}}>School Attendance Taken Statistics for Week</RbText>
        <RbInfoTooltip title="Note: This page is the amount of teachers not taking attendance" />
      </Flex>

      <RbText h5 sx={{mt: 2}}>Note: The numbers will look funky until the auto-scheduler has ran, this is because not every student will have a location</RbText>

      <Divider />
      <RbText h5>Total Atten. Not Taken: {totalStudentsAttenNotTaken ?? '(Loading)'} | Total Attendance to be Taken: {totalStudents ?? '(Loading)'} | Percent With Atten. NOT Taken: {totalStudentsAttenNotTaken && totalStudents && parseInt((totalStudentsAttenNotTaken / totalStudents) * 100)}%</RbText>

      {columnConfigs &&
        <RecordTable
          records={attendanceInfo}
          columnConfigs={columnConfigs}
          isSortable
          defaultSortKey="totalNotTaken"
          defaultSortDirection="desc"
        />
      }
    </>
  )
}

export default AttendanceStats