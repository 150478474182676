import React, {useState} from 'react'
import {Box, Tab, Tabs} from '@mui/material'
import Flex from './Flex'

const RbTabs = ({tabs, renderHeaderContents, contentMarginTop}) => {
  const [currentTab, setCurrentTab] = useState(0)

  return (
    <>
      <Flex justifyBetween alignCenter>
        <Tabs value={currentTab} onChange={(_, newTab) => setCurrentTab(newTab)}>
          {tabs.map((tab, index) => (
            <Tab key={tab.label} label={tab.label} value={index} sx={{justifySelf: tab.justifySelf}} />
          ))}
        </Tabs>

        {renderHeaderContents && renderHeaderContents(currentTab)}
      </Flex>

      <Box sx={{mt: contentMarginTop ?? 2}}>
        {tabs[currentTab]?.render()}
      </Box>
    </>
  )
}

export default RbTabs