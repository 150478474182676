import {ChevronLeft, ChevronRight} from '@mui/icons-material'
import {IconButton, Tooltip} from '@mui/material'
import {DatePicker} from '@mui/x-date-pickers'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {bind} from '../../../utils/helpers'
import Flex from '../components/Flex'

// Datepicker that has quick navigation buttons to increment/decrement the date by 1 day or 1 week
const RbDatePicker = ({value, onChange, incrementByWeek}) => {
  const [currentDate, setCurrentDate] = useState(value)

  const incrementDate = increment => {
    const newDate = moment(currentDate)
    newDate.add(increment, incrementByWeek ? 'weeks' : 'days')
    setCurrentDate(newDate.toDate())
  }

  useEffect(() => onChange(currentDate), [currentDate]) // Update parent component when date changes
  useEffect(() => setCurrentDate(value), [value]) // Keep datepicker in sync with parent component

  return (
    <Flex alignCenter>
      <Tooltip title={`Previous ${incrementByWeek ? 'Week' : 'Day'}`}>
        <IconButton onClick={() => incrementDate(-1)}><ChevronLeft /></IconButton>
      </Tooltip>

      <DatePicker
        label={incrementByWeek ? 'Week of' : 'Date'}
        slotProps={{textField: {size: 'small'}}}
        // Make the input field shorter
        {...bind.datePicker(currentDate, setCurrentDate)}
      />

      <Tooltip title={`Next  ${incrementByWeek ? 'Week' : 'Day'}`}>
        <IconButton onClick={() => incrementDate(1)}><ChevronRight /></IconButton>
      </Tooltip>
    </Flex>
  )
}

export default RbDatePicker