import React from 'react'
import {Avatar, useTheme} from '@mui/material'
import {bind} from '../../../utils/helpers'
import Flex from '../components/Flex'
import RecordSelect from './RecordSelect'
import RbText from '../components/RbText'

const UserSelect = ({users, label, ignoreUsers, selectedRecords, onChange, sx, multiple, icon, required}) => {
  const {getGradeColor} = useTheme()

  const renderOption = user => {
    return (
      <Flex alignCenter justifyBetween sx={{flexGrow: 1}}>
        <RbText>{user.fullName}</RbText>
        {user.levelInfo?.isStudent && <Avatar sx={{ml: 3, width: 25, height: 25, fontSize: 12, backgroundColor: getGradeColor(user.grade), color: 'white'}}>{user.grade}</Avatar>}
      </Flex>
    )
  }

  return (
    <RecordSelect
      records={users}
      isLoading={!users}
      label={label}
      labelKey="fullName"
      ignoreRecords={ignoreUsers ?? []}
      multiple={multiple}
      sx={sx}
      icon={icon}
      required={required}
      renderOption={renderOption}
      {...bind.recordSelect(selectedRecords, onChange)}
    />
  )
}

export default UserSelect