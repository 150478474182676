import React from 'react'
import RbText from './RbText'
import {Box, Divider} from '@mui/material'
import Flex from './Flex'

const PageTitle = ({title, children, subtitle, extraContent}) => {

  return (
    <>
      <Flex alignItems="flex-end" justifyBetween gap={2}>
        <Box>
          <RbText h2 noBottomMargin>{title ?? children}</RbText>
          {subtitle && <RbText h5 noBottomMargin>{subtitle}</RbText>}
        </Box>

        {extraContent}
      </Flex>
      <Divider />
    </>
  )
}

export default PageTitle