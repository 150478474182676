import apiRequests from '../../../api/apiRequests'
import RecordTable from '../../common/components/RecordTable'
import RbButton from '../../common/inputs/RbButton'
import UserSelect from '../../common/inputs/UserSelect'
import React, {useState, useEffect} from 'react'
import {bind} from '../../../utils/helpers'
import Flex from '../../common/components/Flex'
import {toast} from 'react-toastify'
import {Divider, IconButton} from '@mui/material'
import {Delete} from '@mui/icons-material'
import {useCurrentUser} from '../../../context/CurrentUserContext'
import AddByEmail from './AddByEmail'
import RbText from '../../common/components/RbText'
import useUsers from '../../../hooks/useUsers'
import useApiRequest from '../../../hooks/useApiRequest'

const TabContainer = ({isStudents, group, onStudentChange}) => {
  const {currentUserIsAdminOrAbove} = useCurrentUser()
  const {students, teachers} = useUsers({includeStudents: true, includeTeachers: true})

  const [users, setUsers] = useState(null)
  const [selectedUsers, setSelectedUsers] = useState([])

  const allRequests = {
    members: {
      get: useApiRequest({
        request: apiRequests.groups.members.getByGroupId,
        errorText: 'There was an error fetching the group members'
      }),
      create: useApiRequest({
        request: apiRequests.groups.members.create,
        errorText: 'There was an error adding the group members'
      }),
      createFromEmails: useApiRequest({
        request: apiRequests.groups.members.createFromEmails,
        errorText: 'There was an error adding the group members'
      }),
      delete: useApiRequest({
        request: apiRequests.groups.members.delete,
        errorText: 'There was an error removing the group member'
      })
    },
    editors: {
      get: useApiRequest({
        request: apiRequests.groups.editors.getByGroupId,
        errorText: 'There was an error fetching the group admins'
      }),
      create: useApiRequest({
        request: apiRequests.groups.editors.create,
        errorText: 'There was an error adding the group admins'
      }),
      delete: useApiRequest({
        request: apiRequests.groups.editors.delete,
        errorText: 'There was an error removing the group admin'
      })
    }
  }

  const requests = isStudents ? allRequests.members : allRequests.editors

  useEffect(() => {
    requests.get.send(group.id)
      .then(data => setUsers(data.map(member => member.account)))
  }, [group, isStudents])

  const handleAddMembers = () => {
    const newEditorsDto = []

    selectedUsers.forEach(user => {
      newEditorsDto.push({
        accountId: user.id,
        groupId: group.id
      })
    })

    requests.create.send(newEditorsDto)
      .then(() => {
        toast.success(`${selectedUsers.length} ${isStudents ? 'Students' : 'Admins'} added`)
        setSelectedUsers([])
        setUsers(prevData => [...prevData, ...selectedUsers])
      })
  }

  const handleRemoveMember = member => {
    requests.delete.send(group.id, member.id)
      .then(() => {
        toast.success(`${isStudents ? 'Student' : 'Admin'} removed`)
        setUsers(prevUsers => prevUsers.filter(user => user.id !== member.id))
      })
  }

  const handleAddViaEmail = emails => {
    if (emails?.length <= 0) {
      return
    }

    allRequests.members.createFromEmails.send(group.id, emails)
      .then(data => {
        const addedAccts = data.accountsAdded.map(acct => acct.account)

        // deduplicate any users that already existed
        const newUsers = addedAccts.filter(acct => !users.some(user => user.id === acct.id))
        setUsers(prevUsers => [...prevUsers, ...newUsers])

        toast.success(`${newUsers.length} students added`)

        if (data.accountsNotFound?.length > 0) {
          toast.error('Some emails were not found: ' + data.accountsNotFound?.join(', '))
        }
      })
  }

  const colConfigs = [
    {title: 'First Name', dataKey: 'firstName', isSortable: true, dataType: 'string'},
    {title: 'Last Name', dataKey: 'lastName', isSortable: true, dataType: 'string'}
  ]

  if (isStudents) {
    colConfigs.push({title: 'Grade', dataKey: 'grade', isSortable: true, dataType: 'number'})
  }

  colConfigs.push({renderCell: member => <IconButton onClick={() => handleRemoveMember(member)}><Delete /></IconButton>})

  // Triggers a re-render of the group's student count
  useEffect(() => {
    if (!isStudents || !users) {
      return
    }

    onStudentChange(group, users?.length ?? 0)
  }, [users])

  return (
    <>
      <Flex alignCenter>
        <UserSelect
          label={`Add a${isStudents ? ' Student' : 'n Admin'}`}
          users={isStudents ? students : teachers}
          ignoreUsers={users}
          multiple
          sx={{mr: 2, mb: 2}}
          {...bind.recordSelect(selectedUsers, setSelectedUsers)}
        />

        {selectedUsers?.length > 0 &&
          <RbButton onClick={handleAddMembers}>
            Add
          </RbButton>
        }
      </Flex>

      {users?.length === 0 && <RbText>No {isStudents ? 'students' : 'admins'} in this group</RbText>}

      {users?.length > 0 &&
        <RecordTable
          columnConfigs={colConfigs}
          defaultSortKey="lastName"
          records={users}
        />
      }

      {currentUserIsAdminOrAbove && isStudents &&
        <>
          <Divider sx={{my: 2}} />
          <AddByEmail onChange={handleAddViaEmail} />
        </>
      }
    </>
  )
}

export default TabContainer