import {useState, useEffect, useRef} from 'react'
import {toast} from 'react-toastify'
import {v4 as uuid} from 'uuid'

// This hook was created as a result of the changes to useEffect in React 18.
//
// It consolidates a lot of api request logic, but at certain points it can be a bit confusing to follow, so be careful how you implement.
// More specifically, you can pass onResponse in to the initial configuration, or, you can use .then() after calling .send(). Both accomplish the same thing, but for different use cases.
// If you need to use certain dynamic values after the request, then use a .then(), because you'll have the data available to you.
// If you just need to do basic parsing of the response, onResponse is fine, and keeps the code a bit cleaner.
//
// If the request is simple, you don't even have to manage state in the component. Just use the data state that is returned from this hook.
// You can even manipulate the data via setData() if you need to.
//
// It also handles the quirkiness of React 18's changes to useEffect, so you don't have to worry about that.
//
const useApiRequest = ({
  request, // Request to be sent
  errorText, // Error text that will be displayed in a toast
  successText, // Success text that will be displayed in a toast
  params, // Params to be sent with the request. A lot of times this will be dynamic within the usage, so they can be passed in the send function
  onResponse, // Callback for when the request is successful
  sendImmediately, // Whether or not to send the request immediately on mount
  onError, // Callback for when the request errors
  onBeforeRequest, // Callback for before the request is sent
  throwOnError // *This is helpful for usage with toast.promise()* Whether or not to throw the error. Meaning the implementation of this hook will need to handle the error.
}) => {
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState()
  const currentRequestId = useRef()

  const requestIsStale = requestId => {
    // If the request is not sent immediately, then we don't need to worry about it being stale. This is only to handle the quirkiness of React 18's changes to useEffect.
    // If we are sending requests in a useEffect, then we need to properly account for the useEffect unmounting and remounting. We do that by tracking request IDs. When the component unmounts, the request ID will be different than the current request ID, so we can ignore the response
    if (!sendImmediately) {
      return false
    }

    return requestId !== currentRequestId.current
  }

  const send = (...newParams) => {
    setError(null)
    setData(null)
    setIsLoading(true)

    if (onBeforeRequest) {
      onBeforeRequest()
    }

    const requestId = uuid()
    currentRequestId.current = requestId

    const currentParams = newParams?.length > 0 ? newParams :
      (params?.length > 0) ? params : []

    return new Promise((resolve, reject) => {
      request(...currentParams)
        .then(res => {
          if (requestIsStale(requestId)) {
            return
          }

          // Send response data to various places
          const responseData = res.data

          // Data state
          setData(responseData)

          // On response callback
          if (onResponse) {
            onResponse(responseData)
          }

          // Display success toast
          if (successText) {
            toast.success(successText)
          }

          // Return response data if send is being used as a promise directly by the component
          resolve(responseData)
        })
        .finally(() => {
          if (requestIsStale(requestId)) {
            return
          }

          // Stop loading
          setIsLoading(false)
        })
        .catch(e => {
          if (requestIsStale(requestId)) {
            return
          }

          // Handle errors
          setError(e)

          // Display error toast
          if (errorText) {
            toast.error(errorText)
          }

          // On error callback
          if (onError) {
            onError(e)
          }

          // Throw error if implementation wants to handle it
          if (throwOnError) {
            reject(e)
          }
        })
    })
  }

  useEffect(() => {
    if (sendImmediately) {
      send()
    }

    return () => currentRequestId.current = null
  }, [])

  return {
    data, // Data state. Initially what is returned from the request, but can be manipulated via setData()
    setData, // Allows external manipulation of the data state
    isLoading, // Whether or not the request is loading
    error, // Error state
    send // Function to actually send the request
  }
}

export default useApiRequest