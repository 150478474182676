import React, {useEffect, useState} from 'react'
import RbButton from '../../common/inputs/RbButton'
import RbModal from '../../common/components/RbModal'
import RecordTable from '../../common/components/RecordTable'
import moment from 'moment'
import RbTextField from '../../common/inputs/RbTextField'
import {bind} from '../../../utils/helpers'
import RbText from '../../common/components/RbText'
import {IconButton, Tooltip} from '@mui/material'
import {Block, Home, PersonAdd} from '@mui/icons-material'

const AllLocations = ({locations, currentLocation, dayInfo, onSignUp}) => {
  const [isVisible, setIsVisible] = useState(false)
  const [search, setSearch] = useState('')
  const [filteredLocations, setFilteredLocations] = useState(locations)

  const renderSignUpCell = record => {
    if (record?.locationId === currentLocation?.locationId) {
      return (
        <Tooltip title="Your current location">
          <Home style={{color: 'gray'}} />
        </Tooltip>
      )
    }

    if (record?.isFull) {
      return (
        <Tooltip title="This location is full">
          <Block style={{color: 'gray'}} />
        </Tooltip>
      )
    }
    return (
      <IconButton
        onClick={() => {
          onSignUp(record)
          setIsVisible(false)
        }}
      >
        <PersonAdd />
      </IconButton>
    )
  }

  useEffect(() => {
    if (!search || search === '') {
      setFilteredLocations(locations)
    } else {
      setFilteredLocations(locations.filter(location => {
        const searchableText = `${location.locationSummary} ${location.locationDescription} ${location.teacherName}`

        return searchableText.toLowerCase().includes(search.toLowerCase())
      }))
    }
  }, [search])

  return (
    <>
      <RbButton onClick={() => setIsVisible(true)} size="small" color="secondary">
        View All
      </RbButton>

      {isVisible &&
        <RbModal open
          title={`All Locations (${moment(dayInfo.date).format('ddd MMM DD')})`}
          onClose={() => setIsVisible(false)}
        >
          <RbTextField
            icon="search"
            label="Search"
            sx={{mb: 2}}
            {...bind.input(search, setSearch)}
          >
            Search
          </RbTextField>

          {filteredLocations.length === 0 && <RbText>No locations found</RbText>}

          {filteredLocations.length > 0 &&
            <RecordTable
              records={filteredLocations}
              columnConfigs={[
                {title: 'Summary', dataKey: 'locationSummary', dataType: 'string'},
                {title: 'Description', dataKey: 'locationDescription', dataType: 'string'},
                {title: 'Teacher', dataKey: 'teacherName', dataType: 'string'},
                {title: 'Room', dataKey: 'locationRoom', dataType: 'string'},
                {title: 'Sign Up', textAlign: 'center', renderCell: renderSignUpCell},
              ]}
              isSortable
              isPaginated={false}
            />
          }
        </RbModal>
      }
    </>
  )
}

export default AllLocations