import React, {useState} from 'react'
import {Divider, Grid, Stack} from '@mui/material'
import {Box} from '@mui/material'
import moment from 'moment'
import Flex from '../common/components/Flex'
import RbText from '../common/components/RbText'

const DayCardContainer = ({children, dayInfo, indicatorColor, hoverContents, headerContents, disableHover}) => {
  const [editIsHovered, setEditIsHovered] = useState(false)

  return (
    <Grid item xs={12} md={6} lg>
      <Box
        className="day-card"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: 175,
          border: dayInfo.isToday ? '4px solid #39f' : '',
        }}
      >
        <Box className="day-card-indicator" style={{backgroundColor: indicatorColor}} />

        <Flex justifyBetween alignCenter>
          <RbText h5 noBottomMargin>{moment(dayInfo.date).format('ddd MMM DD')}</RbText>
          {headerContents}
        </Flex>

        <Divider sx={{mb: 1}} />

        <Box
          p={1}
          position="relative"
          onMouseEnter={() => setEditIsHovered(true)}
          onMouseLeave={() => setEditIsHovered(false)}
          onClick={() => setEditIsHovered(false)}
        >
          {editIsHovered && !disableHover &&
            <Stack spacing={2} className="day-card-hover">
              {hoverContents}
            </Stack>
          }

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: 90,
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Grid>
  )
}

export default DayCardContainer