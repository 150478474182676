import React from 'react'
import {useAutomatedTasks} from '../AutomatedTasksContext'
import apiRequests from '../../../../../api/apiRequests'

import DailyConfig from '../partials/DailyConfig'

const ScheduleLock = () => {
  const {scheduleLockConfig, updateScheduleLockConfig} = useAutomatedTasks()

  return (
    <DailyConfig
      config={scheduleLockConfig}
      updateConfig={updateScheduleLockConfig}
      enableKey="scheduleLockEnabled"
      enableLabel="Student Schedule Lock Time"
      dayConfigsKey="dailyLockConfigList"
      dayLabel="Lock On"
      timeLabel="At"
      saveRequest={apiRequests.signUpPeriodConfig.updateScheduleLockConfig}
      configName="Schedule Lock Time"
      saveButtonText="Save Schedule Lock Time Settings"
    />
  )
}
export default ScheduleLock