import React from 'react'
import DailyConfigTable from '../partials/DailyConfigTable'
import {toast} from 'react-toastify'
import RbSwitch from '../../../../common/inputs/RbSwitch'
import {bind} from '../../../../../utils/helpers'
import RbLoading from '../../../../common/components/RbLoading'
import RbButton from '../../../../common/inputs/RbButton'
import {Box, Grid} from '@mui/material'
import Flex from '../../../../common/components/Flex'
import useApiRequest from '../../../../../hooks/useApiRequest'

const DailyConfig = ({config, updateConfig, enableKey, enableLabel, dayConfigsKey, dayLabel, timeLabel, saveRequest, configName, renderHeaderContents, actions, saveButtonText, footerContent, canDisableDaily = true}) => {
  if (!config) {
    return <RbLoading />
  }

  const isEnabled = config[enableKey]
  const dayConfigs = config[dayConfigsKey]

  const request = useApiRequest({
    request: saveRequest,
    throwOnError: true,
  })

  const save = () => {
    toast.promise(request.send(isEnabled ? config : null), // TODO: Update this to a better method of disabling
      {
        pending: 'Saving ' + configName,
        success: 'Successfully updated ' + configName,
        error: 'There was an error when updating the ' + configName
      }
    )
  }

  return (
    <Grid item xs={12} md={6}>
      <Flex justifyBetween alignCenter>
        <RbSwitch
          label={enableLabel}
          {...bind.switch(isEnabled, val => updateConfig(enableKey, val))}
        />

        <Box>{renderHeaderContents && renderHeaderContents()}</Box>
      </Flex>

      {
        isEnabled &&
        <>
          {dayConfigs &&
            <DailyConfigTable
              dayConfigs={dayConfigs}
              onChange={configs => updateConfig(dayConfigsKey, configs)}
              dayLabel={dayLabel}
              timeLabel={timeLabel}
              actions={actions}
              canDisableDaily={canDisableDaily}
            />
          }

          <Flex justifyBetween alignCenter sx={{mt: 2}}>
            <RbButton onClick={save}>
              {saveButtonText}
            </RbButton>
            {footerContent}
          </Flex>
        </>
      }
    </Grid>
  )
}
export default DailyConfig