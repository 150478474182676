
import React, {useState, useEffect} from 'react'
import apiRequests from '../../../../../../../../api/apiRequests'
import {useSisIntegration} from '../../../../SisIntegrationContext'
import RbLoading from '../../../../../../../common/components/RbLoading'
import {Box} from '@mui/material'
import RbSelect from '../../../../../../../common/inputs/RbSelect'
import {bind} from '../../../../../../../../utils/helpers'
import RecordTable from '../../../../../../../common/components/RecordTable'
import RbText from '../../../../../../../common/components/RbText'
import useApiRequest from '../../../../../../../../hooks/useApiRequest'

const SisMethod = () => {
  const {currentConnection, currentSchoolId, setStudentHomeroomCourseSourcedId} = useSisIntegration()
  const [periods, setPeriods] = useState()
  const [selectedPeriod, setSelectedPeriod] = useState()
  const [classes, setClasses] = useState()

  const requests = {
    getPeriods: useApiRequest({
      request: apiRequests.sis.migration.getPeriods,
      params: [currentConnection.id, currentSchoolId],
      onResponse: setPeriods,
      sendImmediately: true,
      errorText: 'There was an error loading the periods for this school.'
    }),
    getClassesForPeriod: useApiRequest({
      request: apiRequests.sis.migration.getClassesForPeriod,
      errorText: 'There was an error loading this classes for this period.'
    })
  }

  // When a period is selected, load the classes for that period.
  useEffect(() => {
    if (!selectedPeriod) {
      return
    }

    requests.getClassesForPeriod.send(currentConnection.id, currentSchoolId, selectedPeriod)
      .then(data => {
        setClasses(data?.map(c => ({
          ...c,
          grades: c.grades?.sort().join(', '),
          subjectCodes: c.subjectCodes?.sort().join(', '),
          periods: c.periods?.sort().join(', '),
          courseSourcedId: c.course?.sourcedId,
          id: c.sourcedId,
        })))
      })
  }, [selectedPeriod])

  // When the classes are loaded, set the student homeroom course sourcedId to the first class sourcedId.
  useEffect(() => {
    let sourcedId = null

    if (classes?.length > 0) {
      sourcedId = classes[0]?.course?.sourcedId
    }

    setStudentHomeroomCourseSourcedId(sourcedId)
  }, [classes])

  return (
    <>
      {!periods && <RbLoading />}

      {periods &&
        <Box sx={{maxWidth: 400, margin: 'auto', mb: 4}}>
          <RbText h5>Period</RbText>
          <RbSelect options={periods} {...bind.select(selectedPeriod, setSelectedPeriod)} />
        </Box>
      }

      {selectedPeriod &&
        <>
          {!classes && <RbLoading />}

          {classes?.length > 0 &&
            <Box sx={{maxWidth: 1000, margin: 'auto'}}>
              <RbText h5>Classes</RbText>
              <RecordTable
                records={classes}
                columnConfigs={[
                  {title: 'Title', dataKey: 'title', dataType: 'string'},
                  {title: 'Class Code', dataKey: 'classCode', dataType: 'string'},
                  {title: 'Grades Enrollment', dataKey: 'grades', dataType: 'string'},
                  {title: 'Subject Codes', dataKey: 'subjectCodes', dataType: 'string'},
                  {title: 'Periods', dataKey: 'periods', dataType: 'string'},
                  {title: 'Course Src ID', dataKey: 'courseSourcedId', dataType: 'string'},
                  {title: 'Class Src ID', dataKey: 'sourcedId', dataType: 'string'},
                ]}
                headerTextNoWrap
              />
            </Box>
          }
        </>
      }
    </>
  )
}

export default SisMethod