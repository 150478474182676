import React, {useEffect, useState} from 'react'
import apiRequests from '../../api/apiRequests'
import RbText from '../common/components/RbText'
import RecordTable from '../common/components/RecordTable'
import RbLoading from '../common/components/RbLoading'
import {getRequestType} from './helpers'
import moment from 'moment'
import Flex from '../common/components/Flex'
import RbDatePicker from '../common/inputs/RbDatePicker'
import {bind, eCoal} from '../../utils/helpers'
import {Box} from '@mui/material'
import useWeek from '../../hooks/useWeek'
import useApiRequest from '../../hooks/useApiRequest'

const getUpdateType = updateInt => {
  switch (updateInt) {
    case 0:
      return 'Insert'
    case 1:
      return 'Update'
    case 2:
      return 'Delete'
    default:
      return '???'
  }
}

const StudentAttendanceLog = ({userId, defaultDate}) => {
  const [attendanceLog, setAttendanceLog] = useState(null)
  const [tableData, setTableData] = useState(null)
  const [selectedDate, setSelectedDate] = useState(new Date())

  const {weekId} = useWeek(selectedDate)

  const request = useApiRequest({
    request: apiRequests.attendance.getAttendanceLog,
    onResponse: setAttendanceLog,
    errorText: 'There was an error fetching the attendance log'
  })

  useEffect(() => {
    setSelectedDate(moment(defaultDate ?? new Date()).toDate())
  }, [defaultDate])

  // Get new week attendance log when week changes
  useEffect(() => {
    if (!weekId || !userId) {
      return
    }

    request.send(weekId, userId)
  }, [weekId])

  // Reload when the day selected (tues => Thurs) changes
  useEffect(() => {
    if (!attendanceLog?.days) {
      return
    }

    const cleanedTableData = []
    attendanceLog.days.forEach(day => {
      if (day.dayNum !== selectedDate?.getDay()) {
        return
      }

      day.periods.forEach(period => {
        period.attendanceLogs.forEach(log => {
          const date = moment(log.tempCreatedAt).subtract(5, 'hours') // TODO: Update this to use actual timezone logic

          const tempLog = {
            id: period.periodId,
            periodName: period.periodName ?? '',
            periodOrdinal: period.periodOrdinal,
            dateTime: date.format('MM/DD/YYYY h:mm:ss A'),
            locationTeacherName: eCoal(log.locationTeacherName),
            locationSummary: eCoal(log.locationSummary),
            locationRoom: eCoal(log.locationRoom),
            requestType: getRequestType(log.attendance ? log.requestType : 0, log.priority ? log.priority.priority ?? 0 : 0, log.wasAutoScheduled),
            priority: eCoal(log.priority?.priority),
            teacherNote: eCoal(log.teacherNote),
            updatedBy: eCoal(log.updater?.fullName),
            fromPage: eCoal(log.fromPage),
            updateType: getUpdateType(log.logType)
          }

          cleanedTableData.push(tempLog)
        })
      })
    })

    setTableData(cleanedTableData)
  }, [selectedDate, attendanceLog])

  return (
    <>
      <Flex alignItems="flex-end" justifyBetween sx={{mb: 1}}>
        <Box>
          <RbText h2>Attendance Log</RbText>
          <RbText h6 noBottomMargin>(Note: This is a step-by-step log of how a student got to the location they&apos;re in.)</RbText>
        </Box>

        <RbDatePicker {...bind.datePicker(selectedDate, setSelectedDate)} />
      </Flex>

      {!tableData && <RbLoading />}

      {tableData &&
        <>
          {tableData.length === 0 && <RbText h5 sx={{mb: 2, pl: 2}} color="error">No attendance log for this day</RbText>}

          {tableData.length > 0 &&
            <RecordTable
              records={tableData}
              headerTextNoWrap
              cellTextNoWrap
              isSortable
              defaultSortDirection="desc"
              columnConfigs={[
                {title: 'Action Taken On', dataKey: 'dateTime', dataType: 'date'},
                {title: 'Location', dataKey: 'locationTeacherName', dataType: 'string'},
                {title: 'Summary', dataKey: 'locationSummary', dataType: 'string'},
                {title: 'Room', dataKey: 'locationRoom', dataType: 'string'},
                {title: 'Request Type', dataKey: 'requestType', dataType: 'string'},
                {title: 'Teacher Note', dataKey: 'teacherNote', dataType: 'string'},
                {title: 'Updated By', dataKey: 'updatedBy', dataType: 'string'},
                {title: 'From Page', dataKey: 'fromPage', dataType: 'string'},
                {title: 'Update Type', dataKey: 'updateType', dataType: 'string'}
              ]}
            />
          }
        </>
      }
    </>
  )
}

export default StudentAttendanceLog