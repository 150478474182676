import React from 'react'
import Autoschedule from './sections/Autoschedule/Autoschedule'
import {AutomatedTasksProvider} from './AutomatedTasksContext'
import AttendanceEmail from './sections/AttendanceEmail'
import ScheduleLock from './sections/ScheduleLock'
import {Grid} from '@mui/material'
import ScheduleDuplication from './sections/ScheduleDuplication'
// import AttendanceReminderEmail from './sections/AttendanceReminderEmail'

const AutomatedTasks = () => {
  return (
    <AutomatedTasksProvider>
      <Grid container spacing={10}>
        <Autoschedule />
        <AttendanceEmail />
        {/* Not presently used because its broken in the backend */}
        {/* <AttendanceReminderEmail /> */}
        <ScheduleLock />
        <ScheduleDuplication />
      </Grid>
    </AutomatedTasksProvider>
  )
}

export default AutomatedTasks