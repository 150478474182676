import React, {useState} from 'react'
import {Close, Delete, Edit, Save} from '@mui/icons-material'
import {Box, IconButton, Tooltip} from '@mui/material'
import apiRequests from '../../../api/apiRequests'
import Flex from '../../common/components/Flex'
import RecordTable from '../../common/components/RecordTable'
import {useEffect} from 'react'
import StatusSelect from './StatusSelect'
import {getStatusTitle, STATUSES} from './../constants'
import RbTextField from '../../common/inputs/RbTextField'
import RbLoading from '../../common/components/RbLoading'
import useApiRequest from '../../../hooks/useApiRequest'
import {toast} from 'react-toastify'
import {useConfirm} from '../../../context/ConfirmationContext'
import RbText from '../../common/components/RbText'

const TabContainer = ({records, isStudents, onDelete}) => {
  const [currentRecords, setCurrentRecords] = useState(records)

  const confirm = useConfirm()

  const columnConfigs = isStudents ? [
    {title: 'First Name', dataKey: 'studentFirstName', isSortable: true, dataType: 'string'},
    {title: 'Last Name', dataKey: 'studentLastName', isSortable: true, dataType: 'string'},
    {title: 'Grade', dataKey: 'studentGrade', isSortable: true, dataType: 'number', width: 100},
  ] : [
    {title: 'Name', dataKey: 'groupName', isSortable: true, dataType: 'string'},
  ]

  const requests = {
    update: useApiRequest({
      request: apiRequests.partitionList.update,
      errorText: 'There was an error updating this record.',
      onResponse: () => toast.success('Record updated successfully.'),
    }),
    delete: useApiRequest({
      request: apiRequests.partitionList.delete,
      errorText: 'There was an error deleting this record.',
      onResponse: () => toast.success('Record deleted successfully.'),
    }),
  }

  const deleteRecord = record => {
    const recordInfo = isStudents ? `${record.studentFirstName} ${record.studentLastName}` : record.groupName
    confirm({
      title: 'Delete Record',
      description: <RbText>Are you sure you want to remove <b>{recordInfo}</b> from your <b>{getStatusTitle(record.type)}</b> list?</RbText>,
    })
      .then(() => requests.delete.send(record.id))
      .then(() => onDelete(record))
  }

  const updateRecord = (record, dataKey, value) => {
    setCurrentRecords(prev => {
      const currentRec = prev.find(r => r.id === record.id)
      if (typeof dataKey === 'object') {
        const infoToUpdate = dataKey // Contains an object with key value pairs to update
        Object.keys(infoToUpdate).forEach(key => currentRec[key] = infoToUpdate[key])
      } else {
        currentRec[dataKey] = value
      }

      return [...prev]
    })
  }

  const saveRecord = record => {
    const reason = record.reason ?? ''
    if (record.type === STATUSES.BLOCKED && reason === '') {
      alert('Blocked records must have a reason.')
      return
    }

    requests.update.send(record.id, record)
      .then(() => updateRecord(record, 'isEditing', false))
  }

  const renderActionCell = record => {
    return (
      <Flex alignCenter>
        {record.isEditing &&
          <>
            <Tooltip title="Save">
              <IconButton
                onClick={() => saveRecord(record)}
                mr={3}
              >
                <Save color="success" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Cancel">
              <IconButton onClick={() => {
                updateRecord(record, {
                  type: record.prevStatus,
                  reason: record.prevReason,
                  isEditing: false
                })
              }}
              >
                <Close color="error" />
              </IconButton>
            </Tooltip>
          </>
        }

        {!record.isEditing &&
          <>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  updateRecord(record, {
                    prevStatus: record.type,
                    prevReason: record.reason,
                    isEditing: true
                  })
                }}
                mr={3}
              >
                <Edit color="orange" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete">
              <IconButton onClick={() => deleteRecord(record)}>
                <Delete color="error" />
              </IconButton>
            </Tooltip>
          </>
        }
      </Flex>
    )
  }

  const renderStatusCell = record => {
    if (record.isEditing) {
      return (
        <StatusSelect
          value={record.type}
          onChange={val => updateRecord(record, 'type', val)}
        />
      )
    }

    return getStatusTitle(record.type)
  }

  const renderReasonCell = record => {
    if (record.isEditing) {
      return (
        <RbTextField
          multiline
          value={record.reason}
          onChange={val => updateRecord(record, 'reason', val)}
        />
      )
    }

    return record.reason
  }

  useEffect(() => {
    const mappedRecords = records?.map(record => ({...record, isEditing: false}))
    setCurrentRecords(mappedRecords ?? [])
  }, [records])

  return (
    <Box>
      {!currentRecords && <RbLoading />}

      {currentRecords &&
        <RecordTable
          key={isStudents ? 'students' : 'groups'} // This is needed to force a re-render when switching between students and groups
          columnConfigs={[
            ...columnConfigs,
            {title: 'Status', renderCell: renderStatusCell, isSortable: true, dataType: 'string'},
            {title: 'Reason', renderCell: renderReasonCell, isSortable: true, dataType: 'string'},
            {title: 'Actions', renderCell: renderActionCell, width: 100, textAlign: 'center', dataType: 'string'}
          ]}
          records={currentRecords}
          defaultSortKey={isStudents ? 'studentLastName' : 'groupName'}
          height={800}
        />
      }
    </Box>
  )
}

export default TabContainer