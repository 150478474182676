import React from 'react'
import {Tooltip} from '@mui/material'

// Weird issue when using Tooltip with a custom component as the child
// https://github.com/mui/material-ui/issues/31261
const WorkaroundTooltip = ({children, ...props}) =>
  <Tooltip {...props}>
    <span>{children}</span>
  </Tooltip>

export default WorkaroundTooltip