import React from 'react'
import {useState} from 'react'
import {toast} from 'react-toastify'
import apiRequests from '../../../api/apiRequests'
import Flex from '../../common/components/Flex'
import RbModal from '../../common/components/RbModal'
import RecordTable from '../../common/components/RecordTable'
import GroupSelect from '../../common/inputs/GroupSelect'
import RbButton from '../../common/inputs/RbButton'
import RbSelect from '../../common/inputs/RbSelect'
import RbTextField from '../../common/inputs/RbTextField'
import UserSelect from '../../common/inputs/UserSelect'
import {bind} from '../../../utils/helpers'
import {STATUSES, STATUS_SELECT_OPTIONS} from '../constants'
import useUsers from '../../../hooks/useUsers'
import useGroups from '../../../hooks/useGroups'
import useApiRequest from '../../../hooks/useApiRequest'

const AddRecords = ({type, onClose, studentsToIgnore, groupsToIgnore, userIdBeingViewed, onNewRecords}) => {
  const isStudents = type === 'Students'

  const {students} = useUsers({includeStudents: true})
  const {groups} = useGroups(false)

  const [selectedRecords, setSelectedRecords] = useState([])
  const [isSaving, setIsSaving] = useState(false)

  const columnConfigs = isStudents ? [
    {title: 'First Name', dataKey: 'firstName', isSortable: true, dataType: 'string'},
    {title: 'Last Name', dataKey: 'lastName', isSortable: true, dataType: 'string'},
    {title: 'Grade', dataKey: 'grade', isSortable: true, dataType: 'number'},
  ] : [
    {title: 'Name', dataKey: 'name', isSortable: true, dataType: 'string'},
  ]

  const createPartitionListRequest = useApiRequest({
    request: apiRequests.partitionList.create,
    throwOnError: true,
  })

  const submit = e => {
    e.preventDefault()

    if (selectedRecords.length === 0) {
      return
    }

    for (const record of selectedRecords) {
      const reason = record.reason ?? ''
      const recordType = record.type ?? null

      if (recordType === null) {
        alert('One or more records are missing a status')
        return
      } else if (recordType === STATUSES.BLOCKED && reason === '') {
        alert('Blocked records must have a reason')
        return
      }
    }

    setIsSaving(true)

    const promises = []

    selectedRecords.forEach(record => {
      const request = {
        accountId: userIdBeingViewed,
        type: record.type,
        reason: record.reason
      }

      if (isStudents) {
        request.studentId = record.id
      } else {
        request.groupId = record.id
      }

      promises.push(createPartitionListRequest.send(request)
        .then(() => setSelectedRecords(prev => prev.filter(r => r.id !== record.id)))
      )
    })

    Promise.allSettled(promises)
      .then(res => {
        if (res.some(r => r.status === 'rejected')) {
          toast.error('There was an error saving one or more records')

          if (res.some(r => r.status === 'fulfilled')) {
            toast.info('Some records were saved successfully and have been removed from the list')
          }
        } else {
          toast.success('Records saved successfully')
          onNewRecords()
          onClose()
        }
      })
      .finally(() => setIsSaving(false))
  }

  const updateRecord = (record, dataKey, value) => {
    setSelectedRecords(prev => {
      const currentRec = prev.find(r => r.id === record.id)
      currentRec[dataKey] = value
      return [...prev]
    })
  }

  const renderStatusCell = record => {
    return (
      <RbSelect
        options={STATUS_SELECT_OPTIONS}
        valueKey="value"
        labelKey="label"
        value={record.type}
        onChange={val => updateRecord(record, 'type', val)}
      />
    )
  }

  const renderReasonCell = record => {
    return (
      <RbTextField
        multiline
        value={record.reason}
        onChange={val => updateRecord(record, 'reason', val)}
        sx={{
          width: 350,
          border: record.type === STATUSES.BLOCKED && (record.reason ?? '') === '' ? '1px solid red' : '',
        }}
      />
    )
  }

  // We need to add the extra properties to the records, or else React is not happy, throwing "A component is changing an uncontrolled input of type text to be controlled"
  const handleSelect = records => {
    setSelectedRecords(records.map(r => ({
      ...r,
      reason: r.reason ?? '',
      type: r.type ?? null,
    })))
  }

  return (
    <RbModal
      title={`Add ${type}`}
      open
      onClose={() => onClose()}
      minWidth={600}
    >
      <form onSubmit={submit}>
        {isStudents &&
          <UserSelect
            multiple
            users={students}
            label="Select Students"
            ignoreUsers={studentsToIgnore}
            {...bind.recordSelect(selectedRecords, handleSelect)}
          />
        }

        {!isStudents &&
          <GroupSelect
            multiple
            label="Select Groups"
            groups={groups}
            ignoreGroups={groupsToIgnore}
            {...bind.recordSelect(selectedRecords, handleSelect)}
          />
        }

        {selectedRecords.length > 0 &&
          <>
            <RecordTable
              columnConfigs={[
                ...columnConfigs,
                {title: 'Status', renderCell: renderStatusCell},
                {title: 'Reason', renderCell: renderReasonCell},
              ]}
              records={selectedRecords}
              defaultSortKey={isStudents ? 'lastName' : 'name'}
              height={800}
              sx={{mt: 2}}
            />

            <Flex justifyContent="flex-end">
              <RbButton type="submit" color="primary" sx={{mt: 2}} disabled={isSaving} isLoading={isSaving}>Submit</RbButton>
            </Flex>
          </>
        }
      </form>
    </RbModal>
  )
}

export default AddRecords