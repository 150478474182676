import React, {useMemo, useState} from 'react'
import apiRequests from '../../../../api/apiRequests'
import RbLoading from '../../../common/components/RbLoading'
import {getDayOfWeekTitle} from '../../../../utils/helpers'
import useApiRequest from '../../../../hooks/useApiRequest'

const AutomatedTasksContext = React.createContext()

const AutomatedTasksProvider = ({children}) => {
  const [schoolDays, setSchoolDays] = useState([])
  const [autoscheduleConfig, setAutoscheduleConfig] = useState()
  const [attendanceEmailConfig, setAttendanceEmailConfig] = useState()
  const [scheduleLockConfig, setScheduleLockConfig] = useState()
  const [attendanceReminderEmailConfig, setAttendanceReminderEmailConfig] = useState()
  const [scheduleDuplicationTimeConfig, setScheduleDuplicationTimeConfig] = useState()

  const getDayIntForPeriod = periodId => {
    const day = schoolDays.find(d => d.periodId === periodId)
    return day?.dayInt
  }

  const getDayTitleForPeriod = periodId => getDayOfWeekTitle(getDayIntForPeriod(periodId))

  const updateConfig = (setter, key, value) => setter(prev => ({...prev, [key]: value}))

  const request = useApiRequest({
    request: apiRequests.signUpPeriodConfig.getAutomatedTasksConfig,
    sendImmediately: true,
    errorText: 'There was an error getting the automated tasks configuration.',
    onResponse: config => {
      setSchoolDays(config.schoolDayList)

      config.autoscheduleForHomeroomTimeRequest.dailyAutoscheduleEnabled = true // Always enabled for now
      setAutoscheduleConfig(config.autoscheduleForHomeroomTimeRequest)

      setAttendanceEmailConfig(config.setAttendanceEmailTimeRequest)

      config.updateScheduleLockTimeRequest.dailyLockEnabled = true // Always enabled for now
      setScheduleLockConfig(config.updateScheduleLockTimeRequest)

      setAttendanceReminderEmailConfig(config.setAttendanceEmailRemindTimeRequest)

      setScheduleDuplicationTimeConfig(config.scheduleDuplicationTimeConfig)
    }
  })

  const contextValue = useMemo(() => ({
    getDayTitleForPeriod,
    getDayIntForPeriod,
    autoscheduleConfig,
    updateAutoscheduleConfig: (key, value) => updateConfig(setAutoscheduleConfig, key, value),
    attendanceEmailConfig,
    updateAttendanceEmailConfig: (key, value) => updateConfig(setAttendanceEmailConfig, key, value),
    scheduleLockConfig,
    updateScheduleLockConfig: (key, value) => updateConfig(setScheduleLockConfig, key, value),
    attendanceReminderEmailConfig,
    updateAttendanceReminderEmailConfig: (key, value) => updateConfig(setAttendanceReminderEmailConfig, key, value),
    scheduleDuplicationTimeConfig: scheduleDuplicationTimeConfig,
    updateScheduleDuplicationConfig: (key, value) => updateConfig(setScheduleDuplicationTimeConfig, key, value),
  }), [schoolDays, autoscheduleConfig, attendanceEmailConfig, scheduleLockConfig, attendanceReminderEmailConfig])

  if (request.isLoading) {
    return <RbLoading />
  }

  return (
    <AutomatedTasksContext.Provider value={contextValue}>
      {children}
    </AutomatedTasksContext.Provider>
  )
}

const useAutomatedTasks = () => {
  const context = React.useContext(AutomatedTasksContext)
  if (context === undefined) {
    throw new Error('useAutomatedTasks must be used within a AutomatedTasksProvider')
  }
  return context
}

export {AutomatedTasksProvider, useAutomatedTasks}