import React from 'react'
import SeatsFilled from './tabs/SeatsFilled'
import AttendanceStats from './tabs/AttendanceStats'
import RequestPriorities from './tabs/RequestPriorities'
import RbTabs from '../../common/components/RbTabs'
import RbLoading from '../../common/components/RbLoading'
import useWeek from '../../../hooks/useWeek'
import PageTitle from '../../common/components/PageTitle'
import StudentsWithoutHomerooms from './tabs/StudentsWithoutHomerooms'

const AnalyticTables = () => {
  // Set the title in the browser's tab
  document.title = 'Rebentify - Analytic Tables'

  const {weekId} = useWeek()

  return (
    <>
      <PageTitle
        title="Site Analytics Table"
        subtitle={<>Hello! Please suggest new tables and data that will help in weekly administration of your site! <br /> Note: Only admins+ can see this page</>}
      />

      {!weekId && <RbLoading />}

      {weekId &&
        <RbTabs
          tabs={[
            {label: 'Attendance Stats', render: () => <AttendanceStats weekId={weekId} />},
            {label: 'Seats Filled', render: () => <SeatsFilled weekId={weekId} />},
            {label: 'Request Priorities For Year', render: () => <RequestPriorities />},
            {label: 'Students Without Homerooms', render: () => <StudentsWithoutHomerooms />},
          ]}
        />
      }
    </>
  )
}

export default AnalyticTables