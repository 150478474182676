import React from 'react'
import {IMPORT_TYPES, useSisIntegration} from '../../../SisIntegrationContext'
import {useConfirm} from '../../../../../../../context/ConfirmationContext'
import SisMethod from './ImportTypes/SisMethod'
import GradeLevels from './ImportTypes/GradeLevels'
import {Box} from '@mui/material'
import Flex from '../../../../../../common/components/Flex'
import RbButton from '../../../../../../common/inputs/RbButton'
import RbText from '../../../../../../common/components/RbText'

const ImportType = () => {
  const {importType, setImportType, studentHomeroomAssignmentMethod, setStudentHomeroomAssignmentMethod} = useSisIntegration()
  const confirm = useConfirm()

  const handleImportTypeChange = type => {
    setImportType(type)

    if (type === 'Student') {
      confirm({title: 'Have you already imported teachers?', description: 'Teachers MUST be imported first to avoid data corruption.', confirmButtonText: 'Yes, I have imported teachers', cancelButtonText: 'No'})
        .catch(() => setImportType(null))
    }
  }

  return (
    <>
      <Box sx={{maxWidth: 400, margin: 'auto'}}>
        <RbText h5 sx={{mt: 4}}>Import Type</RbText>

        <Flex justifyCenter gap={1}>
          {Object.keys(IMPORT_TYPES).map(key => {
            const type = IMPORT_TYPES[key]

            return (
              <RbButton sx={{flexGrow: 1}} color={importType === type ? 'primary' : 'cancel'} key={type} onClick={() => handleImportTypeChange(type)}>
                {type}
              </RbButton>
            )
          })}
        </Flex>

        {importType === IMPORT_TYPES.STUDENT &&
          <>
            <RbText h5 sx={{mt: 4}}>Homeroom Assignment Method</RbText>
            <Flex justifyCenter gap={1} sx={{mb: 4}}>
              {['None', 'Grade Level', 'SIS'].map(style => (
                <RbButton sx={{flexGrow: 1}} color={studentHomeroomAssignmentMethod === style ? 'primary' : 'cancel'} key={style} onClick={() => setStudentHomeroomAssignmentMethod(style)}>
                  {style}
                </RbButton>
              ))}
            </Flex>

            {/* Grade levels method */}
            {studentHomeroomAssignmentMethod === 'Grade Level' && <GradeLevels />}
          </>
        }
      </Box>

      {/* SIS Method. This is separate from the other layouts so we aren't limiting the width */}
      {importType === IMPORT_TYPES.STUDENT && studentHomeroomAssignmentMethod === 'SIS' && <SisMethod />}
    </>
  )
}

export default ImportType