import React from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import Login from './components/auth/Login'
import Groups from './components/groups/Groups'
import SearchUser from './components/search/SearchUser'
import Attendance from './components/attendance/Attendance'
import BlockedApproved from './components/blocked-approved/BlockedApproved'
import AdminDashboard from './components/admin-dashboard/AdminDashboard'
import Statistics from './components/statistics/Statistics'
import Tutorials from './components/tutorials/Tutorials'
import AnalyticTables from './components/analytics/tables/AnalyticTables'
import AnalyticGraphs from './components/analytics/graphs/AnalyticGraphs'
import {useCurrentUser} from './context/CurrentUserContext'
import TermsOfService from './components/terms-of-service/TermsOfService'
import ValidateMissingInformationForm from './components/missing-info/ValidateMissingInformationForm'
import {AttendanceProvider} from './components/attendance/AttendanceContext'
import ScheduleRoot from './components/schedule/ScheduleRoot'
import RbLoading from './components/common/components/RbLoading'
import LogOut from './components/auth/LogOut'

import {useNavigate} from 'react-router'
import RbText from './components/common/components/RbText'
import RbButton from './components/common/inputs/RbButton'

const NotFound = () => {
  const navigate = useNavigate()

  return (
    <>
      <RbText h2>Page Not Found</RbText>
      <RbText sx={{mb: 1}}>The page may have moved or you may have mistyped the address.</RbText>
      <RbButton onClick={() => navigate('/')}>Back to Home</RbButton>
    </>
  )
}

export default function RbRoutes() {
  const {currentUser, currentUserIsTeacherOrAbove, currentUserIsStudent, currentUserIsLoading, userToken, currentUserAcceptedTos, missingUserInfo} = useCurrentUser()

  if (!userToken) {
    return (
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    )
  }

  if (currentUser) {
    if (!currentUserAcceptedTos) {
      return <TermsOfService />
    }

    if (missingUserInfo.length > 0) {
      return <ValidateMissingInformationForm />
    }
  }

  if (currentUserIsLoading || !currentUser) {
    return <RbLoading sx={{mt: 5}} />
  }

  return (
    <Routes>
      {currentUser &&
        <>
          <Route path="/logout" element={<LogOut />} />
          <Route path="/login" element={<Navigate to="/" />} />

          {currentUserIsStudent &&
            <>
              <Route exact path="/" element={<Navigate to="/schedule" />} />
              <Route path="/schedule" element={<ScheduleRoot />} />
              <Route path="*" element={<NotFound />} />
            </>
          }

          {currentUserIsTeacherOrAbove &&
            <>
              <Route exact path="/" element={<Navigate to="/schedule" />} />
              <Route path="/schedule/:id?" element={<ScheduleRoot />} />
              <Route
                path="/attendance/:id?"
                element={(
                  <AttendanceProvider>
                    <Attendance />
                  </AttendanceProvider>
                )}
              />
              <Route path="/blocked-approved-list/:id?" element={<BlockedApproved />} />
              <Route path="/groups" element={<Groups {...{currentUser}} />} />
              <Route path="/search" element={<SearchUser />} />
              <Route path="/admin-dashboard" element={<AdminDashboard />} />
              <Route path="/site-analytic-graphs" element={<AnalyticGraphs />} />
              <Route path="/site-analytic-tables" element={<AnalyticTables />} />
              <Route path="/statistics/:id?" element={<Statistics />} />
              <Route path="/tutorials" element={<Tutorials />} />
              <Route path="*" element={<NotFound />} />
            </>
          }
        </>
      }
    </Routes>
  )
}