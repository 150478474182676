import {Stack} from '@mui/material'
import RbModal from '../../common/components/RbModal'
import RbSwitch from '../../common/inputs/RbSwitch'
import RbTextField from '../../common/inputs/RbTextField'
import {bind} from '../../../utils/helpers'
import React, {useEffect, useState} from 'react'
import SaveButtons from '../../common/inputs/SaveButtons'

const GroupInfoModal = ({title, group, onClose, onSave, isSaving}) => {
  const [groupToEdit, setGroupToEdit] = useState(group)

  const handleSave = e => {
    e.preventDefault()
    onSave(groupToEdit)
  }

  useEffect(() => setGroupToEdit(group), [group])

  return (
    <RbModal
      open
      onClose={onClose}
      title={title}
      minWidth={350}
    >
      <form onSubmit={handleSave}>
        <Stack spacing={2}>
          <RbTextField
            label="Group Name"
            autoFocus
            required
            {...bind.object.input(groupToEdit, 'name', setGroupToEdit)}
          />

          <RbTextField
            label="Description"
            multiline
            required
            {...bind.object.input(groupToEdit, 'description', setGroupToEdit)}
          />
          <RbSwitch
            label="Private"
            {...bind.object.switch(groupToEdit, 'private', setGroupToEdit)}
          />

          <SaveButtons isLoading={isSaving} onCancel={onClose} />
        </Stack>
      </form>
    </RbModal>
  )
}

export default GroupInfoModal