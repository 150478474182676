import React, {useState} from 'react'
import {Box, Icon} from '@mui/material'
import {Link} from 'react-router-dom'
import Flex from '../common/components/Flex'
import RbText from '../common/components/RbText'

const Button = ({link, drawerIsOpen}) => (
  <Flex sx={{color: 'white', py: 1.5}} alignCenter className="sidebar-item">
    <Icon sx={{ml: 3, mr: 1, flexGrow: 1, maxWidth: 24}}>{link.icon}</Icon>
    {drawerIsOpen && <RbText noWrap sx={{fontSize: '0.9rem'}}>{link.title}</RbText>}
  </Flex>
)

const ButtonLink = ({link, drawerIsOpen}) => (
  <Link to={link.route} style={{textDecoration: 'none'}}>
    <Button link={link} drawerIsOpen={drawerIsOpen} />
  </Link>
)

const SidebarLink = ({link, drawerIsOpen}) => {
  const [dropdownIsVisible, setDropdownIsVisible] = useState(false)

  return (
    <>
      {!link.dropdown && <ButtonLink link={link} drawerIsOpen={drawerIsOpen} />}

      {link.dropdown &&
        <>
          {drawerIsOpen &&
            <Flex justifyBetween alignCenter sx={{cursor: 'pointer'}} onClick={() => setDropdownIsVisible(prev => !prev)} className="sidebar-item">
              <Button link={link} drawerIsOpen={drawerIsOpen} />
              {drawerIsOpen && <Icon sx={{color: 'white', cursor: 'pointer', mr: 2}}>{dropdownIsVisible ? 'expand_less' : 'expand_more'}</Icon>}
            </Flex>
          }

          {(dropdownIsVisible || !drawerIsOpen) &&
            <Box sx={drawerIsOpen ? {ml: 4, pl: 0, borderLeft: '1px solid white'} : {}}>
              {link.dropdown.map(dropdownLink => <ButtonLink key={dropdownLink.route} link={dropdownLink} drawerIsOpen={drawerIsOpen} />)}
            </Box>
          }
        </>
      }
    </>
  )
}

export default SidebarLink