import React, {useState} from 'react'
import RecordTable from '../common/components/RecordTable'
import RbMenu from '../common/components/RbMenu'
import {useCurrentUser} from '../../context/CurrentUserContext'
import UserForm from '../admin-dashboard/tabs/UserForm'
import {toast} from 'react-toastify'
import RbModal from '../common/components/RbModal'
import RbRecordSearch from '../common/components/RbRecordSearch'
import {useConfirm} from '../../context/ConfirmationContext'
import EditHomeroom from '../main-layout/user-dropdown/EditHomeroom'
import RbSwitch from '../common/inputs/RbSwitch'
import RbText from '../common/components/RbText'
import {bind} from '../../utils/helpers'
import RbLoading from '../common/components/RbLoading'
import useUsers from '../../hooks/useUsers'
import PageTitle from '../common/components/PageTitle'

const SearchUser = () => {
  document.title = 'Rebentify - Search'

  const [filteredUsers, setFilteredUsers] = useState([])
  const [userBeingEdited, setUserBeingEdited] = useState(null)
  const [editUserModalIsVisible, setEditUserModalIsVisible] = useState(false)
  const [editHomeroomModalIsVisible, setEditHomeroomModalIsVisible] = useState(false)
  const [emailColIsVisible, setEmailColIsVisible] = useState(false)

  const confirm = useConfirm()
  const {currentUserIsAdminOrAbove} = useCurrentUser()
  const {allUsers, updateUser, updateUserIsLoading, deleteUser} = useUsers({includeAllUsers: true})

  const handleMenuRender = user => {
    const renderedUserIsTeacherOrAbove = user.levelInfo.isTeacherOrAbove
    const renderedUserIsStudent = user.levelInfo.isStudent

    const options = [{label: 'Edit/View Schedule', link: u => `./schedule/${u.id}`},]

    if (renderedUserIsTeacherOrAbove) {
      options.push({label: 'Take Attendance', link: u => `./attendance/${u.id}`})
    }

    if (renderedUserIsStudent) {
      options.push({label: 'View Statistics', link: u => `./statistics/${u.id}`})
    }

    if (currentUserIsAdminOrAbove) {
      options.push({isDivider: true})

      if (renderedUserIsTeacherOrAbove) {
        options.push({label: 'View Block/Approve List', link: u => `/blocked-approved-list/${u.id}`})
      }

      options.push({
        label: 'Edit User Info', onClick: u => {
          setUserBeingEdited(u)
          setEditUserModalIsVisible(true)
        }
      })

      if (renderedUserIsTeacherOrAbove) {
        options.push({
          label: 'Edit Homeroom', onClick: u => {
            setUserBeingEdited(u)
            setEditHomeroomModalIsVisible(true)
          }
        })
      }

      options.push({
        label: 'Deactivate User', onClick: u => {
          confirm({
            title: `Are you sure you want to de-activate ${u?.fullName ?? 'this user'}?`,
            description: 'The user can be reactivated via the admin dashboard.',
            confirmButtonText: 'Yes, deactivate user'
          })
            .then(() => {
              deleteUser(u.id)
                .then(() => {
                  toast.success(`${u.firstName ?? 'User'} deactivated`)
                })
            })
        }
      })
    }

    return <RbMenu labelIcon="settings" options={options} value={user} />
  }

  const handleEditUserSubmit = (u, onSuccess) => {
    updateUser(u.id, u)
      .then(() => {
        setEditUserModalIsVisible(false)
        setUserBeingEdited(null)
        onSuccess()
        toast.success(`${u.firstName ?? 'User'} updated`)
      })
  }

  const handleUserFilter = users => {
    setFilteredUsers(users?.map(user => ({
      ...user,
      homeroomTeacherName: user.homeroomAccountId ? allUsers?.find(teacher => teacher.id === user.homeroomAccountId)?.fullName : null
    })))
  }

  const colConfigs = () => {
    let configs = [
      {title: 'First Name', dataKey: 'firstName', dataType: 'string'},
      {title: 'Last Name', dataKey: 'lastName', dataType: 'string'},
      {title: 'Email', dataKey: 'email', dataType: 'string'},
      {title: 'Grade', dataKey: 'grade', dataType: 'number', eCoal: true},
      {title: 'Homeroom', dataKey: 'homeroomTeacherName', dataType: 'string', eCoal: true},
      {title: 'User Level', dataKey: 'levelName', dataType: 'string'},
      {title: 'Options', renderCell: user => handleMenuRender(user), textAlign: 'center', width: 100}
    ]

    if (!emailColIsVisible) {
      configs = configs.filter(config => config.dataKey !== 'email')
    }

    return configs
  }

  return (
    <>
      <PageTitle
        title="Search"
        extraContent={(
          <RbSwitch
            label="Include Email"
            {...bind.switch(emailColIsVisible, setEmailColIsVisible)}
          />
        )}
      />

      <RbRecordSearch
        searchKeys={['fullName', 'email']}
        {...bind.recordSearch(allUsers, handleUserFilter)}
      />

      {!filteredUsers && <RbLoading />}

      {filteredUsers &&
        <>
          {filteredUsers.length === 0 && <RbText h4 sx={{mt: 2}}>No users found!</RbText>}

          {filteredUsers.length > 0 &&
            <RecordTable
              columnConfigs={colConfigs()}
              records={filteredUsers ?? []}
              isSortable
              defaultSortKey="lastName"
              sx={{mt: 3}}
            />
          }
        </>
      }

      {editUserModalIsVisible &&
        <RbModal open title="Edit User" onClose={() => setEditUserModalIsVisible(false)}>
          <UserForm
            submitButtonLabel="Edit User"
            user={userBeingEdited}
            onSubmit={handleEditUserSubmit}
            isLoading={updateUserIsLoading}
          />
        </RbModal>
      }

      {editHomeroomModalIsVisible &&
        <EditHomeroom onClose={() => setEditHomeroomModalIsVisible(false)} user={userBeingEdited} />
      }
    </>
  )
}

export default SearchUser