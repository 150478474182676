import React from 'react'

// For some reason we are only able to implement this with a class component. So we just use this as a shell, and actually implement in the other error boundary files.
/* eslint-disable react/destructuring-assignment*/
/* eslint-disable react/no-unused-state */
class ErrorBoundaryCore extends React.Component {
  constructor(props) {
    super(props)
    // We have to declare this state, and set it in componentDidCatch, or else react is not happy. We do not actually use this state.
    this.state = {error: ''}
  }

  componentDidCatch(error, reactInfo) {
    this.setState({error: true})
    this.props.onError(error, reactInfo)
  }

  render() {
    if (this.props.hasError) {
      return this.props.renderError()
    }
    return this.props.children
  }
}

export default ErrorBoundaryCore