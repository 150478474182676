import React, {useEffect, useState} from 'react'
import apiRequests from '../../../api/apiRequests'
import {toast} from 'react-toastify'
import RbSwitch from '../../common/inputs/RbSwitch'
import {Box} from '@mui/material'
import {bind} from '../../../utils/helpers'
import RbLoading from '../../common/components/RbLoading'
import useApiRequest from '../../../hooks/useApiRequest'

const SiteSettings = () => {
  const [schoolInfo, setSchoolInfo] = useState(null)
  const [originalSchoolInfo, setOriginalSchoolInfo] = useState(null)

  const requests = {
    getCurrentSchool: useApiRequest({
      request: apiRequests.schools.current.get,
      errorText: 'There was an error getting your school information.',
      onResponse: setOriginalSchoolInfo,
      sendImmediately: true
    }),
    updateSchool: useApiRequest({
      request: apiRequests.schools.current.update,
      errorText: 'There was an error updating your school information.'
    })
  }

  const settings = [
    {
      key: 'secondWeekForStudentsEnabled',
      label: 'Enable Second Week for Students',
      component: RbSwitch,
      bind: bind.object.switch
    }
  ]

  useEffect(() => setSchoolInfo(originalSchoolInfo), [originalSchoolInfo])

  useEffect(() => {
    // Don't update if the school info hasn't been fetched yet
    if (!schoolInfo) {
      return
    }

    const isUpdated = settings.some(setting => schoolInfo[setting.key] !== originalSchoolInfo[setting.key])

    if (!isUpdated) {
      return
    }

    requests.updateSchool.send(schoolInfo)
      .then(() => {
        setOriginalSchoolInfo(schoolInfo)
        toast.success('Successfully updated your school information.')
      })
  }, [schoolInfo])

  return (
    <>
      {!schoolInfo && <RbLoading />}

      {schoolInfo &&
        <Box width={250}>
          {settings.map(setting => {
            const Component = setting.component
            return (
              <Component
                key={setting.key}
                label={setting.label}
                {...setting.bind(schoolInfo, setting.key, setSchoolInfo)}
              />
            )
          })}
        </Box>
      }
    </>
  )
}

export default SiteSettings