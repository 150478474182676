import React, {useState} from 'react'
import {useCurrentUser} from '../../context/CurrentUserContext'
import RbModal from '../common/components/RbModal'
import {Alert, Box} from '@mui/material'
import RbTextField from '../common/inputs/RbTextField'
import {bind} from '../../utils/helpers'
import RbButton from '../common/inputs/RbButton'
import UserSelect from '../common/inputs/UserSelect'
import Flex from '../common/components/Flex'
import apiRequests from '../../api/apiRequests'
import RbText from '../common/components/RbText'
import useUsers from '../../hooks/useUsers'
import useApiRequest from '../../hooks/useApiRequest'

const ValidateMissingInformationForm = () => {
  const {missingUserInfo, currentUserId} = useCurrentUser()
  const {teachers} = useUsers({includeTeachers: true})

  const [homeroomTeacher, setHomeroomTeacher] = useState(null)
  const [grade, setGrade] = useState(null)
  const [password, setPassword] = useState(null)
  const [confirmPassword, setConfirmPassword] = useState(null)

  const gradeIsMissing = missingUserInfo.includes('grade')
  const homeroomTeacherIsMissing = missingUserInfo.includes('homeroomAccountId')
  const passwordIsMissing = missingUserInfo.includes('password')

  const updateMissingInfoRequest = useApiRequest({
    request: apiRequests.users.updateMissingInfo,
    errorText: 'Failed to update missing information. Please try again.',
    onResponse: () => window.location.reload()
  })

  const submitIsDisabled = () => {
    if (gradeIsMissing && !grade) {
      return true
    }

    if (homeroomTeacherIsMissing && !homeroomTeacher) {
      return true
    }

    if (passwordIsMissing && (!password || !confirmPassword || password !== confirmPassword)) {
      return true
    }

    return false
  }

  const submitMissingInfo = e => {
    e.preventDefault()

    const request = {}

    if (gradeIsMissing) {
      request.grade = grade
    }

    if (homeroomTeacherIsMissing) {
      request.homeroomAccountId = homeroomTeacher.id
    }

    if (passwordIsMissing) {
      request.password = password
      request.isPasswordSet = true
    }

    updateMissingInfoRequest.send(currentUserId, request)
  }

  return (
    <RbModal open title="First Things First">
      <RbText>Please fill out some missing information</RbText>

      <form onSubmit={submitMissingInfo}>
        {gradeIsMissing &&
          <RbTextField
            type="number"
            label="Grade"
            icon="psychology_alt"
            required
            min="5"
            max="16"
            {...bind.input(grade, setGrade)}
            sx={{mt: 4, mr: 2, width: '100%'}}
          />
        }

        {homeroomTeacherIsMissing &&
          <UserSelect
            users={teachers}
            label="Homeroom Teacher"
            icon="how_to_reg"
            {...bind.recordSelect(homeroomTeacher, setHomeroomTeacher)}
            sx={{mt: 4, width: '100%'}}
            required
          />
        }

        {passwordIsMissing &&
          <>
            <Flex sx={{mt: 4}}>
              <RbTextField
                type="password"
                label="Password"
                icon="lock"
                required
                {...bind.input(password, setPassword)}
                sx={{mr: 2}}
              />
              <RbTextField
                type="password"
                label="Confirm Password"
                required
                {...bind.input(confirmPassword, setConfirmPassword)}
              />
            </Flex>

            {(password && (password !== confirmPassword)) && <Alert severity="error" sx={{mt: 1}}>Passwords Do Not Match</Alert>}
          </>

        }

        <Box textAlign="right">
          <RbButton sx={{mt: 8}} type="submit" disabled={submitIsDisabled()}>Submit</RbButton>
        </Box>
      </form>
    </RbModal>
  )
}

export default ValidateMissingInformationForm