import React, {useState} from 'react'
import apiRequests from '../../../../../../api/apiRequests'
import {toast} from 'react-toastify'
import {useSisIntegration} from '../../SisIntegrationContext'
import RbLoading from '../../../../../common/components/RbLoading'
import Flex from '../../../../../common/components/Flex'
import RbSelect from '../../../../../common/inputs/RbSelect'
import {bind} from '../../../../../../utils/helpers'
import RbButton from '../../../../../common/inputs/RbButton'
import useApiRequest from '../../../../../../hooks/useApiRequest'

const TestConnection = () => {
  const {setCurrentConnection} = useSisIntegration()
  const [currentConnectionId, setCurrentConnectionId] = useState()

  const requests = {
    getConnectionDetails: useApiRequest({
      request: apiRequests.sis.getConnectionDetails,
      errorText: 'There was an error loading the connections.',
      sendImmediately: true
    }),
    testConnection: useApiRequest({
      request: apiRequests.sis.testConnection,
      errorText: 'An error occurred while testing the connection. Please try again.'
    })
  }

  const connections = requests.getConnectionDetails.data

  const testConnection = () => {
    requests.testConnection.send(currentConnectionId)
      .then(() => {
        toast.success('Connection Successful')
        setCurrentConnection(connections.find(connection => connection.id === Number(currentConnectionId)))
      })
  }

  return (
    <>
      {!connections && <RbLoading />}

      {connections &&
        <>
          <Flex centered sx={{textAlign: 'left'}}>
            <RbSelect
              options={connections}
              valueKey="id"
              labelKey="connectionNickname"
              label="Select a Connection"
              {...bind.select(currentConnectionId, setCurrentConnectionId)}
              sx={{width: 250, flexGrow: 0}}
            />
          </Flex>

          {currentConnectionId && <RbButton sx={{mt: 3}} onClick={testConnection} isLoading={requests.testConnection.isLoading}>Test</RbButton>}
        </>
      }
    </>
  )
}

export default TestConnection