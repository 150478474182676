import React, {useState} from 'react'
import {toast} from 'react-toastify'
import apiRequests from '../../../../api/apiRequests'
import UserSelect from '../../../common/inputs/UserSelect'
import {bind} from '../../../../utils/helpers'
import RbTextField from '../../../common/inputs/RbTextField'
import {Stack} from '@mui/material'
import RbButton from '../../../common/inputs/RbButton'
import RbText from '../../../common/components/RbText'
import useUsers from '../../../../hooks/useUsers'
import PrioritySelect from '../../../common/inputs/PrioritySelect'
import useApiRequest from '../../../../hooks/useApiRequest'

const StudentRequests = ({locationId}) => {
  const {students} = useUsers({includeStudents: true})

  const defaultStudentInfo = {
    selectedStudents: [],
    priorityLevel: null,
    reason: ''
  }
  const [studentInfo, setStudentInfo] = useState(defaultStudentInfo)

  const requestStudentsRequest = useApiRequest({
    request: apiRequests.attendance.requestMany,
    errorText: 'Failed to save Student Requests. Please try again.'
  })

  const submit = e => {
    e.preventDefault()

    const mappedStudents = studentInfo.selectedStudents.map(acct => {
      return {
        accountId: acct.id,
        locationId: locationId,
        priorityId: parseInt(studentInfo.priorityLevel),
        teacherNote: studentInfo.reason
      }
    })

    requestStudentsRequest.send(locationId, mappedStudents)
      .then(failedStudents => {
        if (failedStudents.length > 0) {
          toast.warning('Partial Success: ' + failedStudents.length + ' Failed Due to Higher Priorities Elsewhere and ' + (mappedStudents.length - failedStudents.length) + ' Succeeded', {autoClose: false})
          toast.error('Failed Students: ' + failedStudents.join(', '), {autoClose: false})
        } else {
          toast.success(`${mappedStudents.length > 1 ? mappedStudents.length + ' Students ' : 'Student '} Requested Successfully`)
        }
        setStudentInfo(defaultStudentInfo)
      })
  }

  return (
    <>
      <RbText h6>Student Requests</RbText>

      <form onSubmit={submit}>
        <Stack spacing={1}>
          <UserSelect
            multiple
            users={students}
            label="Students"
            required
            {...bind.object.recordSelect(studentInfo, 'selectedStudents', setStudentInfo)}
          />

          {studentInfo.selectedStudents.length > 0 &&
            <>
              <PrioritySelect
                required
                {...bind.object.select(studentInfo, 'priorityLevel', setStudentInfo)}
              />

              {studentInfo.priorityLevel &&
                <>
                  <RbTextField
                    multiline
                    label="Reason"
                    {...bind.object.input(studentInfo, 'reason', setStudentInfo)}
                  />

                  <RbButton type="submit" isLoading={requestStudentsRequest.isLoading}>Submit</RbButton>
                </>
              }
            </>
          }
        </Stack>
      </form>
    </>
  )
}

export default StudentRequests