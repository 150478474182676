import React, {useEffect, useState} from 'react'
import RbSwitch from '../../../../common/inputs/RbSwitch'
import {bind} from '../../../../../utils/helpers'
import {Grid} from '@mui/material'
import RbLoading from '../../../../common/components/RbLoading'
import RbButton from '../../../../common/inputs/RbButton'
import moment from 'moment'
import useApiRequest from '../../../../../hooks/useApiRequest'
import apiRequests from '../../../../../api/apiRequests'
import {useAutomatedTasks} from '../AutomatedTasksContext'
import Flex from '../../../../common/components/Flex'
import DayOfTheWeekSelect from '../../../../common/inputs/DayOfTheWeekSelect'
import RbTimePicker from '../../../../common/inputs/RbTimePicker'

const ScheduleDuplication = () => {
  const {scheduleDuplicationTimeConfig} = useAutomatedTasks()
  const [isEnabled, setIsEnabled] = useState()
  const [timeToRun, setTimeToRun] = useState()
  const [dayIntToRun, setDayIntToRun] = useState()

  const request = useApiRequest({
    request: apiRequests.schools.current.updateScheduleDuplication,
    errorText: 'There was an error when updating the Schedule Duplication settings',
    successText: 'Successfully updated Schedule Duplication settings'
  })

  const save = () => {
    request.send({
      scheduleDuplicationEnabled: isEnabled,
      scheduleDuplicationTime: timeToRun,
      dayIntToRun: dayIntToRun
    })
  }

  useEffect(() => {
    setIsEnabled(scheduleDuplicationTimeConfig?.configEnabled)
    setTimeToRun(scheduleDuplicationTimeConfig?.timeToRun)
    setDayIntToRun(scheduleDuplicationTimeConfig?.dayIntToRun)
  }, [scheduleDuplicationTimeConfig])

  const parsedTime = timeToRun ? moment(timeToRun, 'HH:mm:ss').toDate() : null

  return (
    <>
      {!scheduleDuplicationTimeConfig && <RbLoading />}

      {scheduleDuplicationTimeConfig &&
        <Grid item xs={12} md={6}>
          <RbSwitch
            label="Schedule Duplication"
            {...bind.switch(isEnabled, setIsEnabled)}
          />

          {isEnabled &&
            <Flex alignCenter gap={1} sx={{mt: 1}}>
              <span>
                <DayOfTheWeekSelect
                  label="Duplicate On"
                  variant="outlined"
                  size="small"
                  {...bind.select(dayIntToRun, setDayIntToRun)}
                />
              </span>

              <span>
                <RbTimePicker
                  label="At"
                  sx={{width: '9rem'}}
                  {...bind.timePicker(parsedTime, value => setTimeToRun(moment(value).format('HH:mm:ss')))}
                />
              </span>
            </Flex>
          }

          <RbButton onClick={save} sx={{mt: 2}} isLoading={request.isLoading}>
            Save Schedule Duplication Settings
          </RbButton>
        </Grid>
      }
    </>
  )
}
export default ScheduleDuplication