import React, {useMemo, useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {parseUserLevel, useCurrentUser} from '../../context/CurrentUserContext'
import useWeek from '../../hooks/useWeek'
import useUsers from '../../hooks/useUsers'

const AttendanceContext = React.createContext()

const AttendanceProvider = ({children}) => {
  const {id: viewingUserId} = useParams()
  const {currentUser} = useCurrentUser()
  const [viewingUser, setViewingUser] = useState(null)
  const [selectedDate, setSelectedDate] = useState(new Date())

  const {weekId} = useWeek(selectedDate)
  const {getUser} = useUsers()

  const isMe = () => !viewingUserId || viewingUserId === currentUser?.id

  const viewingUserLevelInfo = parseUserLevel(viewingUser)

  useEffect(() => {
    if (isMe()) {
      setViewingUser(currentUser)
    } else if (viewingUserId) {
      getUser(viewingUserId)
        .then(setViewingUser)
    }
  }, [viewingUserId, currentUser])

  const contextValue = useMemo(() => ({
    isMe: isMe(),
    viewingUser,
    selectedDate,
    setSelectedDate,
    isTeacherSchedule: viewingUserLevelInfo.isTeacherOrAbove,
    isStudentSchedule: viewingUserLevelInfo.isStudent,
    weekId
  }), [viewingUser, selectedDate, weekId])

  return (
    <AttendanceContext.Provider value={contextValue}>
      {children}
    </AttendanceContext.Provider>
  )
}

const useAttendance = () => {
  const context = React.useContext(AttendanceContext)
  if (context === undefined) {
    throw new Error('useAttendance must be used within a AttendanceProvider')
  }
  return context
}

export {AttendanceProvider, useAttendance}