
import React, {createContext, useContext, useEffect, useMemo, useState} from 'react'
import apiRequests from '../api/apiRequests'
import useApiRequest from '../hooks/useApiRequest'

const CurrentUserContent = createContext()

export const USER_LEVELS = {
  STUDENT: 'Student',
  TEACHER: 'Teacher',
  ADMIN: 'Admin',
  SUPER_ADMIN: 'Super Admin',
}

export const getUserToken = () => {
  const tokenString = localStorage.getItem('token')
  const userToken = JSON.parse(tokenString)
  return userToken?.token
}

export const parseUserLevel = user => {
  return parseLevel(user?.level)
}

export const parseLevel = level => {
  const levelName = level?.name
  return {
    isStudent: levelName === USER_LEVELS.STUDENT,
    isTeacher: levelName === USER_LEVELS.TEACHER,
    isAdmin: levelName === USER_LEVELS.ADMIN,
    isSuperAdmin: levelName === USER_LEVELS.SUPER_ADMIN,
    isAdminOrAbove: levelName === USER_LEVELS.ADMIN || levelName === USER_LEVELS.SUPER_ADMIN,
    isTeacherOrAbove: levelName === USER_LEVELS.TEACHER || levelName === USER_LEVELS.ADMIN || levelName === USER_LEVELS.SUPER_ADMIN,
  }
}

export const CurrentUserProvider = ({children}) => {
  const [userToken, setUserToken] = useState(getUserToken())
  const [currentUser, setCurrentUser] = useState()

  const saveUserToken = token => {
    localStorage.setItem('token', JSON.stringify(token))
    setUserToken(token)
  }

  const logOut = () => {
    saveUserToken(null)
    setUserToken(null)
    setCurrentUser(null)
  }

  const userLevelInfo = parseUserLevel(currentUser)

  const getMissingInfo = () => {
    const missingInfo = []

    if (!currentUser) {
      return missingInfo
    }

    if (userLevelInfo.isStudent) {
      if (!currentUser.grade) {
        missingInfo.push('grade')
      }

      if (!currentUser.homeroomAccountId) {
        missingInfo.push('homeroomAccountId')
      }
    }

    if (!currentUser.IsPasswordSet) {
      missingInfo.push('password')
    }

    return missingInfo
  }

  const getCurrentUserRequest = useApiRequest({
    request: apiRequests.users.getCurrentUser,
    errorText: 'An error occurred while fetching current user info.',
    onResponse: setCurrentUser,
    onError: logOut
  })

  useEffect(() => {
    if (!userToken) {
      return
    }

    getCurrentUserRequest.send()
  }, [userToken])

  const context = useMemo(() => ({
    userToken,
    saveUserToken,
    currentUser,
    setCurrentUser,
    currentUserIsStudent: userLevelInfo.isStudent,
    currentUserIsTeacherOrAbove: userLevelInfo.isTeacherOrAbove,
    currentUserIsAdminOrAbove: userLevelInfo.isAdminOrAbove,
    currentUserIsLoading: getCurrentUserRequest.isLoading,
    currentUserInitials: currentUser ? (currentUser?.firstName?.trim().charAt(0) + currentUser?.lastName?.trim().charAt(0)) : '?',
    logOut: logOut,
    currentUserId: currentUser?.id,
    currentUserAcceptedTos: currentUser?.tosAccepted,
    missingUserInfo: getMissingInfo()
  }), [userToken, currentUser])

  return <CurrentUserContent.Provider value={context}>{children}</CurrentUserContent.Provider>
}

// Export a custom hook to use the context
export const useCurrentUser = () => {
  const context = useContext(CurrentUserContent)

  if (!context) {
    throw new Error('useCurrentUser must be used within a CurrentUserProvider')
  }

  return context
}