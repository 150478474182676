export const STATUS_CODES = {
  NOT_TAKEN: 0,
  PRESENT: 1,
  ABSENT: 2,
  TARDY: 3,
  NON_COMPLIANT: 4,
}

export const STATUS_CONFIGS = {
  [STATUS_CODES.NOT_TAKEN]: {title: 'Not Taken'},
  [STATUS_CODES.PRESENT]: {title: 'Present', backgroundColor: 'rgb(3, 85, 21)'},
  [STATUS_CODES.ABSENT]: {title: 'Absent', backgroundColor: 'rgb(209, 26, 26)'},
  [STATUS_CODES.TARDY]: {title: 'Tardy', backgroundColor: '#f7cd29'},
  [STATUS_CODES.NON_COMPLIANT]: {title: 'Non-Compliant'},
}

export const getStatusTitle = statusCode => STATUS_CONFIGS[statusCode]?.title ?? 'Unknown'