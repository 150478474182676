import React from 'react'
import {useParams} from 'react-router-dom'
import Schedule from './Schedule'
import {ScheduleProvider} from './ScheduleContext'

// Used to abstract the context provider from the Schedule component, and inject the viewing user id. Only used for the schedule page itself!
const ScheduleRoot = () => {
  const {id: userId} = useParams()

  return (
    <ScheduleProvider viewingUserId={userId}>
      <Schedule />
    </ScheduleProvider>
  )
}

export default ScheduleRoot